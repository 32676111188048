import React, { useState, useEffect } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Breadcrumb from '../Global/Breadcrumb';
import { TbReportAnalytics } from 'react-icons/tb';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

interface DataPoint {
    data: string;
    count: number;
}

interface MultipliedCountData {
    data: string;
    multipliedCount: number;
}

interface MarketSummary {
    totalCNPJs: number;
    totalCreditos: number;
    valorGasto: number;
}

const MaAnalytics = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [marketSummary, setMarketSummary] = useState<MarketSummary | null>(
        null
    );

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' },
        { label: 'Analytics' },
    ];

    const [barChartOptions, setBarChartOptions] = useState({
        chart: {
            id: 'cnpj-bar-chart',
            toolbar: {
                show: false,
            },
            foreColor: '#ffffff', // Define a cor padrão do texto como branco
        },
        xaxis: {
            categories: [] as string[],
            labels: {
                style: {
                    colors: '#ffffff', // Cor branca para as legendas do eixo x
                },
            },
        },
        yaxis: {
            title: {
                text: 'Total de Novos CNPJs',
                style: {
                    color: '#ffffff', // Cor branca para o título do eixo y
                },
            },
            labels: {
                style: {
                    colors: '#ffffff', // Cor branca para as legendas do eixo y
                },
            },
        },
        title: {
            text: 'Novos CNPJs por Dia',
            align: 'center' as 'center',
            style: {
                color: '#ffffff', // Cor branca para o título
            },
        },
        grid: {
            borderColor: '#444444', // Cor do grid mais suave
            strokeDashArray: 4, // Adiciona linhas tracejadas ao grid
        },
        tooltip: {
            theme: 'dark', // Tema escuro para o tooltip
        },
    });

    const [barChartSeries, setBarChartSeries] = useState([
        {
            name: 'Novos CNPJs',
            data: [] as number[],
        },
    ]);

    const [lineChartOptions, setLineChartOptions] = useState({
        chart: {
            id: 'multiplied-count-line-chart',
            toolbar: {
                show: false,
            },
            foreColor: '#ffffff', // Define a cor padrão do texto como branco
        },
        xaxis: {
            categories: [] as string[],
            labels: {
                style: {
                    colors: '#ffffff', // Cor branca para as legendas do eixo x
                },
            },
        },
        yaxis: {
            title: {
                text: 'Total de Créditos',
                style: {
                    color: '#ffffff', // Cor branca para o título do eixo y
                },
            },
            labels: {
                style: {
                    colors: '#ffffff', // Cor branca para as legendas do eixo y
                },
            },
        },
        title: {
            text: 'Total de Créditos por Dia',
            align: 'center' as 'center',
            style: {
                color: '#ffffff', // Cor branca para o título
            },
        },
        grid: {
            borderColor: '#444444', // Cor do grid mais suave
            strokeDashArray: 4, // Adiciona linhas tracejadas ao grid
        },
        tooltip: {
            theme: 'dark', // Tema escuro para o tooltip
        },
    });

    const [lineChartSeries, setLineChartSeries] = useState([
        {
            name: 'Multiplied Count',
            data: [] as number[],
        },
    ]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            // Buscar dados para o gráfico de barras
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/company-data-count-by-day/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const dataPoints: DataPoint[] = response.data;

                    // Extrair datas e counts para os gráficos de barras
                    const dates = dataPoints.map(item => item.data);
                    const counts = dataPoints.map(item => item.count);

                    // Atualizar opções do gráfico de barras (apenas xaxis.categories neste caso)
                    setBarChartOptions(prevOptions => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: dates,
                        },
                    }));

                    // Atualizar série do gráfico de barras
                    setBarChartSeries([
                        {
                            name: 'Novos CNPJs',
                            data: counts,
                        },
                    ]);
                })
                .catch(error => {
                    console.error(
                        'Erro ao buscar dados de novos CNPJs:',
                        error
                    );
                });

            // Buscar dados para o gráfico de linha
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/company-data-count-multiplied-by-day/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const multipliedCountData: MultipliedCountData[] =
                        response.data;

                    // Extrair datas e counts para os gráficos de linha
                    const dates = multipliedCountData.map(item => item.data);
                    const multipliedCounts = multipliedCountData.map(
                        item => item.multipliedCount
                    );

                    // Atualizar opções do gráfico de linha (apenas xaxis.categories neste caso)
                    setLineChartOptions(prevOptions => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: dates,
                        },
                    }));

                    // Atualizar série do gráfico de linha
                    setLineChartSeries([
                        {
                            name: 'Multiplied Count',
                            data: multipliedCounts,
                        },
                    ]);
                })
                .catch(error => {
                    console.error(
                        'Erro ao buscar dados de multiplied count:',
                        error
                    );
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            // Requisição para obter o resumo de mercado
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/company-data-kpis/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const data: MarketSummary = response.data;
                    setMarketSummary(data);
                })
                .catch(error => {
                    console.error('Erro ao buscar resumo de mercado:', error);
                });
        }
    }, []);

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6">
                <div className="flex ml-10 mt-1">
                    <div
                        className="w-[1100px] h-[580px] p-4 mr-6 -ml-8 overflow-y-auto flex flex-col space-y-6"
                        style={{ scrollbarColor: '#6B7280 #4B5563' }}
                    >
                        <div className="container mx-auto">
                            <Chart
                                options={barChartOptions}
                                series={barChartSeries}
                                type="bar"
                                height={350}
                            />
                        </div>
                        <div className="container mx-auto">
                            <Chart
                                options={lineChartOptions}
                                series={lineChartSeries}
                                type="line"
                                height={350}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className="items-center text-2xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                        <TbReportAnalytics className="h-8 w-8 mr-2 text-[#27395e] mb-2" />
                        Analytics
                    </h3>
                    <p className="text-gray-300 text-base font-montserrat mt-0">
                        Aqui você pode ver um resumo das suas consultas de CNPJ,
                        incluindo o número de consultas realizadas, o custo
                        associado e outras métricas importantes.
                    </p>

                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-10">
                        <TbReportAnalytics className="mr-2 w-7 h-7" />{' '}
                        Indicadores
                    </h3>
                    {marketSummary && (
                        <div>
                            <div className="flex mt-2 -mb-4 w-56">
                                <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3 w-80">
                                    {marketSummary.totalCNPJs} CNPJ(s)
                                </div>
                            </div>

                            <div className="flex mt-6 w-56">
                                <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3 w-80">
                                    {marketSummary.totalCreditos} Crédito(s)
                                </div>
                            </div>

                            <div className="flex mt-2 w-56">
                                <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3 w-80">
                                    R${marketSummary.valorGasto.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MaAnalytics;
