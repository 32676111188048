import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import Login from '../components/AuthComponent/Login';
import Register from '../components/AuthComponent/Register';

import Dashboard from '../pages/Dashboard';
import CNPJ from '../pages/CNPJ/CNPJ';
import { CNPJFolder } from '../pages/CNPJ/CNPJFolder'; 

import CPF from '../pages/CPF/CPF';
import CNPJMonitoring from '../pages/CNPJ/CNPJMonitoring';
import { CPFFolder } from '../pages/CPF/CPFFolder';
import CPFMonitoring from '../pages/CPF/CPFMonitoring';

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            
            <Route path="/cnpj" element={<CNPJ />} />
            <Route
                path="/cnpj/folder/:id"
                element={<CNPJFolder />}
            />
            <Route path="/monitoring-cnpj" element={<CNPJMonitoring />} />

            <Route path="/cpf" element={<CPF />} />
            <Route
                path="/cpf/folder/:id"
                element={<CPFFolder />}
            />
            <Route path="/monitoring-cpf" element={<CPFMonitoring />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
    </Routes>
);

export default AppRoutes;
