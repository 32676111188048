import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importar useNavigate
import logoNeuronLab from '../../assets/logo-neuronlab-site.png';
import { CiBellOn, CiSettings } from 'react-icons/ci';
import userAvatar from '../../assets/avatar.jpeg';
import { IoIosLogOut } from 'react-icons/io';
import { BsQuestionCircle } from 'react-icons/bs';
import { GiGraduateCap } from 'react-icons/gi';
import axios from 'axios';
import { REACT_APP_SERVER_URL } from '../../config/keys';

interface UserInfo {
    name: string;
    profileImageURL: string;
}

interface NotificationInfo {
    active: boolean;
}

export const LandingNavbar = () => {
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [avatarImage, setAvatarImage] = useState<string | null>(null);

    const navigate = useNavigate(); // Criar uma instância do navegador

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            const userId = JSON.parse(storedUserId);

            // Buscar informações do usuário
            axios
                .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${userId}`)
                .then(response => {
                    const updatedUserInfo: UserInfo = {
                        ...response.data,
                        profileImageURL: response.data.profileImageURL,
                    };
                    setUserInfo(updatedUserInfo);
                    setAvatarImage(updatedUserInfo.profileImageURL);
                })
                .catch(error => {
                    console.error('Erro ao buscar informações do usuário:', error);
                });

            // Buscar informações de notificações
            axios
                .get<NotificationInfo>(`${REACT_APP_SERVER_URL}/api/market-intelligence/notifications/${userId}`)
                .then(response => {
                    // Ativar a notificação somente se "active" for false
                    setHasNewNotifications(!response.data.active);
                })
                .catch(error => {
                    console.error('Erro ao buscar informações de notificações:', error);
                });
        }
    }, []);

    const handleMenuClick = (menuType: string) => {
        if (menuType === 'user') {
            setIsMenuOpen(!isMenuOpen);
        } else if (menuType === 'notifications') {
            setIsMenuOpen(false);
        }
    };

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const handleNotificationsClick = () => {
        navigate('/market-analysis/notifications'); // Navegar para a rota /notifications
    };

    return (
        <nav className="bg-[#111827] px-5 py-4 flex items-center justify-between shadow-sm mb-8">
            <Link to="/" className="flex items-center">
                <div className="w-26 h-10 ml-3">
                    <img
                        src={logoNeuronLab}
                        alt="Neuron Lab"
                        className="w-full h-full object-cover"
                    />
                </div>
            </Link>

            <div className="flex items-center space-x-2 relative">
                <div className="flex space-x-2">
                    <div className="flex space-x-2">
                        <button className="flex items-center bg-[#192339] px-3 py-1 rounded-lg h-7 mt-2 text-white relative transition-colors duration-300 hover:bg-[#2a3e5e]">
                            <GiGraduateCap className="text-[#ffffff] text-sm mr-1" />
                            <span className="font-montserrat text-sm font-normal">
                                Tutoriais
                            </span>
                        </button>

                        <button className="flex items-center bg-[#192339] px-3 py-1 rounded-lg h-7 mt-2 text-white relative transition-colors duration-300 hover:bg-[#2a3e5e]">
                            <BsQuestionCircle className="text-[#ffffff] text-sm mr-1" />
                            <span className="font-montserrat text-sm font-normal">
                                Suporte
                            </span>
                        </button>
                    </div>
                    <button
                        className="flex items-center bg-[#192339] px-3 py-1 rounded-lg h-7 mt-2 text-white relative transition-colors duration-300 hover:bg-[#2a3e5e]"
                        onClick={handleNotificationsClick} // Atualizado
                    >
                        <CiBellOn className="text-[#ffffff] text-sm mr-1" />
                        <span className="font-montserrat text-sm font-normal">
                            Notificações
                        </span>
                        {hasNewNotifications && (
                            <div className="w-1.5 h-1.5 bg-red-500 rounded-full absolute -top-0.5 -right-0.5 animate-ping"></div>
                        )}
                    </button>

                    {/* Avatar do usuário com menu suspenso */}
                    <div className="relative">
                        <img
                            src={avatarImage || userAvatar}
                            alt="User Avatar"
                            className="w-11 h-11 rounded-full cursor-pointer ml-2"
                            style={{
                                visibility: avatarImage ? 'visible' : 'hidden',
                            }}
                            onClick={() => handleMenuClick('user')}
                        />

                        {isMenuOpen && (
                            <div className="absolute top-full right-0 mt-4 w-44 bg-[#192339] text-white rounded-lg shadow-lg border border-gray-200 z-50">
                                <ul className="p-2">
                                    <li>
                                        <span className="block py-1 border-b border-gray-200 font-montserrat ml-2 text-base font-semibold">
                                            {userInfo ? userInfo.name : 'Usuário'}
                                        </span>
                                    </li>
                                    <li className="flex items-center ml-2 mt-2">
                                        <CiSettings className="text-white text-base mr-1" />
                                        <span className="block py-1 font-montserrat text-base">
                                            Configurações
                                        </span>
                                    </li>
                                    <li className="flex items-center ml-2 -mt-1">
                                        <IoIosLogOut
                                            className="text-red-500 text-base mr-1 cursor-pointer font-bold"
                                            onClick={handleLogout}
                                        />
                                        <span
                                            className="block py-1 font-montserrat text-base text-red-500 font-bold cursor-pointer"
                                            onClick={handleLogout}
                                        >
                                            Sair
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default LandingNavbar;
