import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { X } from 'lucide-react';
import { BiStar } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import { IoHomeOutline } from 'react-icons/io5';
import { FaLock, FaUnlock } from 'react-icons/fa';
import { PlusCircle, MinusCircle } from 'lucide-react';
import axios from 'axios';
import Joyride from 'react-joyride';
import { FaCheckCircle } from 'react-icons/fa';
import { FaRegNewspaper } from 'react-icons/fa';

import ConfirmationPopup from '../Global/ConfirmationPopup';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

import Breadcrumb from '../Global/Breadcrumb';

const API_URL = `${REACT_APP_SERVER_URL}/api/news/search/`;

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface InvoiceResponse {
    _id: string;
    name: string;
    url: string;
    image?: string; // Pode ser opcional dependendo do seu modelo
    description: string;
    datePublished: string;
    filteredText: string;
    category: string;
    providerName: string;
    hasSummary: boolean;
    like: boolean;
}

interface Provider {
    name: string;
    isBlocked: boolean;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const NewsHero = () => {
    const { id } = useParams();

    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [loadingSummarize, setLoadingSummarize] = useState(false);
    const [tourFinished, setTourFinished] = useState(false);
    const companiesPerPage = 3;
    const [downloading, setDownloading] = useState(false);
    const [providerList, setProviderList] = useState<InvoiceResponse[]>([]);

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const [loading, setLoading] = useState(false);

    const [newsCount, setNewsCount] = useState<number | string>(2);
    const [searchTerms, setSearchTerms] = useState<string[]>(['']);

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' }, // Adiciona a rota aqui
    ];

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.name &&
                company.name.toLowerCase() !== 'N/A' &&
                company.name.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/news/news-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/news/kpis-news-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const [uniqueProviders, setUniqueProviders] = useState<Provider[]>([]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        const fetchData = async () => {
            try {
                if (storedUserId) {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/news/provider-list-data/${JSON.parse(
                            storedUserId
                        )}`
                    );
                    setUniqueProviders(response.data.uniqueProviders);
                }
            } catch (error) {
                console.error('Error fetching provider list:', error);
            }
        };

        fetchData();
    }, []); // Sem dependência para que o useEffect seja executado apenas uma vez

    const showProvidersList = uniqueProviders.length > 0;

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        // Salva a página atual antes de iniciar o processo de exclusão
        const currentPageBeforeDelete = currentPage;

        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);

        // Salva a página atual após o início do processo de exclusão
        setPreviousPageBeforeDelete(currentPageBeforeDelete);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/news/delete-news-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            // Restaura a página anterior se a exclusão não afetar diretamente a página
            setCurrentPage(prevPage =>
                companyIdToDelete === null ? prevPage : previousPageBeforeDelete
            );
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
        }
    };

    const [previousPageBeforeDelete, setPreviousPageBeforeDelete] =
        useState<number>(1);

    const handleAddTerm = () => {
        setSearchTerms([...searchTerms, '']);
    };

    const handleRemoveTerm = (index: number) => {
        if (searchTerms.length > 1) {
            const updatedTerms = [...searchTerms];
            updatedTerms.splice(index, 1);
            setSearchTerms(updatedTerms);
        }
    };

    const handleTermChange = (index: number, value: string) => {
        const updatedTerms = [...searchTerms];
        updatedTerms[index] = value;
        setSearchTerms(updatedTerms);
    };

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [updatedUserCredits, setUpdatedUserCredits] = useState(0);
    const [analysisCompleted, setAnalysisCompleted] = useState(false);
    const [executionTime, setExecutionTime] = useState(0);

    const handleSearch = async (
        searchTerms: string[],
        newsCount: number | string
    ) => {
        const start = new Date().getTime();

        const concatenatedTerms = searchTerms.join('+');

        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                setLoading(true);

                const response = await axios.get(API_URL, {
                    params: {
                        query: concatenatedTerms,
                        user_id: userId,
                        count: newsCount,
                    },
                });

                const { countNews, availableCredits } = response.data;

                setNumberOfPages(countNews);
                setUpdatedUserCredits(availableCredits);

                setLoading(false);
                setAnalysisCompleted(true);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);

                console.log('Response:', response.data);
            } catch (error) {
                console.error('Request error:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePopupOkClick = () => {
        setAnalysisCompleted(false);
        window.location.reload();
    };

    const handleSummarize = async (companyId: string, filteredText: string) => {
        try {
            setLoadingSummarize(true);

            // Assuming you have a function to get user data from local storage
            const user = getUserFromLocalStorage();

            if (user && user.userId) {
                const userId = user.userId.replace(/^"(.*)"$/, '$1');

                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/api/news/summarize/${companyId}?author_id=${userId}`,
                    { text: filteredText }
                );

                console.log('Resposta da sumarização:', response.data);

                // Atualize o estado local para refletir a mudança no botão
                // Por exemplo, você pode ter uma lista de empresas onde você atualiza o estado de uma empresa específica após a sumarização
                // Supondo que você tenha uma lista de empresas chamada 'companies', você pode atualizar o estado dessa lista

                setCompanies(prevCompanies => {
                    const updatedCompanies = prevCompanies.map(company => {
                        if (company._id === companyId) {
                            // Atualiza o estado do botão para 'download'
                            return { ...company, hasSummary: true };
                        }
                        return company;
                    });
                    return updatedCompanies;
                });
            } else {
                console.error(
                    'Usuário não encontrado ou sem ID no armazenamento local.'
                );
            }
        } catch (error) {
            console.error('Erro ao chamar a API de sumarização:', error);
        } finally {
            setLoadingSummarize(false);
        }
    };

    const handleDownload = (companyId: string) => {
        const user = getUserFromLocalStorage();
        setDownloading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId); // Certifique-se de codificar o authorId corretamente

            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/news/download-sample/${companyId}?author_id=${authorId}`
                )
                .then(response => {
                    // Lógica para processar a resposta
                    console.log(
                        'Download realizado com sucesso:',
                        response.data
                    );

                    // Abre uma nova aba do navegador com os campos da resposta
                    const {
                        datePublished,
                        summary,
                        name,
                        fonte,
                        gpt3GeneratedContent,
                        url,
                    } = response.data;

                    const htmlContent = `
                        <html>
                            <head>
                                <title>Neuron Lab | Resumo Gerado</title>
                                <style>
                                    body {
                                        font-family: 'Montserrat', sans-serif;
                                        background-color: #111827;
                                        color: #ffffff;
                                        padding: 20px;
                                        line-height: 1.8;
                                    }
                                    h2 {
                                        color: #39A2A7;
                                        margin-bottom: 15px;
                                    }
                                    h4 {
                                        color: #D9D9D9;
                                        margin-bottom: 20px;
                                    }
                                    p {
                                        margin-bottom: 25px;
                                        font-size: 16px;
                                    }
                                    a {
                                        color: #39A2A7;
                                        text-decoration: underline;
                                    }
                                </style>
                            </head>
                            <body>
                                <h2>${name}</h2>
                                <h4>Data: ${datePublished}</h4>
                                <p>${gpt3GeneratedContent.replace(
                                    /\n/g,
                                    '<br>'
                                )}</p>
                                <p><a href="${url}" target="_blank">Link: ${url}</a></p>
                            </body>
                        </html>
                    `;

                    // Verifica se newTab não é null antes de acessar suas propriedades
                    const newTab = window.open();
                    if (newTab) {
                        newTab.document.write(htmlContent);
                    } else {
                        console.error(
                            'Não foi possível abrir a nova aba do navegador.'
                        );
                    }
                })
                .catch(error => {
                    console.error('Erro durante o download:', error);
                })
                .finally(() => {
                    // Atualiza o estado para indicar que o download foi concluído
                    setDownloading(false);
                });
        }
    };
    const handleToggleLike = async (companyId: string) => {
        try {
            // Make the request to the endpoint to toggle the 'like' state
            await axios.put(
                `${REACT_APP_SERVER_URL}/api/news/toggle-like-news?_id=${companyId}`
            );

            // Update the local state to indicate that the like state has changed
            setCompanies(prevCompanies => {
                return prevCompanies.map(company => {
                    if (company._id === companyId) {
                        return { ...company, like: !company.like };
                    }
                    return company;
                });
            });
        } catch (error) {
            console.error('Error toggling "like" state:', error);
        }
    };

    const handleToggleBlockClick = async (
        providerName: string,
        isBlocked: boolean
    ) => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId);

            const route = isBlocked ? 'unblock-provider' : 'block-provider';

            try {
                setLoading(true);

                const response = await fetch(
                    `${REACT_APP_SERVER_URL}/api/news/${route}/${authorId}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            // Adicione headers de autenticação, se necessário
                        },
                        body: JSON.stringify({ providerName }),
                    }
                );

                if (response.ok) {
                    console.log(
                        `Fonte "${providerName}" ${
                            isBlocked ? 'desbloqueada' : 'bloqueada'
                        } com sucesso!`
                    );

                    // Atualizar a lista de provedores após a operação bem-sucedida
                    const updatedProviders = [...uniqueProviders];
                    const index = updatedProviders.findIndex(
                        provider => provider.name === providerName
                    );

                    if (index !== -1) {
                        updatedProviders[index].isBlocked = !isBlocked;
                        setUniqueProviders(updatedProviders);
                    }
                } else {
                    console.error(
                        `Falha ao ${
                            isBlocked ? 'desbloquear' : 'bloquear'
                        } a fonte.`
                    );
                }
            } catch (error) {
                console.error(
                    `Erro ao processar a solicitação de ${
                        isBlocked ? 'desbloqueio' : 'bloqueio'
                    }:`,
                    error
                );
            } finally {
                setLoading(false);
            }
        }
    };

    const formatTime = (milliseconds: number) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = ((milliseconds % 60000) / 1000).toFixed(0);

        if (minutes === 0) {
            return `${seconds} segundos`;
        } else {
            return `${minutes} minutos ${seconds} segundos`;
        }
    };

    return (
        <div className="bg-[#111827] py-2 px-6 w-full">
            <div className="bg-[#111827] py-4 px-6 mb-5">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="flex mt-8 space-x-4">
                <div>
                    <div className="bg-[#192339] rounded-lg p-6 w-auto h-auto">
                        <div className="mb-2">
                            <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                                Assistente de IA para <br />
                                <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                    Notícias
                                </span>
                            </p>
                        </div>
                        <p className="mb-4 text-sm font-montserrat text-white">
                            Busque e resuma notícias de forma descomplicada.
                        </p>
                        <div className="flex flex-col items-center mb-2">
                            {searchTerms.map((term, index) => (
                                <div
                                    key={index}
                                    className="flex items-center mb-2"
                                >
                                    <input
                                        type="text"
                                        id="inputQuery"
                                        placeholder="Digite o assunto"
                                        value={term}
                                        onChange={e =>
                                            handleTermChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        className="border rounded-lg p-3 w-60 font-montserrat bg-[#192339] focus:outline-none text-white mr-2"
                                    />
                                    <div id="inputMoreMinus" className="flex bg-[#192339]">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleRemoveTerm(index)
                                            }
                                            className={`text-red-500 cursor-pointer ${
                                                searchTerms.length === 1 ||
                                                index === searchTerms.length - 1
                                                    ? 'opacity-50 cursor-not-allowed bg-[#192339]'
                                                    : ''
                                            }`}
                                            disabled={
                                                searchTerms.length === 1 ||
                                                index === searchTerms.length - 1
                                            }
                                        >
                                            <MinusCircle size={24} />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleAddTerm}
                                            className="text-[#03989E] cursor-pointer ml-2"
                                        >
                                            <PlusCircle size={24} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <input
                            type="text"
                            id="newsCount"
                            placeholder="Número de notícias"
                            className="border rounded-lg p-3 w-full font-montserrat bg-[#1f2937] focus:outline-none  text-white"
                            value={newsCount}
                            onChange={e => {
                                const newValue = parseInt(e.target.value, 10);
                                if (
                                    !isNaN(newValue) &&
                                    newValue >= 1 &&
                                    newValue <= 100
                                ) {
                                    setNewsCount(newValue);
                                } else if (e.target.value === '') {
                                    setNewsCount('');
                                }
                            }}
                            max={100}
                        />
                        <div className="mt-2">
                            <button
                                id="search-button"
                                onClick={() =>
                                    handleSearch(searchTerms, newsCount)
                                }
                                className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                                    loading
                                        ? 'opacity-50 cursor-not-allowed'
                                        : ''
                                }`}
                                disabled={loading}
                            >
                                {loading ? (
                                    'Aguarde...'
                                ) : (
                                    <RiRobot2Line size={24} />
                                )}
                            </button>
                        </div>
                    </div>
                    {showProvidersList && (
                        <div className="bg-[#192339] rounded-lg p-6 w-auto h-auto mt-4 max-h-[280px]">
                            <p className="mb-2 text-xl font-semibold text-white font-montserrat">
                                Fontes
                            </p>
                            <div className="text-sm font-montserrat text-white overflow-y-auto max-h-[150px] mt-2">
                                {uniqueProviders.map((provider, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between py-2 border-b border-gray-500"
                                    >
                                        <div className="flex items-center">
                                            <span>{provider.name}</span>
                                        </div>
                                        <div className="flex items-center">
                                            <div
                                                onClick={() =>
                                                    handleToggleBlockClick(
                                                        provider.name,
                                                        provider.isBlocked
                                                    )
                                                }
                                                className="flex items-center cursor-pointer"
                                            >
                                                {provider.isBlocked ? (
                                                    <FaUnlock
                                                        size={18}
                                                        className="mr-1 text-green-500"
                                                    />
                                                ) : (
                                                    <FaLock
                                                        size={18}
                                                        className="mr-1 text-red-500"
                                                    />
                                                )}
                                                <div className="mr-2">
                                                    <span className="font-montserrat font-semibold">
                                                        {provider.isBlocked
                                                            ? 'Desbloquear'
                                                            : 'Bloquear'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex mt-8 space-x-4">
                    <div>
                        <div
                            className="flex justify-center -mt-14 text-sm font-montserrat"
                            id="kpis"
                        >
                            <div>
                                <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-30">
                                    <div className="grid grid-cols-1 md:grid-cols-6 gap-4 ml-5">
                                        <div className="col-span-1  bg-[#192339] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base text-muted-foreground font-montserrat">
                                                Total de Notícias
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.count_news || '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#192339] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base text-muted-foreground font-montserrat">
                                                Total de Categorias
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countUniqueCategories ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#192339] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Fontes Disponíveis
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countUniqueProviderNames ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#192339] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Notícia Não Disponível
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countEmptyFilteredText ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#192339] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Notícia Disponível
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countNonEmptyFilteredText ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <button
                                            className="col-span-1 flex items-center justify-center border-dashed border-[#2c3848] border-2 text-[#ffffff] p-4 rounded-lg shadow-md hover:bg-[#1f2937] hover:text-white transition-all duration-300"
                                            onClick={() => {
                                                window.open(
                                                    '/kpis-invoices',
                                                    '_blank'
                                                );
                                            }}
                                            disabled
                                        >
                                            <p className="text-base font-montserrat">
                                                <FaPlus size={20} />
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mb-0">
                            <input
                                type="text"
                                id="search"
                                value={searchFilter}
                                onChange={e => setSearchFilter(e.target.value)}
                                placeholder="Pesquisar Notícia..."
                                className="w-[1000px] ml-6 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#192339] border border-[#ffffff] rounded focus:outline-none focus:border-[#39A2A7] text-white font-montserrat"
                            />
                        </div>
                        <div
                            className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-6"
                            id="companies"
                        >
                            {currentFilteredCompanies.length === 0 ? (
                                <div className="flex items-center justify-center mt-10">
                                    <p className="text-gray-500 text-center text-lg font-montserrat">
                                        Nenhuma notícia adicionada.
                                    </p>
                                </div>
                            ) : (
                                currentFilteredCompanies.map(company => (
                                    <div
                                        key={company._id}
                                        className="bg-[#192339] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0 mb-10 "
                                    >
                                        <div className="ml-56 -mt-1 flex ">
                                            <div>
                                                <X
                                                    size={24}
                                                    className="cursor-pointer text-red-500 hover:text-red-700"
                                                    onClick={() =>
                                                        handleDeleteCompany(
                                                            company._id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <img
                                                    src={
                                                        company.image ||
                                                        'https://placehold.it/700x400'
                                                    }
                                                    alt={company.name}
                                                    className="w-full h-auto -ml-8 -mt-16 mb-2 rounded-full  border-solid border border-[#2c3848]"
                                                    style={{
                                                        width: '90px',
                                                        height: '90px',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="relative">
                                            <div className="flex items-center">
                                                <div className="w-full">
                                                    <p className="text-base font-medium font-montserrat mb-1 mt-1 overflow-hidden  max-w-full ">
                                                        {company.name || 'N/A'}
                                                    </p>
                                                    <p
                                                        className={`text-xs font-montserrat mt-2`}
                                                    >
                                                        Categoria:{' '}
                                                        {company.category ? (
                                                            <span className="bg-yellow-500 text-black font-montserrat font-semibold rounded-sm px-0.5 py-0.5 mr-2">
                                                                {
                                                                    company.category
                                                                }
                                                            </span>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </p>
                                                    <div className="flex items-center mt-2">
                                                        <p className="text-xs font-montserrat mr-2">
                                                            Fonte:{' '}
                                                            {company.providerName ? (
                                                                <span className="bg-white text-black font-montserrat font-semibold rounded-sm px-1 py-0.5">
                                                                    {
                                                                        company.providerName
                                                                    }
                                                                </span>
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="border border-solid border-blue-400 p-2 mb-2 mt-4 rounded">
                                                        <p className="text-xs font-montserrat text-white font-medium">
                                                            {
                                                                company.description
                                                            }
                                                        </p>
                                                        <p className="text-gray-400 text-xs font-montserrat mt-2">
                                                            Data da Publicação:{' '}
                                                            {company.datePublished ||
                                                                'N/A'}
                                                        </p>
                                                        {company.url && (
                                                            <a
                                                                href={
                                                                    company.url
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-xs font-montserrat text-blue-300 inline-block hover:underline"
                                                            >
                                                                Leia Mais
                                                            </a>
                                                        )}
                                                    </div>
                                                    <div
                                                        key={company._id}
                                                        className="flex space-x-4 mt-4"
                                                    >
                                                        <button
                                                            className=" text-center bg-[#111827] text-white p-3 font-montserrat rounded-md hover:bg-[#111827]/70"
                                                            onClick={() =>
                                                                handleToggleLike(
                                                                    company._id
                                                                )
                                                            }
                                                        >
                                                            <div className="ml-0">
                                                                {company.like ? (
                                                                    // Ícone estrela preenchida do React-icons
                                                                    <BiStar
                                                                        size={
                                                                            28
                                                                        }
                                                                        color="#ffd700"
                                                                    />
                                                                ) : (
                                                                    // Ícone estrela vazia do React-icons
                                                                    <BiStar
                                                                        size={
                                                                            28
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </button>
                                                        {company.hasSummary ? (
                                                            <button
                                                                className={`flex-1 block text-center bg-[#57B4B9] text-[#111827] font-montserrat font-semibold p-2 rounded-md hover:bg-[#57B4B9]/90 focus:outline-none focus:ring focus:border-[#57B4B9]/10`}
                                                                onClick={() =>
                                                                    handleDownload(
                                                                        company._id
                                                                    )
                                                                }
                                                                disabled={
                                                                    downloading
                                                                }
                                                            >
                                                                {downloading
                                                                    ? 'Aguarde...'
                                                                    : 'Download'}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className={`flex-1 block text-center bg-gray-700 text-white font-montserrat p-2 rounded-md hover:bg-gray-900 focus:outline-none focus:ring focus:border-gray-300 ${
                                                                    loadingSummarize
                                                                        ? 'opacity-50 cursor-not-allowed'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    handleSummarize(
                                                                        company._id,
                                                                        company.filteredText
                                                                    )
                                                                }
                                                                disabled={
                                                                    loadingSummarize
                                                                }
                                                            >
                                                                {loadingSummarize
                                                                    ? 'Aguarde...'
                                                                    : 'Resumir'}
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center ml-0 -mt-4 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>

            <>
                {analysisCompleted && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden w-96">
                            <div className="px-6 py-4 bg-[#02989E] text-white flex justify-between items-center">
                                <div className="flex items-center">
                                    <FaCheckCircle size={24} className="mr-2" />
                                    <h2 className="text-2xl font-semibold font-montserrat">
                                        Tarefa Concluída
                                    </h2>
                                </div>
                            </div>
                            <div className="px-6 py-4">
                                <p className="text-gray-800 text-2xl font-bold mb-2 font-montserrat">
                                    Relatório
                                </p>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-400 mr-2 font-montserrat text-lg">
                                        Notícias Encontradas:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {numberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Créditos Utilizados:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {numberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Duração:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {formatTime(executionTime)}
                                    </p>
                                </div>

                                <div className="flex items-center mb-6">
                                    <p className="text-gray-700 mr-2 font-montserrat text-xl">
                                        Créditos Disponíveis:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {updatedUserCredits}
                                    </p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        className="text-white bg-[#02989E] px-6 py-2 rounded-md hover:bg-[#02807a] font-semibold focus:outline-none font-montserrat"
                                        onClick={handlePopupOkClick}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta notícia?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};
