import { LandingNavbar } from '../components/Homepage/landing-navbar';
import { MaArchived } from '../components/MarketAnalysis/ma-archived';

const ArchiveCompaniesPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaArchived />
            </div>
        </main>
    );
};

export default ArchiveCompaniesPage;
