import React from 'react';
import { Link } from 'react-router-dom';
import logoNeuronLab from '../../assets/logo-neuronlab-site.png';

interface NavbarProps {
    textoLink: string;
    textoBotao: string;
    linkCadastro: string;
}

export const Navbar: React.FC<NavbarProps> = ({ textoLink, textoBotao, linkCadastro }) => {
    return (
        <nav className="bg-[#111827] px-4 py-3 flex items-center justify-between shadow-md shadow-top-md">
            <Link to="/login" className="flex items-center">
                <div className="w-26 h-10 ml-3">
                    <img
                        src={logoNeuronLab}
                        alt="Neuron Lab"
                        className="w-full h-full object-cover"
                    />
                </div>
            </Link>

            <div className="flex items-center space-x-4 relative">
                {/* Ícone do sino com indicador de novas notificações */}
                <div className="relative">
                    <span className="text-base text-[#ffffff] font-montserrat">
                        {textoLink}{' '}
                        <Link
                            to={linkCadastro}
                            className="text-[#03989E] hover:underline font-semibold"
                        >
                            {textoBotao}
                        </Link>
                    </span>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
