import type Register from '../../models/Register';
import Login from '../../models/Login';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import axios from "axios";


export const loginUser = async ({ email, password }: Login) => {
    console.log(REACT_APP_SERVER_URL)
    return await axios.post(`${REACT_APP_SERVER_URL}/api/auth/users/login`, { email, password });
};

export const createNewUser = async (signUpParams: Register, termsChecked: boolean) => {
    const { name, email, password, confirmPassword, cnpj, profileImageURL,
    } = signUpParams;
    return await axios.post(`${REACT_APP_SERVER_URL}/api/auth/users/register`, {
        name,
        email,
        password,
        confirmPassword, 
        cnpj,
        profileImageURL,
        termsChecked 
    });
};
