import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from '../components/Global/NavBar';
import Header from '../components/Global/Header';
import {
  HomeIcon,
  FolderIcon,
  UserIcon,
  CheckIcon,
  PercentBadgeIcon,
} from '@heroicons/react/24/outline';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { EyeIcon } from '@heroicons/react/24/outline';

const Dashboard = () => {
  const [kpis, setKpis] = useState({
    totalCNPJs: 0,
    totalCPFs: 0,
    regulares: 0,
    percentualAlteracoes: 0,
    monitorings: 0,
  });

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      const bearerToken =
        'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
      axios
        .get(
          `${REACT_APP_SERVER_URL}/api/dash/kpis-dash?user_id=${JSON.parse(
            storedUserId
          )}`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        )
        .then((response) => {
          setKpis(response.data);
        })
        .catch((error) => {
          console.error('Erro ao buscar KPIs:', error);
        });
    }
  }, []);

  const kpiItems = [
    {
      label: 'CNPJs',
      value: kpis.totalCNPJs,
      icon: <FolderIcon className="w-6 h-6 text-[#FFD700]" />,
    },
    {
      label: 'CPFs',
      value: kpis.totalCPFs,
      icon: <UserIcon className="w-6 h-6 text-[#00D3CF]" />,
    },
    {
      label: 'Regulares',
      value: kpis.regulares,
      icon: <CheckIcon className="w-6 h-6 text-[#4CAF50]" />,
    },
    {
      label: '% Regulares',
      value: `${kpis.percentualAlteracoes.toFixed(2)}%`,
      icon: <PercentBadgeIcon className="w-6 h-6 text-[#FFA500]" />,
    },
    {
      label: 'Monitoramentos',
      value: kpis.monitorings,
      icon: <EyeIcon className="w-6 h-6 text-[#FFA500]" />,
    },
  ];

  return (
    <div className="min-h-screen bg-[#0F172A] text-white flex flex-col">
      <NavBar />
      <main className="flex-1 pt-4">
        <div className="px-4 sm:px-6 lg:px-8 space-y-6">

          <div className="w-full mx-auto p-6 rounded-xl">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
              {kpiItems.map(({ label, value, icon }) => (
                <div
                  key={label}
                  className="py-4 px-6 rounded-lg shadow-lg bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 hover:border-orangeNeuron/60 transition-all duration-300"
                >
                  <div className="flex justify-between items-center">
                    <p className="text-xl font-semibold font-montserrat">
                      {label}
                    </p>
                    <div className="p-2 bg-[#ffffff1a] rounded-lg shadow-md">
                      {icon}
                    </div>
                  </div>
                  <p className="text-4xl font-bold mt-2 transition-transform duration-200 hover:scale-105 font-montserrat">
                    {typeof value === 'number'
                      ? value.toLocaleString()
                      : value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
