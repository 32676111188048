import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GrAnalytics } from 'react-icons/gr';
import { FaRegNewspaper, FaTimes } from 'react-icons/fa';
import { TbFiles } from 'react-icons/tb';
import { RiStockLine } from 'react-icons/ri';
import { FaOilWell } from 'react-icons/fa6';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { FaIdCard, FaRegBuilding, FaRegCheckCircle } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';

const services = [
    {
        name: 'Análise de Mercado',
        description:
            'Analise dados de empresas para descobrir tendências e novas oportunidades de crescimento.',
        color: '#FBFCF7',
        bgColor: '#192339',
        route: '/market-analysis',
        icon: <GrAnalytics size={30} color="#ffffff" />,
        image: 'https://skai.io/wp-content/uploads/2021/08/market-intelligence-data-image-fb.png',
        iconBgColor: '#ffffff10',
    },
    {
        name: 'Documentos',
        description:
            'Automatize a revisão de documentos e melhore a eficiência das operações.',
        color: '#4B5563',
        bgColor: '#192339',
        route: '/documents',
        icon: <TbFiles size={30} color="#ffffff" />,
        image: 'https://www.shutterstock.com/image-photo/document-management-system-dms-being-600nw-1874749972.jpg',
        iconBgColor: '#ffffff10',
    },
    {
        name: 'Notícias',
        description:
            'Esteja sempre atualizado com as últimas notícias e tendências importantes para seu negócio.',
        color: '#FBFCF7',
        bgColor: '#192339',
        route: '/news',
        icon: <FaRegNewspaper size={30} color="#ffffff" />,
        image: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400/f9203f43012225.57e05eb56b036.png',
        iconBgColor: '#ffffff10',
    },
    {
        name: 'Mercado Financeiro',
        description:
            'Receba insights valiosos e tome decisões bem informadas sobre seus investimentos.',
        color: '#4B5563',
        bgColor: '#192339',
        route: '/finance',
        icon: <RiStockLine size={30} color="#ffffff" />,
        image: 'https://www.swift.com/sites/default/files/styles/width_1200/public/images/swift_images_dataquality.jpg?itok=-04dKlYw',
        iconBgColor: '#ffffff10',
    },
    {
        name: 'Commodities',
        description:
            'Encontre oportunidades de negociação e gerencie riscos de maneira eficaz.',
        color: '#4B5563',
        bgColor: '#192339',
        route: '/marketing',
        icon: <FaOilWell size={30} color="#ffffff" />,
        image: 'https://www.ifcmarkets.com.br/uploads/image/TradeCommodities.jpg',
        iconBgColor: '#ffffff10',
    },
    {
        name: 'Weather',
        description:
            'Monitore as condições climáticas e planeje suas operações de forma estratégica.',
        color: '#4B5563',
        bgColor: '#192339',
        route: '/marketing',
        icon: <TiWeatherPartlySunny size={30} color="#ffffff" />,
        image: 'https://wallpapers.com/images/hd/weather-scenarios-collage-dbk9c5n23l7e5fgb.jpg',
        iconBgColor: '#ffffff10',
    },
];

export const LandingContent = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleMarketAnalysisClick = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="px-6 pb-6 -mt-12 mb-16 relative overflow-hidden">
            <div className="w-screen overflow-x-auto hide-scrollbar">
                <div className="flex">
                    {services.map(item => {
                        const serviceContent = (
                            <div
                                key={item.description}
                                className="w-72 shadow-lg rounded-xl rounded-b-2xl overflow-hidden mr-10 relative"
                                onClick={
                                    item.name === 'Análise de Mercado'
                                        ? handleMarketAnalysisClick
                                        : undefined
                                }
                            >
                                <div
                                    className="h-52 bg-cover bg-center bg-no-repeat relative transform transition-transform hover:scale-105 shadow-xl cursor-pointer"
                                    style={{
                                        backgroundImage: `url(${item.image})`,
                                    }}
                                >
                                    <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-50 text-white">
                                        <div
                                            className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded mr-1"
                                            style={{
                                                backgroundColor:
                                                    item.iconBgColor,
                                            }}
                                        >
                                            <div className="p-2">
                                                {item.icon}
                                            </div>
                                        </div>
                                        <h5 className="text-2xl font-semibold font-montserrat mt-3">
                                            {item.name}
                                        </h5>
                                    </div>
                                </div>
                                <div
                                    className={`p-4 flex flex-col mb-4 rounded-b-lg`}
                                    style={{ backgroundColor: item.bgColor }}
                                >
                                    <p
                                        className="text-[#FBFCF7] text-sm font-medium font-montserrat"
                                        style={{
                                            maxHeight: '80px',
                                            lineHeight: '1.2',
                                        }}
                                    >
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        );

                        if (item.name === 'Documentos') {
                            return (
                                <Link key={item.description} to={item.route}>
                                    {serviceContent}
                                </Link>
                            );
                        } else {
                            return (
                                <div key={item.description}>
                                    {serviceContent}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold  mt-4">
                            Escolha o tipo de análise
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-4">
                            Selecione o tipo de documento que você deseja
                            analisar.
                        </p>
                        {/* Botão de fechar no canto superior direito */}
                        <button
                            onClick={handlePopupClose}
                            className="absolute top-3 right-3 text-white rounded-lg p-2 hover:bg-[#3B3B3B] transition ease-in-out duration-300"
                        >
                            <FaTimes size={20} />
                        </button>
                        {/* Botões CNPJ e CPF */}
                        <div className="flex justify-center space-x-4 mt-6">
                            <Link to="/market-analysis">
                                <button className="flex items-center justify-center w-52 h-20 bg-[#192339] text-white rounded-lg font-montserrat text-xl font-semibold border border-[#2a3e5e] hover:bg-[#57B4B9] hover:text-black transition duration-300">
                                    <FaRegBuilding size={32} className="mr-3" />
                                    CNPJ
                                </button>
                            </Link>
                            <Link to="/market-analysis-cpf">
                                <button className="flex items-center justify-center w-52 h-20 bg-[#192339] text-white rounded-lg font-montserrat text-xl font-semibold border border-[#2a3e5e] hover:bg-[#57B4B9] hover:text-black transition duration-300">
                                    <FaIdCard size={32} className="mr-3" />
                                    CPF
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
