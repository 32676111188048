import { DocumentsFolder } from '../../components/Documents/documents-folders';
import { LandingNavbar } from '../../components/Homepage/landing-navbar';

const DocumentsFolderPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <DocumentsFolder />
            </div>
        </main>
    );
};

export default DocumentsFolderPage;
