import { DocumentsHero } from '../components/Documents/documents-hero';
import { LandingNavbar } from '../components/Homepage/landing-navbar';

const DocumentsPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <DocumentsHero />
            </div>
        </main>
    );
};

export default DocumentsPage;
