import LandingFooter from '../components/Homepage/landing-footer';
import { LandingNavbar } from '../components/Homepage/landing-navbar';
import { NewsHero } from '../components/News/news-hero';

const NewsPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <NewsHero />
                <LandingFooter />
            </div>
        </main>
    );
};

export default NewsPage;
