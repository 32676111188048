import { LandingNavbar } from '../components/Homepage/landing-navbar';
import { MaHero } from '../components/MarketAnalysis/ma-hero';

const MarketAnalysisPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaHero />
            </div>
        </main>
    );
};

export default MarketAnalysisPage;
