interface EstadoImagens {
    [key: string]: string;
}

export const stateImages: EstadoImagens = {
    AC: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Bandeira_do_Acre.svg/640px-Bandeira_do_Acre.svg.png",
    AL: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Bandeira_de_Alagoas.svg/1200px-Bandeira_de_Alagoas.svg.png",
    AP: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Bandeira_do_Amap%C3%A1.svg",
    AM: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Bandeira_do_Amazonas.svg/1200px-Bandeira_do_Amazonas.svg.png",
    BA: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Bandeira_da_Bahia.svg/1200px-Bandeira_da_Bahia.svg.png",
    CE: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Bandeira_do_Cear%C3%A1.svg/1200px-Bandeira_do_Cear%C3%A1.svg.png",
    DF: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Bandeira_do_Distrito_Federal_%28Brasil%29.svg",
    ES: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Bandeira_do_Esp%C3%ADrito_Santo.svg/1200px-Bandeira_do_Esp%C3%ADrito_Santo.svg.png",
    GO: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Flag_of_Goi%C3%A1s.svg/1200px-Flag_of_Goi%C3%A1s.svg.png",
    MA: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Bandeira_do_Maranh%C3%A3o.svg/1200px-Bandeira_do_Maranh%C3%A3o.svg.png",
    MT: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Bandeira_de_Mato_Grosso.svg/2000px-Bandeira_de_Mato_Grosso.svg.png",
    MS: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Bandeira_de_Mato_Grosso_do_Sul.svg/1200px-Bandeira_de_Mato_Grosso_do_Sul.svg.png",
    MG: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Bandeira_de_Minas_Gerais.svg/1200px-Bandeira_de_Minas_Gerais.svg.png",
    PA: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Bandeira_do_Par%C3%A1.svg/1200px-Bandeira_do_Par%C3%A1.svg.png",
    PB: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Bandeira_da_Para%C3%ADba.svg/1200px-Bandeira_da_Para%C3%ADba.svg.png",
    PR: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Bandeira_do_Paran%C3%A1.svg/1200px-Bandeira_do_Paran%C3%A1.svg.png",
    PE: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Bandeira_de_Pernambuco.svg/2560px-Bandeira_de_Pernambuco.svg.png",
    PI: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Bandeira_do_Piau%C3%AD.svg/1200px-Bandeira_do_Piau%C3%AD.svg.png",
    RJ: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnyjzw81cI7Ej_zmw2x4-kNlHLcHiV6BMbLA&s",
    RN: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Bandeira_do_Rio_Grande_do_Norte.svg/1200px-Bandeira_do_Rio_Grande_do_Norte.svg.png",
    RS: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Bandeira_do_Rio_Grande_do_Sul.svg/1200px-Bandeira_do_Rio_Grande_do_Sul.svg.png",
    RO: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Bandeira_de_Rond%C3%B4nia.svg/1200px-Bandeira_de_Rond%C3%B4nia.svg.png",
    RR: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Bandeira_de_Roraima.svg/1200px-Bandeira_de_Roraima.svg.png",
    SC: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Bandeira_de_Santa_Catarina.svg/1200px-Bandeira_de_Santa_Catarina.svg.png",
    SP: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Bandeira_do_estado_de_S%C3%A3o_Paulo.svg/1200px-Bandeira_do_estado_de_S%C3%A3o_Paulo.svg.png",
    SE: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Bandeira_de_Sergipe.svg/1200px-Bandeira_de_Sergipe.svg.png",
    TO: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Bandeira_do_Tocantins.svg/486px-Bandeira_do_Tocantins.svg.png",
};
