// components/Header.tsx
import { ElementType } from 'react';
import { Link } from 'react-router-dom';
import { RiRobot2Line } from 'react-icons/ri';

interface HeaderProps {
  title: string;
  Icon: ElementType; // Componente de ícone a ser exibido
  route?: string; // Rota opcional para quando o título for clicado
  marginLeftClass?: string; // Nova propriedade para controlar o margin-left
}

const Header: React.FC<HeaderProps> = ({ title, Icon, route, marginLeftClass = '-ml-16' }) => {
  const titleElement = (
    <span className="text-white">{title}</span>
  );

  return (
    <header className="bg-dark text-white shadow-sm border-t border-white/10">
      <div className="max-w-7xl pr-4 py-3 sm:px-6 lg:px-8 mt-2">
        <h1 className={`flex items-center text-3xl font-semibold font-montserrat tracking-tight ${marginLeftClass}`}>
          <Icon
            className="w-6 h-6 mr-2 text-orangeNeuron"
            aria-hidden="true"
          />
          {route ? (
            <Link to={route}>{titleElement}</Link>
          ) : (
            titleElement
          )}
          {/* Badge "Online" */}
          <span className="ml-4 px-4 py-1 text-base font-bold text-dark bg-gradient-to-r from-green-400 to-green-600 border border-green-700 rounded-lg shadow-lg flex items-center animate-pulse font-montserrat tracking-wide">
            <RiRobot2Line className="w-4 h-4 mr-2 opacity-90" />
            <span>IA Online</span>
          </span>
        </h1>
      </div>
    </header>
  );
};

export default Header;
