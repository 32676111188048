import React from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../Global/Breadcrumb';

import { HiServer } from 'react-icons/hi';

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const MaHelpFeedbackPage = () => {
    const { id } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { label: 'Ajuda e Feedback' },
    ];

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6 ">
                <div className="flex ml-10 mt-1">
                    <div
                        className="w-[1100px] h-[580px] p-4 mr-6 -ml-8 overflow-y-auto flex"
                        style={{ scrollbarColor: '#6B7280 #4B5563' }}
                    >
                        <div className="container mx-auto mt-56">
                            <p className="text-gray-300 text-xl font-montserrat">
                                Em breve
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className=" items-center text-2xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                        <HiServer className="h-8 w-8 mr-2 text-[#27395e] mb-2" />
                        Ajuda e Feedback
                    </h3>
                    <p className="text-gray-300 text-base font-montserrat mt-0">
                        Aqui você pode encontrar recursos de ajuda e fornecer feedback sobre nossos serviços.
                    </p>

                    <p className="text-gray-300 text-base font-montserrat mt-4">
                        Estamos trabalhando constantemente para melhorar sua experiência e valorizamos seus comentários.
                    </p>
                </div>
            </div>
        </div>
    );
};
