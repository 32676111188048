import { FormEvent, useState, ChangeEvent } from 'react';
import { setUserInLocalStorageWithResponseData } from '../../services';
import { loginUser } from '../../api/users/authentication';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import Navbar from './Navbar';

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        if (trimmedEmail !== '' && trimmedPassword !== '') {
            if (!isEmailValid(trimmedEmail)) {
                setError('Por favor, insira um endereço de e-mail válido!');
                return;
            }

            try {
                const response = await loginUser({
                    email: trimmedEmail,
                    password: trimmedPassword,
                });

                if (response) {
                    setUserInLocalStorageWithResponseData(response.data);
                    navigate('/');
                }
            } catch (err) {
                console.log(err);
                if (err instanceof AxiosError && err.response) {
                    if (err.response.status === 404) {
                        setError(
                            'Usuário não existe. Verifique suas credenciais.'
                        );
                    } else if (err.response.status === 401) {
                        if (
                            err.response.data.message ===
                            'Sua conta ainda não foi verificada pela nossa equipe. Logo você receberá um e-mail com todas as informações necessárias.'
                        ) {
                            setError(
                                'Sua conta ainda não foi verificada pela nossa equipe. Logo você receberá um e-mail com todas as informações necessárias.'
                            );
                        } else {
                            setError(
                                'Senha incorreta. Por favor, tente novamente.'
                            );
                        }
                    } else {
                        setError(err.response.data.error);
                    }
                } else if (err instanceof Error) {
                    setError(err.message);
                }
            }
        } else {
            setError('Por favor, preencha todos os campos!');
        }
    };

    return (
        <div>
            <Navbar
                textoLink="Não tem conta?"
                textoBotao="Cadastre-se!"
                linkCadastro="/signup"
            />
            <div className="min-h-screen flex items-center justify-center bg-[#111827]">
                <div className="w-full max-w-lg p-8 bg-[#192339] rounded-lg shadow-2xl mx-auto -mt-10">
                    <h1 className="text-2xl font-normal mb-2 text-white font-montserrat text-center">
                        Faça seu login na{' '}
                        <span className="font-bold text-[#03989E]">Neuron Lab</span>.
                    </h1>

                    <form
                        onSubmit={onFormSubmit}
                        className="grid grid-cols-1 gap-4 mt-6"
                    >
                        {error && (
                            <p className="error-message bg-red-500 text-white font-semibold py-2 px-4 rounded-md font-montserrat">
                                {error}
                            </p>
                        )}
                        <input
                            type="email"
                            placeholder="Digite seu e-mail..."
                            onChange={onEmailChange}
                            className="input-field text-[#003366] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg mt-0"
                        />

                        <div className="flex items-center">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Digite sua senha..."
                                onChange={onPasswordChange}
                                className="input-field text-[#003366] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg flex-1"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="text-gray-100 hover:text-gray-700 ml-4"
                            >
                                {showPassword ? (
                                    <FaEyeSlash size={20} />
                                ) : (
                                    <FaEye />
                                )}
                            </button>
                        </div>
                        <div className="flex items-center justify-end mt-4">
                            <input
                                type="submit"
                                value="Entrar"
                                className="bg-[#03989E] hover:bg-[#03989E]/90 text-white text-lg font-normal py-3 px-12 rounded-md shadow-lg focus:outline-none focus:ring-2 focus:ring-[#03989E]/90 focus:ring-opacity-50 font-montserrat"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
