import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import axios from 'axios';

import Breadcrumb from '../Global/Breadcrumb';

import { LiaBirthdayCakeSolid } from 'react-icons/lia';
import { MdOutlineFileDownload } from 'react-icons/md';
import { BiBuildingHouse, BiCalendar, BiWorld } from 'react-icons/bi';
import { RiPassValidLine } from 'react-icons/ri';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineAssessment } from 'react-icons/md';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { IoBusinessOutline } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import { IoAnalytics } from 'react-icons/io5';
import { FiArchive } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { MdOutlineExpandLess } from 'react-icons/md';
import { MdOutlineExpandMore } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { MdMoreVert, MdDelete } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import { RiFolderSharedLine } from 'react-icons/ri';
import { MdDeleteOutline } from 'react-icons/md';
import { IoCalendarOutline } from 'react-icons/io5';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface CompanyData {
    nome: string;
    cpf: string;
    nascimento: string;
    situacao: string;
    situacaoMotivo: string;
    registrations_count: number;
    idade: string;
    maior_de_18: string;
    registrations: Registration[]; // Adicionar o campo de registros
}

interface CompanyDataDownload {
    date: string;
    cpf: string;
    name: string;
    nascimento: string;
    anoNascimento: string;
    idade: string;
    status: string;
    situacao: string;
}

interface Registration {
    cnpj: string;
    razao: string;
    fantasia: string;
    dataSociedade: string;
    qualificacao: string;
    situacao: string;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

interface DownloadData {
    taxId: string;
    name: string;
    date: string;
    alias: string;
    address: String;
    addon_address: String;
    district: String;
    zip: String;
    city: String;
    state: String;
    status: String;
    registrations_count: String;
    most_recent_registration_number: String;
    most_recent_registration_status: String;
    most_recent_registration_state: String;
    most_recent_registration_status_text: String;
    simples_optant: String;
    simei_optant: String;
}

export const MaCPFCompanies = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupDeleteCompany, setIsPopupDeleteCompany] = useState(false);
    const [isPopupMoveFolder, setIsPopupMoveFolder] = useState(false);
    const [folders, setFolders] = useState<string[]>([]);
    const [selectedTaxId, setSelectedTaxId] = useState<string | null>(null);
    const [isPopupOpenAlert, setIsPopupOpenAlert] = useState(false);
    const [isPopupOpenDownload, setIsPopupOpenDownload] = useState(false);
    const [isPopupOpenArchive, setIsPopupOpenArchive] = useState(false);
    const [isPopupOpenMap, setIsPopupOpenMap] = useState(false);
    const [folderName, setFolderName] = useState<string>('');
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [registrationsCount, setRegistrationsCount] = useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [copiedText, setCopiedText] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [statusMessageMove, setStatusMessageMove] = useState('');
    const [statusMessageMonitor, setStatusMessageMonitor] = useState('');
    const [statusMessageArchive, setStatusMessageArchive] = useState('');
    const [statusMessageDelete, setStatusMessageDelete] = useState('');
    const [taxIdCount, setTaxIdCount] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(null);
    const [companyData, setCompanyData] = useState<CompanyData[]>([]);
    const [companyDataDownload, setCompanyDataDownload] = useState<
        CompanyDataDownload[]
    >([]);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [repeatOption, setRepeatOption] = useState<string | null>(null);
    const isSaveEnabled = selectedDateTime !== '';
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage] = useState(5);
    const [pageDownload, setPageDownload] = useState(1);
    const [perPageDownload] = useState(500);
    const [error, setError] = useState<string | null>(null); // Ajuste de tipo
    const [searchTerm, setSearchTerm] = useState('');
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [menuOpen, setMenuOpen] = useState(
        Array(companyData.length).fill(false)
    );

    const [availability, setAvailability] = useState<{
        status: string;
        data: string;
    } | null>(null);

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis-cpf', label: 'Análise de Mercado - CPF' }, // Adiciona a rota aqui
        { label: id || 'N/A' },
    ];

    const [isSaveEnabledMove, setIsSaveEnabledMove] = useState(false); // Adicione esse estado

    useEffect(() => {
        setIsSaveEnabledMove(isFolderSelected);
    }, [isFolderSelected]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/move-folders/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )

                .then(response => {
                    console.log(response.data);
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/availability/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const { status, data } = response.data;
                    setAvailability({ status, data });
                })
                .catch(error => {
                    console.error('Error fetching availability:', error);
                    // Handle error if needed
                });
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');

            if (storedUserId) {
                const folder = id; // Obtém o parâmetro folder da URL

                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/personal-data/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&page=${page}&perPage=${perPage}`
                    );
                    if (page === 1) {
                        // Se for a primeira página, substitui os dados anteriores
                        setCompanyData(response.data);
                    } else {
                        // Caso contrário, acumula os dados novos aos anteriores
                        setCompanyData(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações da empresa.');
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };

        fetchData();
    }, [id, page, perPage]);

    const nextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');

            if (storedUserId) {
                const folder = id; // Obtém o parâmetro folder da URL

                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/download-personal-data/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&pageDownload=${pageDownload}&perPageDownload=${perPageDownload}`
                    );
                    if (pageDownload === 1) {
                        // Se for a primeira página, substitui os dados anteriores
                        setCompanyDataDownload(response.data);
                    } else {
                        // Caso contrário, acumula os dados novos aos anteriores
                        setCompanyDataDownload(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações da empresa.');
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };

        fetchData();
    }, [id, pageDownload, perPageDownload]);

    const nextPageDownload = () => {
        setPageDownload(prevPage => prevPage + 1);
    };

    const prevPageDownload = () => {
        setPageDownload(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const getStatusStyle = (status: string) => {
        switch (status) {
            case 'Nula':
                return { backgroundColor: '#D1D5DB', color: '#000000' };
            case 'Regular':
                return { backgroundColor: '#10B981', color: '#FFFFFF' };
            case 'Pendente':
                return { backgroundColor: '#FBBF24', color: '#000000' };
            case 'Inapta':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            case 'Ativa Não Regular':
                return { backgroundColor: '#3B82F6', color: '#FFFFFF' };
            case 'Cancelado':
                return { backgroundColor: '#8B5CF6', color: '#FFFFFF' };
            default:
                return {}; // Padrão para outros casos
        }
    };

    const getSimplesOptantStyle = (simplesOptant: string) => {
        switch (simplesOptant) {
            case 'Optante':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' }; // Verde claro com texto branco
            case 'Não Optante':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' }; // Laranja com texto branco
            default:
                return {}; // Padrão para outros casos
        }
    };

    const getSimeiOptantStyle = (simeiOptant: string) => {
        switch (simeiOptant) {
            case 'Enquadrado':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' }; // Azul com texto branco
            case 'Não Enquadrado':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' }; // Roxo com texto branco
            default:
                return {}; // Padrão para outros casos
        }
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            const schedule = selectedDateTime;
            const folderId = id;
            let repeat = repeatOption;

            if (!repeat) {
                repeat = 'empty';
            }

            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/schedule`;

            try {
                await axios.post(
                    endpointMonitoring,
                    {
                        schedule: schedule,
                        user_id: userId,
                        folder: folderId,
                        repeat: repeat,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                );
                setStatusMessageMonitor('Monitoramento Agendado com Sucesso'); // Define a mensagem de sucesso

                // Limpar a mensagem após 3 segundos
                setTimeout(() => {
                    setStatusMessageMonitor('');
                    setIsPopupOpen(false);
                }, 2000); // 2000 milissegundos = 2 segundos
            } catch (error) {
                console.error('Request error:', error);
            }
        } else {
            setLoading(false);
        }
    };

    const handleDownloadClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            const folderId = id;
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/download/${userId}`;

            const requestData = {
                folder: folderId || '', // Garantindo que 'folder' seja uma string
                start_date: selectedStartDate,
                end_date: selectedEndDate, // Preenche com a data atual se estiver vazio
            };

            try {
                const response = await axios.get(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    params: requestData,
                });

                // Redefinir o estado download para garantir que os novos dados sejam exibidos
                setDownload([]);

                // Armazena os dados obtidos para uso posterior
                setDownload(response.data);

                console.log('Dados recebidos:', response.data); // Adicione este log para verificar os dados recebidos

                setStatusMessage('Download Feito com Sucesso'); // Define a mensagem de sucesso

                // Após o sucesso da requisição, chame a função para download do Excel
                console.log('Chamando handleDownloadExcel...'); // Adicione este log para verificar se handleDownloadExcel() está sendo chamado
                await handleDownloadExcel();

                console.log('Download do Excel concluído com sucesso.'); // Adicione este log para verificar se a geração do Excel foi bem-sucedida

                // Limpar a mensagem após 3 segundos
                setTimeout(() => {
                    setStatusMessage('');
                    setIsPopupOpenDownload(false);
                }, 2000); // 2000 milissegundos = 2 segundos
            } catch (error) {
                console.error('Request error:', error);
                setStatusMessage('Erro no Download'); // Define a mensagem de erro
            }
        } else {
            setLoading(false);
        }
    };

    const handleDownloadExcel = async () => {
        console.log('Download state:', companyDataDownload);

        if (companyDataDownload.length === 0) {
            console.log('Nenhum dado para exportar.');
            return;
        }

        const rows = companyDataDownload.map(company => {
            return {
                DATA: company.date || 'N/A',
                CPF: company.cpf || 'N/A',
                Nome: company.name || 'N/A',
                'Data Nascimento': company.nascimento || 'N/A',
                'Ano de Nascimento': company.anoNascimento || 'N/A',
                Idade: company.idade || 'N/A',
                Status: company.status || 'N/A',
                'Situação Cadastral': company.situacao || 'N/A',
            };
        });

        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Informações');

        // Obtém a data atual no formato YYYY-MM-DD
        const today = new Date().toISOString().split('T')[0];
        const fileName = `CPF-${today}.xlsx`;

        XLSX.writeFile(workbook, fileName, { compression: true });
    };

    const handleArchiveClick = async () => {
        const user = getUserFromLocalStorage();
        setLoading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folderId = id; // Certifique-se de que id esteja definido corretamente
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/archive-folder-cpf/${userId}/${folderId}`;

            try {
                await axios.put(endpointMonitoring, null, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageArchive('Pasta Arquivada com Sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e navegar para a rota '/market-analysis'
                setTimeout(() => {
                    setIsPopupOpenArchive(false);
                    navigate('/market-analysis-cpf');
                }, 1000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setLoading(false);
            setIsPopupOpenArchive(false);
            navigate('/market-analysis-cpf'); // Redirecionar para a rota '/market-analysis'
        }
    };

    const handleMoveFolder = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folderId = id; // Certifique-se de que id esteja definido corretamente
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/update-company-folder/${userId}/${folderId}/${selectedTaxId}`;

            try {
                await axios.put(
                    endpointMonitoring,
                    { newFolder: folderName },
                    {
                        // Envia o folderName como newFolder na requisição
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                );
                setStatusMessageMove('Pasta Arquivada com Sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e navegar para a rota '/market-analysis'
                setTimeout(() => {
                    setIsPopupOpenArchive(false);
                    navigate('/market-analysis');
                }, 1000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setLoading(false);
            setIsPopupOpenArchive(false);
            navigate('/market-analysis'); // Redirecionar para a rota '/market-analysis'
        }
    };

    const handleRepeatOption = (option: string) => {
        if (repeatOption === option) {
            // Se a opção atual já estiver selecionada, desmarque-a
            setRepeatOption(null);
        } else {
            // Caso contrário, selecione a opção clicada
            setRepeatOption(option);
        }
    };

    const handleDeleteCompanyClick = async (cnpj: string | null) => {
        const user = getUserFromLocalStorage();

        if (cnpj && user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folderId = id; // Certifique-se de que id esteja definido corretamente
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/delete-company-folder/${userId}/${folderId}/${cnpj}`;

            try {
                await axios.delete(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageDelete('Empresa Deletada com Sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e navegar para a rota '/market-analysis'
                setTimeout(() => {
                    setIsPopupOpenArchive(false);
                    navigate('/market-analysis');
                }, 1000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setLoading(false);
            setIsPopupDeleteCompany(false);
        }
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-5">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="text-white ml-6 -mt-5">
                <input
                    type="text"
                    id="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Pesquisar pelo CPF..."
                    className="w-[1055px] h-[40px] px-4 py-2 text-base rounded-md shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] font-montserrat text-white"
                />
            </div>

            <div className="flex ml-10 mt-1 ">
                <div
                    className="w-[1100px] h-[550px] p-4 mr-6 -ml-8 rounded-xl  overflow-y-auto"
                    style={{ scrollbarColor: '#6B7280 #4B5563' }}
                >
                    {companyData
                        .filter(company => company.cpf.includes(searchTerm))
                        .map((company, index) => (
                            <div
                                key={index}
                                className="bg-gradient-to-b from-[#0E1621] to-[#192339] rounded-xl shadow-md p-4 mb-4 mt-4 flex"
                            >
                                <div className="flex-1 mr-8">
                                    <div className="flex items-center">
                                        <div>
                                            <p
                                                className="text-sm font-medium font-montserrat overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap mt-3"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.nome || 'N/A'
                                                    )
                                                }
                                            >
                                                <h2 className="text-white text-xl font-bold font-montserrat">
                                                    {truncateText(
                                                        company.nome,
                                                        34
                                                    )}
                                                </h2>
                                            </p>

                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                        </div>
                                        <p
                                            className="text-base font-montserrat rounded-lg px-2 py-1 text-center font-bold ml-3 mt-3"
                                            style={getStatusStyle(
                                                company.situacao
                                            )}
                                            onClick={() =>
                                                handleCopyClick(
                                                    company.situacao || 'N/A'
                                                )
                                            }
                                        >
                                            {company.situacao}
                                        </p>
                                    </div>
                                    <p
                                        className="text-gray-200 flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                `CPF: ${company.cpf || 'N/A'}`
                                            )
                                        }
                                    >
                                        CPF: {company.cpf || 'N/A'}
                                    </p>
                                    <p
                                        className="text-white flex items-center text-base font-montserrat mt-4"
                                        onClick={() =>
                                            handleCopyClick(
                                                `Fundada em: ${
                                                    company.nascimento || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        <BiCalendar className="mr-1 w-5 h-5" />{' '}
                                        Data de Nascimento:{' '}
                                        {company.nascimento || 'N/A'}
                                    </p>

                                    <p
                                        className="text-white flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.idade || 'N/A'
                                            )
                                        }
                                    >
                                        <LiaBirthdayCakeSolid className="mr-1 w-5 h-5" />
                                        {company.idade} anos
                                    </p>

                                    <p
                                        className="text-white flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.maior_de_18 || 'N/A'
                                            )
                                        }
                                    >
                                        <RiPassValidLine className="mr-1 w-5 h-5" />
                                        {company.maior_de_18}
                                    </p>
                                </div>
                                <div className="flex-1 ml-4 -mt-7">
                                    <div className="mt-5 ml-96 relative">
                                        {/* Botão de Menu */}
                                        <button
                                            onClick={() => {
                                                const updatedMenuOpen = [
                                                    ...menuOpen,
                                                ];
                                                updatedMenuOpen[index] =
                                                    !updatedMenuOpen[index];
                                                setMenuOpen(updatedMenuOpen);
                                            }}
                                            className="text-white hover:text-gray-300 focus:outline-none ml-20 mt-2"
                                        >
                                            <MdMoreVert className="w-7 h-7" />
                                        </button>
                                        {/* Menu Suspenso */}
                                        {menuOpen[index] && (
                                            <div className="absolute z-40 top-full right-0 mt-1 bg-[#2a3e5e] border border-gray-700 rounded shadow w-40 font-montserrat">
                                                <button
                                                    onClick={() => {
                                                        setIsPopupDeleteCompany(
                                                            true
                                                        );
                                                        setSelectedTaxId(
                                                            company.cpf
                                                        ); // definir o taxId aqui
                                                    }}
                                                    className="block w-full py-2 px-4 text-left text-white hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none"
                                                >
                                                    <MdDelete className="inline-block w-5 h-5 mr-2" />
                                                    Excluir
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsPopupMoveFolder(
                                                            true
                                                        );
                                                        setSelectedTaxId(
                                                            company.cpf
                                                        );
                                                    }}
                                                    className="block w-full py-2 px-4 text-left text-white hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none"
                                                >
                                                    <RiFolderSharedLine className="inline-block w-5 h-5 mr-2" />
                                                    Mover
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className="w-[500px] ">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 -mt-4 -ml-10">
                                            <MdOutlineMapsHomeWork className="mr-2 w-7 h-7" />{' '}
                                            Empresas Vinculadas -{' '}
                                            {company.registrations_count}
                                        </h3>

                                        {company.registrations_count === 0 ? (
                                            <div className="flex justify-center items-center w-full h-32">
                                                <MdErrorOutline className="w-16 h-16 text-[#212e4b]" />
                                            </div>
                                        ) : (
                                            <div className="flex overflow-x-auto -ml-10">
                                                {company.registrations.map(
                                                    (registration, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="rounded-lg p-4 m-2 flex-shrink-0 font-montserrat text-black bg-[#d6d7da] mb-4"
                                                        >
                                                            <p
                                                                className="text-black font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `CNPJ: ${registration.cnpj}`
                                                                    )
                                                                }
                                                            >
                                                                CNPJ:{' '}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.cnpj
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Razão Social: ${registration.razao.slice(
                                                                            0,
                                                                            20
                                                                        )}${
                                                                            registration
                                                                                .razao
                                                                                .length >
                                                                            20
                                                                                ? '...'
                                                                                : ''
                                                                        }`
                                                                    )
                                                                }
                                                            >
                                                                Razão Social:{' '}
                                                                <span className="font-semibold">
                                                                    {registration.razao.slice(
                                                                        0,
                                                                        25
                                                                    )}
                                                                    {registration
                                                                        .razao
                                                                        .length >
                                                                    20
                                                                        ? '...'
                                                                        : ''}
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black mt-1 font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Data da Sociedade: ${registration.dataSociedade}`
                                                                    )
                                                                }
                                                            >
                                                                Data da
                                                                Sociedade:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.dataSociedade
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black mt-1"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Situação: ${registration.situacao}`
                                                                    )
                                                                }
                                                            >
                                                                Situação:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.situacao
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {loading && (
                        <p className="font-monteserrat text-white">
                            Carregando...
                        </p>
                    )}
                    {error && <p>{error}</p>}
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={prevPage}
                            disabled={page === 1 || loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandLess className="w-6 h-6 " />
                        </button>
                        <button
                            onClick={nextPage}
                            disabled={loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandMore className="w-6 h-6 " />
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 -mt-5">
                        <RiRobot2Line className="mr-2 w-7 h-7" /> Escolha uma
                        ação
                    </h3>
                    <button
                        onClick={() => {
                            setIsPopupOpenDownload(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-56 mt-1 border border-transparent"
                    >
                        <MdOutlineFileDownload className="w-6 h-6 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Downloads
                        </span>
                    </button>
                    <button
                        onClick={() => {
                            setIsPopupOpenArchive(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-56 mt-3 border border-transparent"
                    >
                        <FiArchive className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Arquivar
                        </span>
                    </button>

                    <button
                        onClick={() => {
                            setIsPopupOpenMap(true);
                        }}
                        disabled={true} // Botão desabilitado
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-gray-400 cursor-not-allowed opacity-50 hover:bg-gray-400 focus:outline-none w-56 mt-3 border border-transparent"
                    >
                        <FiMapPin className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Mapa
                        </span>
                    </button>

                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-10">
                        <IoAnalytics className="mr-2 w-7 h-7" /> Indicadores
                    </h3>

                    <div className="flex mt-2 -mb-4 w-full">
                        {taxIdCount !== null && (
                            <div className="text-lg font-montserrat  text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3  w-56">
                                {taxIdCount} CNPJs
                            </div>
                        )}
                    </div>

                    <div className="flex mt-6 w-56">
                        {registrationsCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339]  p-3 w-80">
                                {registrationsCount} IEs
                            </div>
                        )}
                    </div>
                    <div className="flex mt-2 w-56">
                        {activeStatusCount !== null && ( // Renderizar o novo campo activeStatusCount
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3 w-80">
                                {activeStatusCount} Ativa(s)
                            </div>
                        )}
                    </div>
                </div>

                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-2xl p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                Adicionar Monitoramento
                            </h2>
                            <p className="text-gray-300 font-montserrat text-sm mb-6">
                                Selecione abaixo os horários que você deseja
                                monitorar.
                            </p>
                            <div className="relative mb-3">
                                <label
                                    htmlFor="dateTimePicker"
                                    className="block text-white font-semibold mb-2 font-montserrat"
                                >
                                    Selecione a Data e Hora:
                                </label>
                                <div className="flex items-center">
                                    <input
                                        type="datetime-local"
                                        id="dateTimePicker"
                                        value={selectedDateTime}
                                        onChange={e =>
                                            setSelectedDateTime(e.target.value)
                                        }
                                        className="font-montserrat block w-full py-2 px-4 border border-gray-600 text-[#0E1621] rounded-md shadow-sm focus:outline-none focus:ring-[#02989E]/90 focus:border-[#02989E] cursor-pointer"
                                    />
                                </div>
                            </div>

                            <div className="relative mb-10">
                                <label className="block text-gray-400 font-medium mb-2 font-montserrat">
                                    Repetir:
                                </label>
                                <div className="grid grid-cols-3 gap-4">
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('bimestral')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'bimestral'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Bimestral
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('mensal')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'mensal'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Mensal
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('quinzenal_q1')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'quinzenal_q1'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Quinzenal (Q1)
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('quinzenal_q2')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'quinzenal_q2'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Quinzenal (Q2)
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('semanal')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'semanal'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Semanal
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('diario')
                                        }
                                        className={`flex items-center justify-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'diario'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Diário
                                    </button>
                                </div>
                            </div>

                            {statusMessageMonitor && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat -mt-5">
                                    {statusMessageMonitor}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpen(false)}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>

                                <button
                                    onClick={handleSearchClick}
                                    disabled={!isSaveEnabled}
                                    className={`flex items-center px-6 py-3 text-white ${
                                        isSaveEnabled
                                            ? 'bg-blue-600 hover:bg-blue-700'
                                            : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                    } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenAlert && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                Adicionar Alertas
                            </h2>
                            <p className="text-gray-300 font-montserrat text-sm mb-6">
                                Adicione Alertas e Monitore sua base de clientes
                            </p>
                            <div className="relative mb-3"> Alertas </div>

                            {statusMessage && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat -mt-5">
                                    {statusMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenAlert(false)}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>

                                <button
                                    onClick={handleSearchClick}
                                    disabled={!isSaveEnabled}
                                    className={`flex items-center px-6 py-3 text-white ${
                                        isSaveEnabled
                                            ? 'bg-blue-600 hover:bg-blue-700'
                                            : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                    } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenDownload && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-[1300px] h-[700px] p-8 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="flex justify-end">
                                <button
                                    onClick={() =>
                                        setIsPopupOpenDownload(false)
                                    }
                                    className="text-gray-300 hover:text-gray-400 focus:outline-none absolute top-4 right-4"
                                >
                                    <IoMdCloseCircleOutline className="h-6 w-6" />{' '}
                                    {/* Ícone de fechar */}
                                </button>
                            </div>
                            <h2 className="text-[#57B4B9] font-montserrat text-3xl font-semibold mb-0 -mt-2">
                                Baixar Dados Empresariais
                            </h2>
                            <p className="text-gray-300 font-montserrat text-base mb-5">
                                Baixe os dados detalhados das empresas em
                                formato Excel.
                            </p>

                            <button
                                onClick={handleDownloadExcel}
                                className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md font-montserrat hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400 absolute top-16 right-8 mb-4"
                            >
                                Download Excel
                            </button>
                            <div className="overflow-x-auto h-[500px] mt-12">
                                <div className="sticky top-0">
                                    <table className="w-full table-fixed border-collapse">
                                        {/* Cabeçalho da tabela */}
                                        <thead className="bg-[#0E1621] text-white font-normal font-montserrat text-xs">
                                            <tr className="border-b border-[#57B4B9]">
                                                <th className="px-3 py-2 w-[50px]">
                                                    Nº
                                                </th>
                                                <th className="px-3 py-2 w-[100px]">
                                                    DATA
                                                </th>
                                                <th className="px-3 py-2 w-[100px]">
                                                    CPF
                                                </th>
                                                <th className="px-3 py-2 w-[200px]">
                                                    NOME
                                                </th>
                                                <th className="px-3 py-2 w-[200px]">
                                                    DATA DE <br /> NASCIMENTO
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    ANO DE NASCIMENTO
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    IDADE
                                                </th>
                                                <th className="px-3 py-2 w-[250px]">
                                                    STATUS
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    SITUAÇÃO
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="mt-8">
                                    <table className="w-full table-fixed border-collapse">
                                        {/* Corpo da tabela */}
                                        <tbody className="bg-[#0E1621] text-gray-300 font-montserrat text-xs">
                                            {companyDataDownload.map(
                                                (item, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border-b border-[#2D3748]"
                                                    >
                                                        <td className="px-3 py-2 w-[50px]">
                                                            {index + 1}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px]">
                                                            {item.date}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px]">
                                                            {item.cpf}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px]">
                                                            {item.name}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px]">
                                                            {item.nascimento}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.anoNascimento}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.idade}
                                                        </td>
                                                        <td className="px-3 py-2 w-[250px]">
                                                            {item.status}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.situacao}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={prevPageDownload}
                                    disabled={pageDownload === 1}
                                    className={`px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 ${
                                        pageDownload === 1
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-[#1A202C]'
                                    }`}
                                >
                                    Anterior
                                </button>
                                <button
                                    onClick={nextPageDownload}
                                    className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400"
                                >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenMap && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenMap(false)}
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenArchive && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                                Arquivar Pasta
                            </h2>
                            <p className="text-gray-300 font-montserrat text-xl mb-4">
                                Tem certeza que deseja arquivar a pasta?
                            </p>

                            {statusMessageArchive && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat">
                                    {statusMessageArchive}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenArchive(false)}
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleArchiveClick}
                                    className={`flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold`}
                                >
                                    <FiArchive className="w-6 h-6 mr-2" />
                                    Arquivar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {loading && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                            <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                            <span className="text-base text-gray-700 font-montserrat">
                                Arquivando...
                            </span>
                        </div>
                    </div>
                )}
                {copiedText && (
                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                        {copiedText}
                    </div>
                )}

                {loadingCompanyData && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                            <FaSpinner className="w-6 h-6 text-[#00B3BE] animate-spin" />
                            <span className="text-base text-gray-700 font-montserrat">
                                Carregando...
                            </span>
                        </div>
                    </div>
                )}

                {isPopupDeleteCompany && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                                Deletar Empresa
                            </h2>
                            <p className="text-gray-300 font-montserrat text-xl mb-4">
                                Tem certeza que deseja deletar a empresa?
                            </p>

                            {statusMessageDelete && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat">
                                    {statusMessageDelete}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() =>
                                        setIsPopupDeleteCompany(false)
                                    }
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={() =>
                                        handleDeleteCompanyClick(selectedTaxId)
                                    }
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdDeleteOutline className="w-6 h-6 mr-2" />
                                    Deletar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <>
                    {isPopupMoveFolder && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#192339] bg-opacity-85">
                            <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                                <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                    Mover Empresa para Outra Pasta
                                </h2>
                                <p className="text-gray-300 font-montserrat text-sm mb-6">
                                    Selecione a pasta para onde deseja mover a
                                    empresa.
                                </p>
                                <div className="mb-4">
                                    <p className="block mb-2 text-white font-montserrat text-base font-semibold">
                                        Pasta Atual: {id}
                                    </p>
                                    <label
                                        htmlFor="folderInput"
                                        className="block mb-2 text-white font-montserrat text-lg font-bold mt-4"
                                    >
                                        Selecione a Nova Pasta
                                    </label>
                                    <select
                                        id="existingFolders"
                                        value={folderName} // Adicione este atributo para garantir que o valor selecionado seja controlado pelo estado
                                        onChange={e => {
                                            setFolderName(e.target.value);
                                            setIsFolderSelected(
                                                e.target.value !== ''
                                            );
                                        }}
                                        className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat focus:border-gray-300 focus:ring focus:ring-gray-200"
                                    >
                                        <option value="">
                                            Selecione a Nova Pasta
                                        </option>
                                        {folders
                                            .filter(folder => folder !== id)
                                            .map(folder => (
                                                <option
                                                    key={folder}
                                                    value={folder}
                                                >
                                                    {folder}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="flex justify-between mt-10">
                                    <button
                                        onClick={() =>
                                            setIsPopupMoveFolder(false)
                                        }
                                        className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                    >
                                        <MdOutlineCancel className="w-6 h-6 mr-2" />
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={handleMoveFolder}
                                        disabled={!isSaveEnabledMove}
                                        className={`flex items-center px-6 py-3 text-white ${
                                            isSaveEnabledMove
                                                ? 'bg-blue-600 hover:bg-blue-700'
                                                : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                        } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                                    >
                                        <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                        Salvar
                                    </button>
                                </div>
                                {loading && (
                                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                        <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                            <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                            <span className="text-base text-gray-700 font-montserrat">
                                                Salvando...
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
