import NavBar from '../../components/Global/NavBar';
import Header from '../../components/Global/Header';
import Footer from '../../components/Global/Footer';

import {
    BuildingOfficeIcon,
    ExclamationCircleIcon,
    ArchiveBoxIcon,
    FolderIcon,
    DocumentTextIcon,
    AdjustmentsVerticalIcon,
    MagnifyingGlassIcon,
    CheckIcon,
    PercentBadgeIcon,
} from '@heroicons/react/24/outline';

import { FaCheckCircle, FaSpinner } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import { getUserFromLocalStorage } from '../../services';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import { UserIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';

interface FolderObj {
    folder: string;
}

const CPF = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const foldersPerPage = 3;

    // Estados para dados obtidos da API
    const [fetchedFolders, setFetchedFolders] = useState<
        { id: string; createdAt: string; count: number; color: string }[]
    >([]);
    const [cnpjStats, setCnpjStats] = useState({
        totalFolders: 0,
        totalCPFs: 0,
        ativos: 0,
        percentual: 0,
    });
    const [cpfStats, setCpfStats] = useState({
        totalFolders: 0,
        totalCPFs: 0,
        ativos: 0,
        percentual: 0,
    });
    const [folders, setFolders] = useState<FolderObj[]>([]);
    const [chartSeries, setChartSeries] = useState<any[]>([]);
    const [chartOptions, setChartOptions] = useState<any>({});

    // Outros estados
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [folderName, setFolderName] = useState<string>('');
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState('');
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [statusMessageArchive, setStatusMessageArchive] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<
        'success' | 'error'
    >('success');
    const [loading, setLoading] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);

    // Estados para o popup e textarea
    const [activeTab, setActiveTab] = useState<'existingTab' | 'newTab'>(
        'existingTab'
    );
    const [searchTerm, setSearchTerm] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [errorMessage, setErrorMessage] = useState('');

    // Filtragem das pastas conforme a pesquisa
    const filteredFolders = fetchedFolders.filter(
        folder =>
            folder.id &&
            folder.id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const totalPages = Math.ceil(filteredFolders.length / foldersPerPage);
    const startIndex = (currentPage - 1) * foldersPerPage;
    const currentFolders = filteredFolders.slice(
        startIndex,
        startIndex + foldersPerPage
    );

    const handlePasteClick = () => {
        if (textareaRef.current) {
            navigator.clipboard.readText().then(pastedText => {
                setSearchTerm(pastedText);
            });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;
        const filteredText = inputText.replace(/[^a-zA-Z0-9\s]/g, '');
        if (inputText !== filteredText) {
            setErrorMessage('Caracteres especiais não são permitidos.');
        } else {
            setErrorMessage('');
        }
        setFolderName(filteredText);
        setIsFolderSelected(filteredText.trim() !== '');
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();
        setLoadingQueue(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            // Tratamento para CPF: remove caracteres não numéricos e formata para 14 dígitos
            const sanitizedData = searchTerm
                .split(/\s+/)
                .map(cpf => cpf.replace(/\D/g, ''))
                .filter(cpf => cpf);
            const formattedData = sanitizedData.map(data =>
                data.padStart(11, '0')
            );

            const endpointCPF = `${REACT_APP_SERVER_URL}/api/cpf/process-cpfs`;

            try {
                setLoading(true);
                console.log('Iniciando a validação dos CPFs...');

                const response = await axios.get(endpointCPF, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    params: {
                        cpfs: formattedData.join(','),
                        user_id: userId,
                        folder: folderName,
                    },
                });

                console.log('Response:', response.data);

                setTimeout(() => {
                    setIsPopupOpen(false);
                    navigate('/cpf');
                    window.location.reload(); // Força o refresh após a navegação
                }, 500);
            } catch (error) {
                console.error('Erro na requisição:', error);
                setProgressMessage('Ocorreu um erro. Tente novamente.');
            } finally {
                setLoadingQueue(false);
                setIsPopupOpen(false);
                navigate('/cpf');
                window.location.reload(); // Garante o refresh completo
            }
        } else {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        const user = getUserFromLocalStorage();

        if (!user || !user.userId) {
            console.log('❌ Usuário não autenticado.');
            setMessage('Usuário não autenticado.');
            setNotificationType('error');
            setShowNotification(true);
            return;
        }

        if (!selectedFiles || selectedFiles.length === 0) {
            console.log('❌ Nenhum arquivo selecionado.');
            setMessage('Nenhum arquivo selecionado.');
            setNotificationType('error');
            setShowNotification(true);
            return;
        }

        console.log('🚀 Iniciando o envio de arquivos...');
        setLoadingQueue(true);
        setShowNotification(false);

        const userId = user.userId.replace(/^"(.*)"$/, '$1');
        console.log('🔍 userId convertido:', userId);

        const bearerToken =
            'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            console.log('📂 Adicionando arquivo:', selectedFiles[i].name);
            formData.append('excelFiles', selectedFiles[i]); // O nome do campo deve ser 'excelFiles'
        }

        try {
            console.log('🌐 Enviando arquivos para o endpoint de CPF...');
            const response = await axios.post(
                `${REACT_APP_SERVER_URL}/api/cpf/excel-processing-queue-cpf?user_id=${userId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log('✅ Arquivos enviados com sucesso!', response.data);
            setTimeout(() => {
                setLoadingQueue(false);
                setIsPopupOpen(false);
                navigate('/cpf');
                window.location.reload(); // Força o refresh após a navegação
            }, 500);
        } catch (error) {
            console.error('❌ Erro durante o envio de arquivos:', error);
            setMessage('Erro durante o envio de arquivos.');
            setNotificationType('error');
            setShowNotification(true);
        } finally {
            setTimeout(() => {
                setLoadingQueue(false);
                setIsPopupOpen(false);
                navigate('/cpf');
                window.location.reload(); // Força o refresh após a navegação
            }, 500);
        }
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/cpf/folders-cpf?user_id=${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    console.log('Folders:', response.data);
                    // Supondo que response.data seja um array de objetos do tipo { folder: "Teste" }
                    setFolders(response.data);
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const userId = JSON.parse(storedUserId);
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            Promise.all([
                axios.get(
                    `${REACT_APP_SERVER_URL}/api/cpf/folders-cpf-data?user_id=${userId}`,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    }
                ),
                axios.get(
                    `${REACT_APP_SERVER_URL}/api/cpf/cpf-stats?user_id=${userId}`,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    }
                ),
                axios.get(
                    `${REACT_APP_SERVER_URL}/api/cpf/cpf-status-stats-by-date?user_id=${userId}`,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    }
                ),
            ])
                .then(([foldersDataRes, cnpjStatsRes, chartDataRes]) => {
                    setFetchedFolders(foldersDataRes.data);
                    setCnpjStats(cnpjStatsRes.data);
                    const data = chartDataRes.data;
                    const sortedData = data.sort((a: any, b: any) => {
                        const [dayA, monthA, yearA] = a.date
                            .split('/')
                            .map(Number);
                        const [dayB, monthB, yearB] = b.date
                            .split('/')
                            .map(Number);
                        const dateA = new Date(yearA, monthA - 1, dayA);
                        const dateB = new Date(yearB, monthB - 1, dayB);
                        return dateA.getTime() - dateB.getTime();
                    });
                    const categories = sortedData.map((item: any) => item.date);
                    const seriesTotalCPFs = {
                        name: 'Total de CPFs',
                        data: sortedData.map((item: any) => item.totalCPFs),
                    };
                    const seriesNewCPFs = {
                        name: 'Novos',
                        data: sortedData.map((item: any) => item.totalNewCPFs),
                    };
                    const seriesExistingCPFs = {
                        name: 'Atualizados',
                        data: sortedData.map(
                            (item: any) => item.totalExistingCPFs
                        ),
                    };
                    setChartSeries([
                        seriesTotalCPFs,
                        seriesNewCPFs,
                        seriesExistingCPFs,
                    ]);
                    setChartOptions({
                        chart: {
                            type: 'line',
                            height: 350,
                            width: '100%',
                            toolbar: { show: false },
                            background: 'transparent',
                        },
                        grid: {
                            borderColor: '#2A3B55',
                            strokeDashArray: 5,
                        },
                        xaxis: {
                            categories,
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                            axisBorder: { show: false },
                            axisTicks: { show: false },
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 2,
                        },
                        markers: {
                            size: 3,
                            strokeWidth: 1,
                            hover: { size: 5 },
                        },
                        dataLabels: { enabled: false },
                        tooltip: {
                            theme: 'dark',
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        },
                        legend: {
                            labels: {
                                colors: '#ffffff',
                                fontFamily: 'Montserrat',
                            },
                            position: 'top',
                            horizontalAlign: 'center',
                        },
                        colors: ['#00D3CF', '#FF5733', '#8E44AD'],
                    });
                })
                .catch(error => {
                    console.error('Erro ao buscar dados unificados:', error);
                });
        }
    }, []);

    function normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return (
        <div className="min-h-screen bg-[#0F172A] text-white flex flex-col">
            <NavBar />
            <main className="flex-1 pt-2 min-h-screen">
                <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 space-y-6">
                    <Header title="CPF" Icon={UserIcon} route="/cpf" />

                    {/* Primeira linha: Navegação interna + KPIs */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:-ml-10">
                        {/* Menu lateral de links */}
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl p-4 space-y-2 rounded-xl">
                            {[
                                {
                                    to: '#',
                                    label: 'Consultar',
                                    icon: (
                                        <MagnifyingGlassIcon className="w-5 h-5" />
                                    ),
                                    action: () => setIsPopupOpen(true),
                                },
                                {
                                    to: '/monitoring-cpf',
                                    label: 'Monitoramentos',
                                    icon: (
                                        <AdjustmentsVerticalIcon className="w-5 h-5" />
                                    ),
                                },
                                {
                                    to: '',
                                    label: 'Alertas',
                                    icon: (
                                        <ExclamationCircleIcon className="w-5 h-5" />
                                    ),
                                },
                                {
                                    to: '',
                                    label: 'Arquivadas',
                                    icon: (
                                        <ArchiveBoxIcon className="w-5 h-5" />
                                    ),
                                },
                            ].map(({ to, label, icon, action }) => (
                                <Link
                                    key={label}
                                    to={to}
                                    onClick={action}
                                    className="flex items-center w-full text-white hover:bg-[#2a3e5e]/50 hover:shadow-md hover:shadow-orangeNeuron/30 rounded-lg p-2 font-montserrat text-base transition-all duration-300"
                                >
                                    <div className="p-2 bg-[#2a3e5e]/50  rounded-lg">
                                        {icon}
                                    </div>
                                    <span className="ml-2">{label}</span>
                                </Link>
                            ))}
                        </div>

                        {/* Bloco de KPIs */}
                        <div className="bg-darkAlt md:col-span-3 p-6 rounded-xl border border-[#2A3B55] shadow-2xl">
                            {/* Título "KPIs" com espaçamento maior abaixo */}
                            <h2 className="text-lg font-montserrat font-semibold text-white mb-6 sm:mb-6 md:mb-8 lg:mb-10 flex items-center">
                                <AdjustmentsVerticalIcon className="w-5 h-5 mr-2 text-orangeNeuron animate-pulse" />
                                KPIs
                            </h2>

                            {/* Cards com espaçamento maior do título */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 sm:mt-6 md:mt-8 lg:mt-10">
                                {[
                                    {
                                        label: 'Pastas',
                                        value: cnpjStats.totalFolders,
                                        icon: (
                                            <FolderIcon className="w-6 h-6 text-[#FFD700]" />
                                        ),
                                    },
                                    {
                                        label: 'CPFs',
                                        value: cnpjStats.totalCPFs,
                                        icon: (
                                            <UserIcon className="w-6 h-6 text-[#00D3CF]" />
                                        ),
                                    },
                                    {
                                        label: 'Regulares',
                                        value: cnpjStats.ativos,
                                        icon: (
                                            <CheckIcon className="w-6 h-6 text-[#4CAF50]" />
                                        ),
                                    },
                                    {
                                        label: '% Regulares',
                                        value: `${cnpjStats.percentual.toFixed(
                                            2
                                        )}%`,
                                        icon: (
                                            <PercentBadgeIcon className="w-6 h-6 text-[#FFA500]" />
                                        ),
                                    },
                                ].map(({ label, value, icon }) => (
                                    <div
                                        key={label}
                                        className="py-2 px-4 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg border border-[#2A3B55]/60 hover:border-orangeNeuron/60 transition-all duration-300"
                                    >
                                        <div className="flex justify-between items-center">
                                            <p className="text-base text-white font-semibold font-montserrat">
                                                {label}
                                            </p>
                                            <div className="p-2 bg-[#ffffff1a] rounded-lg shadow-md">
                                                {icon}
                                            </div>
                                        </div>
                                        <p className="text-3xl font-bold text-white mt-2 transition-transform duration-200 hover:scale-105 font-montserrat">
                                            {typeof value === 'number'
                                                ? value.toLocaleString()
                                                : value}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Segunda linha: Conteúdo principal e sidebar */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:-ml-10">
                        {/* Div About com as Pastas */}
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] md:col-span-2 p-6 rounded-xl border border-[#2A3B55]/60 shadow-2xl">
                            {/* Header das Pastas */}
                            <div className="mb-8 flex justify-between items-center">
                                <h2 className="text-lg font-montserrat font-semibold text-white flex items-center">
                                    <FolderIcon className="w-5 h-5 mr-2 text-orangeNeuron" />
                                    Pastas
                                </h2>

                                {/* Campo de Pesquisa */}
                                <div className="relative w-80">
                                    <MagnifyingGlassIcon className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <input
                                        type="text"
                                        placeholder="Pesquisar pasta..."
                                        className="w-full pl-10 p-2 text-sm bg-[#192339] border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-orangeNeuron/60 text-white font-montserrat transition-all duration-300 hover:border-orangeNeuron/50"
                                        value={searchQuery}
                                        onChange={e =>
                                            setSearchQuery(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {/* Grid de Pastas */}
                            {/* Grid de Pastas */}
                            {currentFolders.length > 0 ? (
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
                                    {currentFolders.map(folder => {
                                        let hoverColor =
                                            'hover:border-orangeNeuron/60 hover:shadow-orangeNeuron/30';

                                        if (folder.id === 'Denegar') {
                                            hoverColor =
                                                'hover:border-orange-500 hover:shadow-orange-500/30';
                                        } else if (folder.id === 'Desativar') {
                                            hoverColor =
                                                'hover:border-red-500 hover:shadow-red-500/30';
                                        }

                                        return (
                                            <div
                                                key={folder.id}
                                                onClick={() =>
                                                    navigate(
                                                        `/cpf/folder/${normalizeString(
                                                            folder.id
                                                        )}`
                                                    )
                                                }
                                                className={`p-4 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] 
                      rounded-xl shadow-xl border border-[#2A3B55]/60 cursor-pointer 
                      transition-all duration-300 hover:scale-105 ${hoverColor}`}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <p className="text-lg font-semibold font-montserrat text-white">
                                                        {folder.id}
                                                    </p>
                                                    <FolderIcon
                                                        className="w-6 h-6"
                                                        style={{
                                                            color: folder.color,
                                                        }}
                                                    />
                                                </div>
                                                <hr className="border-gray-600 my-2" />
                                                <p className="text-xs text-gray-100 font-montserrat mb-1 mt-1">
                                                    Criado em:{' '}
                                                    {folder.createdAt}
                                                </p>
                                                <p className="text-xs text-gray-100 font-montserrat">
                                                    Total de CPFs:{' '}
                                                    {folder.count}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <p className="text-center text-gray-400 font-montserrat mt-10">
                                    Nenhuma pasta encontrada.
                                </p>
                            )}

                            {/* Paginação */}
                            <div className="flex justify-between items-center mt-12">
                                <button
                                    onClick={() =>
                                        setCurrentPage(prev =>
                                            Math.max(prev - 1, 1)
                                        )
                                    }
                                    disabled={currentPage === 1}
                                    className="bg-gray-700 p-2 rounded-lg hover:bg-gray-600 font-montserrat disabled:opacity-50 transition-all duration-200"
                                >
                                    &lt; Anterior
                                </button>
                                <p className="text-sm text-gray-400">
                                    Página {currentPage} de {totalPages}
                                </p>
                                <button
                                    onClick={() =>
                                        setCurrentPage(prev =>
                                            Math.min(prev + 1, totalPages)
                                        )
                                    }
                                    disabled={currentPage === totalPages}
                                    className="bg-gray-700 p-2 rounded-lg font-montserrat hover:bg-gray-600 disabled:opacity-50 transition-all duration-200"
                                >
                                    Próximo &gt;
                                </button>
                            </div>
                        </div>
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl flex flex-col items-center justify-center text-center rounded-xl p-4">
                            {chartSeries.length > 0 ? (
                                <ReactApexChart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="line"
                                    height={350}
                                />
                            ) : (
                                <p className="font-montserrat">
                                    Carregando gráfico...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                {/* Popup Condicional */}
                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-11/12 md:w-3/4 lg:w-1/2 min-h-[500px] p-6 rounded-lg shadow-lg bg-gradient-to-b from-[#0E1621] to-[#192339] border border-gray-700">
                            {/* Tabs Header */}
                            <div className="flex space-x-1 mb-4">
                                <button
                                    className={`flex-1 py-2 px-2 rounded-t-md text-center font-semibold font-montserrat text-sm transition-all duration-200 ${
                                        activeTab === 'existingTab'
                                            ? 'bg-[#192339] text-white shadow-md border-b-2 border-white'
                                            : 'bg-[#2d3946] text-gray-300 hover:bg-[#334155]'
                                    }`}
                                    onClick={() => setActiveTab('existingTab')}
                                >
                                    <FolderIcon className="w-4 h-4 inline-block mr-1 text-sm" />{' '}
                                    Consultar CPF
                                </button>
                                <button
                                    className={`flex-1 py-2 px-2 rounded-t-md text-center font-semibold font-montserrat text-sm transition-all duration-200 ${
                                        activeTab === 'newTab'
                                            ? 'bg-[#192339] text-white shadow-md border-b-2 border-white'
                                            : 'bg-[#2d3946] text-gray-300 hover:bg-[#334155]'
                                    }`}
                                    onClick={() => setActiveTab('newTab')}
                                >
                                    <DocumentTextIcon className="w-4 h-4 inline-block mr-1 text-sm" />{' '}
                                    Consulta em Massa
                                </button>
                            </div>

                            {/* Tabs Content */}
                            {activeTab === 'existingTab' && (
                                <div>
                                    <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                        Consulte informações de clientes usando
                                        o CPF
                                    </h2>
                                    <p className="text-gray-300 font-montserrat text-base mb-4">
                                        Cole os números de CPF no campo abaixo,
                                        separados por espaços ou quebras de
                                        linha.
                                    </p>

                                    <div className="flex items-center mb-4">
                                        <textarea
                                            ref={textareaRef}
                                            id="searchTerm"
                                            name="searchTerm"
                                            value={searchTerm}
                                            onChange={e =>
                                                setSearchTerm(e.target.value)
                                            }
                                            className="flex-1 w-full h-32 p-2 bg-[#1f2937] border border-dashed border-gray-500 rounded-lg focus:outline-none font-montserrat text-white text-sm resize-none"
                                            placeholder="Cole os CPFs aqui"
                                        ></textarea>

                                        <button
                                            onClick={handlePasteClick}
                                            className="ml-3 bg-gray-700 text-white p-2 rounded-lg hover:bg-gray-600 focus:outline-none"
                                        >
                                            <ClipboardDocumentIcon className="w-5 h-5" />
                                        </button>
                                    </div>

                                    <label className="block mb-2 text-white font-montserrat text-sm font-semibold">
                                        Escolha uma pasta usada anteriormente
                                    </label>
                                    <select
                                        className="w-full py-2 px-2 text-white bg-[#1f2937] border border-gray-600 rounded-lg focus:outline-none font-montserrat text-sm"
                                        onChange={e => {
                                            setFolderName(e.target.value);
                                            setIsFolderSelected(
                                                e.target.value !== ''
                                            );
                                        }}
                                        value={folderName}
                                    >
                                        <option value="">
                                            Selecione uma pasta existente
                                        </option>
                                        {folders.map((folderObj, index) => (
                                            <option
                                                key={index}
                                                value={folderObj.folder}
                                            >
                                                {folderObj.folder}
                                            </option>
                                        ))}
                                    </select>

                                    <div className="flex items-center mt-3">
                                        <button
                                            className="text-blue-400 hover:text-blue-500 focus:outline-none"
                                            onClick={() =>
                                                setShowNewFolderInput(
                                                    !showNewFolderInput
                                                )
                                            }
                                        >
                                            <FolderIcon className="w-5 h-5" />
                                        </button>
                                        <span className="ml-2 font-montserrat text-white text-sm">
                                            ou crie uma nova pasta
                                        </span>
                                    </div>

                                    {showNewFolderInput && (
                                        <div className="mt-3">
                                            <input
                                                type="text"
                                                className="w-full py-2 px-2 text-white bg-[#1f2937] border border-gray-500 rounded-lg focus:outline-none font-montserrat text-sm"
                                                placeholder="Digite o nome da nova pasta"
                                                value={folderName}
                                                onChange={handleInputChange}
                                            />
                                            {errorMessage && (
                                                <p className="text-red-500 italic mt-1 text-xs font-montserrat">
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div className="flex justify-between mt-6">
                                        <button
                                            onClick={() =>
                                                setIsPopupOpen(false)
                                            }
                                            className="px-5 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white text-sm font-semibold font-montserrat"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSearchClick}
                                            disabled={!isFolderSelected}
                                            className={`px-5 py-2 rounded-lg text-sm font-semibold font-montserrat ${
                                                isFolderSelected
                                                    ? 'bg-[#00B3BE] hover:bg-[#00D3CF] text-[#192339]'
                                                    : 'bg-gray-600 opacity-50 cursor-not-allowed text-white'
                                            }`}
                                        >
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'newTab' && (
                                <div>
                                    <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-2">
                                        Envie um arquivo com CPFs para análise
                                        em massa
                                    </h2>
                                    <p className="text-gray-300 font-montserrat text-lg mb-4">
                                        Envie um arquivo Excel com as
                                        informações dos CPFs para continuar.
                                    </p>

                                    <div className="flex items-center space-x-4 p-4 rounded-lg border-2 border-dashed border-gray-600 shadow-md font-montserrat">
                                        <div className="w-14 h-14 flex items-center justify-center bg-gray-700 rounded-lg">
                                            <img
                                                src="https://logospng.org/download/microsoft-excel/logo-microsoft-excel-1024.png"
                                                alt="Ícone de Excel"
                                                className="w-10 h-10"
                                            />
                                        </div>
                                        <div className="text-sm text-gray-300">
                                            <p className="mb-1 font-semibold">
                                                Exemplo de arquivo:
                                            </p>
                                            <p className="text-gray-400">
                                                Coluna: <strong>CPF</strong>
                                            </p>
                                            <p className="text-gray-400">
                                                Linhas:{' '}
                                                <span className="italic">
                                                    números de CPF
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center mb-6 p-4 bg-gray-800 rounded-lg shadow-md border-2 border-dotted border-gray-600 mt-4 font-montserrat">
                                        <label
                                            htmlFor="fileInput"
                                            className="text-gray-400 hover:text-white font-medium p-2 rounded-md cursor-pointer text-sm font-montserrat"
                                        >
                                            Clique aqui para selecionar um
                                            arquivo Excel
                                        </label>
                                        <input
                                            type="file"
                                            accept=".xls,.xlsx"
                                            multiple
                                            className="hidden"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                        />
                                        <p className="text-gray-500 text-xs mt-2 text-center">
                                            Suporte para arquivos no formato{' '}
                                            <strong>.xls</strong> ou{' '}
                                            <strong>.xlsx</strong>.
                                            Certifique-se de que o arquivo
                                            contém uma coluna chamada{' '}
                                            <strong>"CPF"</strong> e os números
                                            de CPF nas linhas abaixo.
                                        </p>
                                    </div>
                                    {statusMessageArchive && (
                                        <div className="text-center text-green-500 font-montserrat text-base font-bold mt-4">
                                            {statusMessageArchive}
                                        </div>
                                    )}

                                    <div className="flex justify-between mt-6">
                                        <button
                                            onClick={() =>
                                                setIsPopupOpen(false)
                                            }
                                            className="px-5 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white text-sm font-semibold font-montserrat"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            disabled={!selectedFiles}
                                            className={`px-5 py-2 rounded-lg text-sm font-semibold font-montserrat ${
                                                selectedFiles
                                                    ? 'bg-[#00B3BE] hover:bg-[#00D3CF] text-[#192339]'
                                                    : 'bg-gray-600 opacity-50 cursor-not-allowed text-white'
                                            }`}
                                        >
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {loadingQueue && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                                    <FaSpinner className="animate-spin w-6 h-6 text-white" />
                                    <span className="text-base text-white font-montserrat font-semibold">
                                        Enviando para Consulta na Receita
                                        Federal...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default CPF;
