import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import Breadcrumb from '../Global/Breadcrumb';

import { FaRegFolderOpen } from 'react-icons/fa';
import { MdOutlineAddAlert } from 'react-icons/md';
import { IoAdd } from 'react-icons/io5';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import { MdDeleteOutline } from 'react-icons/md';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface Schedule {
    id: number;
    alertName: string;
    date: string;
    selectedFolder: string;
    actionConfiguration: string;
    conditionDetails: string;
}

interface CompanyData {
    name: string;
    taxId: string;
    founded: string;
    nature: string;
    full_address: string;
    status: string;
    activities: string[];
    simples_optant: string;
    simei_optant: string;
    registrations_count: number;
    registrations: Registration[]; // Adicionar o campo de registros
}

interface Registration {
    state: string;
    number: string;
    enabled: string;
    statusDate: string;
    status_text: string;
    type_text: string;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

interface Schedule {
    id: number;
    createdAt: string;
    time: string;
    folder: string;
    active: string;
    repeat: string;
}

interface AlertFormData {
    alertName: string;
    conditionalType: string;
    conditionDetails: string;
    actionConfiguration: string;
    isActive: boolean;
    selectedFolder: string;
    recipientEmails: string; // Alterado para string para armazenar múltiplos emails separados por vírgula
}

export const MaAlerts = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [taxIdCount, setTaxIdCount] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(null);
    const [companyData, setCompanyData] = useState<CompanyData[]>([]);
    const [page, setPage] = useState(1);
    const [folders, setFolders] = useState<string[]>([]);
    const [isHovered, setIsHovered] = useState(false);
    const [error, setError] = useState<string | null>(null); // Ajuste de tipo
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [emails, setEmails] = useState<string[]>([]);
    const [folderName, setFolderName] = useState<string>('');
    const [scheduleToDelete, setScheduleToDelete] = useState<number | null>(
        null
    );
    const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
    const [statusMessageDelete, setStatusMessageDelete] = useState('');

    const [formData, setFormData] = useState<AlertFormData>({
        alertName: '',
        conditionalType: '',
        conditionDetails: '',
        actionConfiguration: '',
        isActive: false,
        selectedFolder: '',
        recipientEmails: '', // Inicializado como uma string vazia
    });

    const isSaveEnabled =
        formData.alertName.trim() !== '' &&
        formData.selectedFolder.trim() !== '' &&
        formData.conditionDetails.trim() !== '' &&
        formData.actionConfiguration.trim() !== '';

    const [availability, setAvailability] = useState<{
        status: string;
        data: string;
    } | null>(null);

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' }, // Adiciona a rota aqui
        { label: 'Alertas' },
    ];

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            const checked = (e.target as HTMLInputElement).checked;
            setFormData(prevFormData => ({ ...prevFormData, [name]: checked }));
        } else {
            setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const storedUserId = localStorage.getItem('userId');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            if (storedUserId) {
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/alert-data/${JSON.parse(
                            storedUserId
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    );
                    console.log('Dados recebidos:', response.data); // Adicionando este log
                    setSchedules(
                        response.data.map((item: any) => ({
                            id: item._id, // Usando o nome da pasta como ID
                            alertName: item.alertName,
                            createdAt: item.createdAt,
                            selectedFolder: item.selectedFolder,
                            actionConfiguration: item.actionConfiguration,
                            conditionDetails: item.conditionDetails,
                        }))
                    );

                    setLoading(false);
                } catch (error) {
                    console.error(
                        'Error fetching inactive folders data:',
                        error
                    );
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/folders/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )

                .then(response => {
                    console.log(response.data);
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.includes(',')) {
            const newEmails = value.split(',').map(email => email.trim());
            setEmails([...emails, ...newEmails]);
            setFormData({ ...formData, recipientEmails: '' });
        } else {
            setFormData({ ...formData, recipientEmails: value });
        }
    };

    const handleSearchClick = async (event: React.FormEvent) => {
        event.preventDefault(); // Evitar o recarregamento da página

        const user = getUserFromLocalStorage();
        if (user && user.userId) {
            const userId = JSON.parse(user.userId);
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            const endpoint = `${REACT_APP_SERVER_URL}/api/market-intelligence/alerts/${userId}`;

            // Limpa a lista de emails removendo entradas vazias ou com espaços em branco
            const cleanedEmails = emails.filter(email => email.trim() !== '');

            const requestBody = {
                alertName: formData.alertName,
                selectedFolder: formData.selectedFolder,
                conditionDetails: formData.conditionDetails,
                actionConfiguration: formData.actionConfiguration,
                recipientEmails: cleanedEmails.join(','), // Concatena os emails em uma única string
            };

            try {
                setLoading(true);
                const response = await axios.post(endpoint, requestBody, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                console.log('Request successful:', response.data);
            } catch (error) {
                console.error('Request error:', error);
            } finally {
                setLoading(false); // Parar o estado de carregamento
                setIsPopupOpen(false);
                navigate('/market-analysis/alerts'); // Redirecionar para a rota '/market-analysis'
                window.location.reload(); // Recarregar a página
            }
        }
    };

    const handleDeleteClick = async (scheduleId: number) => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/alert/${userId}/${scheduleId}`;

            try {
                await axios.delete(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageDelete('Alerta excluído com sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e recarregar a página
                setTimeout(() => {
                    setIsPopupOpenDelete(false);
                    window.location.reload(); // Recarregar a página
                }, 1000);
            } catch (error) {
                console.error('Erro na requisição:', error);
                setError('Erro ao excluir o monitoramento');
            }
        } else {
            setIsPopupOpenDelete(false);
        }
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6">
                <div className="flex ml-10 mt-1">
                    <div
                        className="w-[1100px] h-[580px] p-4 mr-6 -ml-8 overflow-y-auto flex"
                        style={{ scrollbarColor: '#6B7280 #4B5563' }}
                    >
                        <div className="container mx-auto">
                            <div className="flex justify-end mb-4">
                                <button
                                    onClick={() => {
                                        setIsPopupOpen(true);
                                    }}
                                    className="flex items-center text-white rounded-lg font-montserrat text-base font-semibold p-2 px-10 hover:from-blue-700 hover:to-blue-600 focus:outline-none relative"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    style={{
                                        transition:
                                            'background-color 0.3s ease-in-out',
                                        backgroundColor: isHovered
                                            ? '#04BFBF'
                                            : '#03989E',
                                    }}
                                >
                                    <IoAdd className="w-5 h-5 mt-0" />
                                    <span className="font-montserrat">
                                        Adicionar
                                    </span>
                                    {isHovered && (
                                        <div
                                            className="absolute w-full h-full top-0 left-0 bg-[#BDDEE0] opacity-25 rounded-full"
                                            style={{
                                                animation:
                                                    'pulse 1.5s infinite',
                                            }}
                                        ></div>
                                    )}
                                </button>
                            </div>
                            <table className="min-w-full bg-gradient-to-b from-[#0E1621] to-[#192339] shadow-md rounded">
                                <thead>
                                    <tr className="border-b-2 border-[#2a3e5e] text-base">
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Nome do Alerta
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Data
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Pasta de Origem
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Pasta de Destino
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Regra
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{
                                        maxHeight: '480px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    {loading ? (
                                        <tr>
                                            <td
                                                colSpan={6} // Alterado de 4 para 6 para corresponder ao número de colunas
                                                className="text-center py-4 font-montserrat text-white text-base"
                                            >
                                                Carregando...
                                            </td>
                                        </tr>
                                    ) : schedules.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan={6} // Alterado de 4 para 6 para corresponder ao número de colunas
                                                className="text-center py-4 font-montserrat text-white text-base"
                                            >
                                                Nenhum alerta encontrado.
                                            </td>
                                        </tr>
                                    ) : (
                                        schedules.map(schedule => (
                                            <tr
                                                key={schedule.id}
                                                className="border-b-2 border-[#2a3e5e] hover:bg-[#385179] font-montserrat text-white"
                                            >
                                                <td className="py-3 px-4 flex items-center text-base">
                                                    <MdOutlineAddAlert className="h-6 w-6 mr-2 text-[#03989E]" />
                                                    {schedule.alertName}
                                                </td>
                                                <td className="py-3 px-4 text-base">
                                                    {schedule.createdAt}
                                                </td>
                                                <td className="py-3 px-4 text-base">
                                                    {schedule.selectedFolder}
                                                </td>
                                                <td className="py-3 px-4 text-base">
                                                    {
                                                        schedule.actionConfiguration
                                                    }
                                                </td>
                                                <td className="py-3 px-4 max-w-xs truncate">
                                                    {schedule.conditionDetails}
                                                </td>

                                                <td className="py-3 px-4">
                                                    <button
                                                        onClick={() => {
                                                            setScheduleToDelete(
                                                                schedule.id
                                                            );
                                                            setIsPopupOpenDelete(
                                                                true
                                                            );
                                                        }}
                                                        className="bg-[#2a3e5e]  border border-transparent text-white font-bold py-1 px-2 rounded-lg font-montserrat transition duration-300 ease-in-out hover:bg-red-600"
                                                    >
                                                        Excluir
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <div className="flex flex-col mt-3 h-[520px]">
                        <h3 className="items-center text-2xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                            <MdOutlineAddAlert className="h-8 w-8 mr-2 text-[#27395e] mb-2" />
                            Criar Alertas de Monitoramento
                        </h3>
                        <p className="text-gray-300 text-base font-montserrat mt-0">
                            Aqui você pode configurar alertas para monitorar
                            alterações cadastrais de seus clientes.
                        </p>
                    </div>
                </div>
            </div>
            {isPopupOpenDelete && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                            Excluir Alerta
                        </h2>
                        <p className="text-gray-300 font-montserrat text-base mb-4">
                            Tem certeza que deseja excluir o alerta?
                        </p>

                        {statusMessageDelete && (
                            <div className="text-white font-semibold mb-4 text-base font-montserrat">
                                {statusMessageDelete}
                            </div>
                        )}

                        <div className="flex justify-between">
                            <button
                                onClick={() => setIsPopupOpenDelete(false)}
                                className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>
                            <button
                                onClick={() => {
                                    if (scheduleToDelete !== null) {
                                        handleDeleteClick(scheduleToDelete);
                                    }
                                }}
                                className={`flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold`}
                            >
                                <MdDeleteOutline className="w-6 h-6 mr-2" />
                                Excluir
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-[#111827] w-[1100px] p-6 rounded shadow-lg relative">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                            Adicione os Alertas
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-4">
                            Insira as informações do alerta nos campos abaixo.
                            Separe os e-mails dos destinatários por vírgulas.
                        </p>

                        <form onSubmit={handleSearchClick}>
                            <div className="mb-4">
                                <label className="block mb-2 text-white font-montserrat text-lg font-bold">
                                    Nome do Alerta
                                    <input
                                        type="text"
                                        className="w-full bg-gray-800 text-gray-300 border border-gray-600 rounded p-2 mt-2"
                                        placeholder="Digite o nome do alerta"
                                        name="alertName"
                                        value={formData.alertName}
                                        onChange={handleInputChange}
                                    />
                                </label>
                            </div>

                            <div className="mb-2">
                                <p className="text-[#57B4B9] font-montserrat text-2xl font-bold italic mb-1">
                                    Regra
                                </p>
                                <label className="block mb-2 text-white font-montserrat text-lg font-bold">
                                    Se a pasta
                                    <select
                                        id="existingFolders"
                                        className="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 bg-gray-800 leading-tight focus:outline-none focus:shadow-outline font-montserrat mt-2"
                                        onChange={handleInputChange}
                                        name="selectedFolder"
                                        value={formData.selectedFolder}
                                    >
                                        <option value="">Pastas</option>
                                        {folders.map(folder => (
                                            <option key={folder} value={folder}>
                                                {folder}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-white font-montserrat text-lg font-bold">
                                    possuir algum caso de
                                    <select
                                        className="w-full bg-gray-800 text-gray-300 border border-gray-600 rounded p-2 mt-2"
                                        name="conditionDetails"
                                        value={formData.conditionDetails}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value="CNPJ Baixada/Nula">
                                            CNPJ Baixada/Nula
                                        </option>
                                        <option value="CNPJ Suspensa/Irregular/Inapta">
                                            CNPJ Suspensa/Irregular/Inapta
                                        </option>
                                        <option value="Apenas I.Es Não Habilitadas">
                                            Apenas I.Es Não Habilitadas
                                        </option>
                                        <option value="Apenas I.Es Bloqueado/Vedado">
                                            Apenas I.Es Bloqueado/Vedado
                                        </option>
                                        {/* Adicione mais opções conforme necessário */}
                                    </select>
                                </label>
                            </div>

                            <div className="mb-2">
                                <label className="block mb-2 text-white font-montserrat text-lg font-bold">
                                    mova para a pasta
                                    <select
                                        id="existingFolders"
                                        className="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 bg-gray-800 leading-tight focus:outline-none focus:shadow-outline font-montserrat mt-2"
                                        onChange={handleInputChange}
                                        name="actionConfiguration"
                                        value={formData.actionConfiguration}
                                    >
                                        <option value="">Pastas</option>
                                        {folders.map(folder => (
                                            <option key={folder} value={folder}>
                                                {folder}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-white font-montserrat text-lg font-bold">
                                    e envie alertas para os emails:
                                </label>
                                <input
                                    type="text"
                                    value={formData.recipientEmails}
                                    onChange={handleEmailInputChange}
                                    className="w-full px-3 py-2 mb-2 rounded bg-gray-800 text-white focus:outline-none font-montserrat"
                                    name="recipientEmails"
                                />
                                <div className="flex flex-wrap">
                                    {emails.map((email, index) => (
                                        <span
                                            key={index}
                                            className="bg-gray-700 font-montserrat text-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
                                        >
                                            {email}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className="flex justify-between mt-5">
                                <button
                                    onClick={() => setIsPopupOpen(false)}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                    disabled={loading} // Desabilitar se estiver carregando
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>

                                <button
                                    type="submit"
                                    className={`flex items-center px-6 py-3 rounded-lg font-montserrat text-base font-semibold ${
                                        isSaveEnabled
                                            ? 'text-white bg-blue-600 hover:bg-blue-700'
                                            : 'text-gray-400 bg-gray-600 cursor-not-allowed'
                                    }`}
                                    disabled={!isSaveEnabled || loading} // Desabilitar se não estiver pronto para salvar ou estiver carregando
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </form>

                        {loading && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                    <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                    <span className="text-base text-gray-700 font-montserrat">
                                        Salvando...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaAlerts;
