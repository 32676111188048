import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import Login from '../components/AuthComponent/Login';
import Register from '../components/AuthComponent/Register';

import Home from '../pages/Home';
import MarketAnalysisPage from '../pages/MarketAnalysisPage';
import NewsPage from '../pages/NewsPage';
import DocumentsPage from '../pages/DocumentsPage';
import CompaniesPage from '../pages/CompaniesPage';
import MonitoringCompaniesPage from '../pages/MonitoringCompaniesPage';
import ArchiveCompaniesPage from '../pages/ArchivedPage';
import DatastorePage from '../pages/Datastore';
import IntegrationCompaniesPage from '../pages/IntegrationPage';
import HelpFeedBackCompaniesPage from '../pages/HelpFeedbackCompaniesPage';
import DocumentsFolderPage from '../pages/Documents/DocumentsFolderPage';
import AlertsCompaniesPage from '../pages/AlertsCompaniesPage';
import AnalyticsCompaniesPage from '../pages/AnalyticsCompaniesPage';
import MarketAnalysisCPFPage from '../pages/CPF/MarketAnalysisCPFPage';
import CompaniesCPFPage from '../pages/CPF/CompaniesCPFPage';
import ArchivedCPFPage from '../pages/CPF/ArchivedCPFPage';
import NotificationsPage from '../pages/NotificationsPage';
import { MaIntegration } from '../components/MarketAnalysis/ma-integration';
import { MaCPFIntegration } from '../components/MarketAnalysisCPF/ma-cpf-integration';
import IntegrationCPFPage from '../pages/CPF/IntegrationCPFPage';

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/market-analysis" element={<MarketAnalysisPage />} />
            <Route
                path="/market-analysis/folder/:id"
                element={<CompaniesPage />}
            />
            <Route
                path="/market-analysis/monitoring"
                element={<MonitoringCompaniesPage />}
            />
            <Route
                path="/market-analysis/alerts"
                element={<AlertsCompaniesPage />}
            />
            <Route
                path="/market-analysis/archived"
                element={<ArchiveCompaniesPage />}
            />
            <Route
                path="/market-analysis/datastore"
                element={<DatastorePage />}
            />
            <Route
                path="/market-analysis/analytics"
                element={<AnalyticsCompaniesPage />}
            />
            <Route
                path="/market-analysis/integration"
                element={<IntegrationCompaniesPage />}
            />
            <Route
                path="/market-analysis/help-feedback"
                element={<HelpFeedBackCompaniesPage />}
            />
            <Route
                path="/market-analysis/notifications"
                element={<NotificationsPage />}
            />

            <Route
                path="/market-analysis-cpf"
                element={<MarketAnalysisCPFPage />}
            />
            <Route
                path="/market-analysis-cpf/folder/:id"
                element={<CompaniesCPFPage />}
            />
            <Route
                path="/market-analysis-cpf/archived"
                element={<ArchivedCPFPage />}
            />
            <Route
                path="/market-analysis-cpf/integration"
                element={<IntegrationCPFPage />}
            />

            <Route path="/documents" element={<DocumentsPage />} />
            <Route
                path="/documents/folder/:id"
                element={<DocumentsFolderPage />}
            />

            <Route path="/market-analysis/news" element={<NewsPage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
    </Routes>
);

export default AppRoutes;
