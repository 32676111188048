import { LandingNavbar } from '../components/Homepage/landing-navbar';
import { MaNotifications } from '../components/MarketAnalysis/ma-notifications';

const NotificationsPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaNotifications />
            </div>
        </main>
    );
};

export default NotificationsPage;
