import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface SidebarProps {
    children: ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
    return (
        <div className='mt-3'>
            {children}
        </div>
    );
};

export default Sidebar;
