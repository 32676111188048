import React, { useState, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';
import Breadcrumb from '../Global/Breadcrumb';
import { HiServer } from 'react-icons/hi';
import axios from 'axios';
import { getUserFromLocalStorage } from '../../services';
import { REACT_APP_SERVER_URL } from '../../config/keys';

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const MaIntegration = () => {
    const { id } = useParams();
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<
        'success' | 'error'
    >('success');

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' },
        { label: 'Integração' },
    ];

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    const handleSubmit = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    setMessage('Nenhum arquivo selecionado.');
                    setNotificationType('error');
                    setShowNotification(true);
                    return;
                }

                setLoading(true);
                setShowNotification(false);

                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('excelFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/market-intelligence/bulk-upload-file-cnpj?user_id=${userId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setMessage(response.data.message);
                setNotificationType('success');
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
                setMessage('Erro durante o envio de arquivos.');
                setNotificationType('error');
            } finally {
                setLoading(false);
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                    window.location.reload(); // Recarrega a página após 3 segundos
                }, 3000); // 3000 milissegundos = 3 segundos
            }
        }
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6">
                <div className="flex ml-10 mt-1">
                    <div
                        className="w-[1100px] h-[580px] p-4 mr-6 -ml-8 overflow-y-auto flex"
                        style={{ scrollbarColor: '#6B7280 #4B5563' }}
                    >
                        <div className="container mx-auto -ml-4">
                            <div className="flex justify-between items-center text-white text-lg font-montserrat font-semibold rounded-xl shadow-xl border-2 border-dotted border-gray-600 p-4">
                                <p>Consulta de CNPJ em Massa</p>
                                <div className="flex items-center space-x-2">
                                    <label
                                        htmlFor="fileInput"
                                        className="bg-[#019482] text-white font-bold p-2 rounded-md cursor-pointer text-base font-montserrat"
                                    >
                                        Escolher arquivos
                                    </label>
                                    <input
                                        type="file"
                                        accept=".xls,.xlsx"
                                        multiple
                                        className="hidden"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                    />
                                    <button
                                        onClick={handleSubmit}
                                        className={`text-white font-bold py-1 px-3 rounded-md text-sm font-montserrat flex items-center justify-center ${
                                            selectedFiles
                                                ? 'hover:text-white text-[#019482]'
                                                : 'opacity-50 cursor-not-allowed'
                                        }`}
                                        disabled={!selectedFiles}
                                    >
                                        <FaUpload
                                            className={`w-5 h-5 ${
                                                selectedFiles
                                                    ? 'text-white'
                                                    : 'text-gray-400'
                                            }`}
                                        />
                                    </button>
                                </div>
                                {selectedFiles && selectedFiles.length > 0 && (
                                    <p className="mt-2 text-sm font-light font-montserrat text-gray-300 italic">
                                        {`${selectedFiles.length} ${
                                            selectedFiles.length === 1
                                                ? 'arquivo selecionado'
                                                : 'arquivos selecionados'
                                        }`}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className="items-center text-2xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                        <HiServer className="h-8 w-8 mr-2 text-[#27395e] mb-2" />
                        Integração
                    </h3>
                    <p className="text-gray-300 text-base font-montserrat mt-0">
                        Aqui estarão disponíveis os endpoints para integração
                        com nossos serviços.
                    </p>

                    <p className="text-gray-300 text-base font-montserrat mt-4">
                        Estamos trabalhando para proporcionar a melhor
                        experiência de integração possível.
                    </p>
                </div>
            </div>

            {/* Notificação ou Spinner */}
            {loading ? (
                <div className="fixed bottom-4 right-4 p-4 rounded-md shadow-lg text-white bg-blue-500 flex items-center justify-center">
                    <div className="w-6 h-6 border-4 border-t-transparent border-white rounded-full animate-spin mr-3"></div>
                    <span className="font-montserrat text-base font-semibold">
                        Realizando o upload dos arquivos
                    </span>
                </div>
            ) : (
                showNotification && (
                    <div
                        className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg text-white ${
                            notificationType === 'success'
                                ? 'bg-green-500'
                                : 'bg-red-500'
                        }`}
                    >
                        <p className="font-montserrat text-base font-bold">
                            {message}
                        </p>
                    </div>
                )
            )}
        </div>
    );
};
