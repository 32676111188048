import React from 'react';
import TypewriterComponent from 'typewriter-effect';

export const LandingHero = () => {
    return (
        <div className="text-[#FBFCF7] py-28 text-center space-y-5 bg-cover bg-center bg-no-repeat bg-[#111827] -mt-6 -mb-10">
            <div className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl space-y-6 -mt-4 ">
                <p className="font-montserrat font-bold">
                    Assistentes de IA para
                </p>
                <div className="text-[#111827]">
                    <div
                        className="gradient-container font-montserrat inline-block p-2 rounded font-bold mb-2"
                        style={{
                            background:
                                'linear-gradient(to right, #7ad9de, #03989E)',
                        }}
                    >
                        <TypewriterComponent
                            options={{
                                strings: ['Análise de Mercado', 'Notícias', 'Documentos', 'Mercado Financeiro'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                </div>
                <div className="text-white font-normal font-montserrat -mt-0 mb-1 text-base">
                    Potencialize sua produtividade com assistentes inteligentes para o back-office.
                </div>
            </div>
        </div>
    );
};
