import { LandingNavbar } from '../../components/Homepage/landing-navbar';
import { MaCPFCompanies } from '../../components/MarketAnalysisCPF/ma-cpf-companies';

const CompaniesCPFPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaCPFCompanies />
            </div>
        </main>
    );
};

export default CompaniesCPFPage;
