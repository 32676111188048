import React from 'react';
import { BsGrid3X3Gap, BsPlus } from 'react-icons/bs';
import Breadcrumb from '../Global/Breadcrumb';
import { Link } from 'react-router-dom';
import Sidebar from '../Global/Sidebar';

import { LuLayoutDashboard } from 'react-icons/lu';
import { LuMonitorCheck } from 'react-icons/lu';
import { IoAlertCircleOutline, IoAnalytics } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';

export const NewsHero = () => {
    const breadcrumbItems = [
        { to: '/', icon: BsGrid3X3Gap, label: 'Página Inicial' },
        { label: 'Notícias' },
    ];

    const handleDownload = () => {
        // Lógica para download dos dados no formato Excel
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="flex justify-between">
                <div>
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="-mt-6">
                    <button className="flex items-center text-white bg-[#00B3BE] hover:bg-[#57B4B9] rounded-lg px-6 py-2 font-montserrat text-sm font-semibold">
                        <BsPlus className="w-5 h-5 mr-0" />
                        Adicionar
                    </button>
                </div>
            </div>
            <div className="w-64 mt-4 p-4 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                <Sidebar>
                    <Link
                        to="/documents"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm -mt-2"
                    >
                        <LuLayoutDashboard className="w-5 h-5 mr-2" />
                        Dashboard
                    </Link>
                    <Link
                        to="/analytics"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm"
                    >
                        <LuMonitorCheck className="w-5 h-5 mr-2" />
                        Monitoramento
                    </Link>
                    <Link
                        to="/settings"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm"
                    >
                        <IoAnalytics className="w-5 h-5 mr-2" />
                        Analytics
                    </Link>
                    <Link
                        to="/users"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                    >
                        <IoAlertCircleOutline className="w-5 h-5 mr-2" />
                        Alertas
                    </Link>
                    <Link
                        to="/users"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                    >
                        <MdOutlineFileDownload className="w-5 h-5 mr-2" />
                        Download
                    </Link>
                    <Link
                        to="/users"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm"
                    >
                        <FiTrash2 className="w-5 h-5 mr-2" />
                        Lixeira
                    </Link>
                </Sidebar>
            </div>
        </div>
    );
};
