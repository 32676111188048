import { MaCPFIntegration } from '../../components/MarketAnalysisCPF/ma-cpf-integration';
import { LandingNavbar } from '../../components/Homepage/landing-navbar';

const IntegrationCPFPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaCPFIntegration />
            </div>
        </main>
    );
};

export default IntegrationCPFPage;
