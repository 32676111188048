import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import Breadcrumb from '../Global/Breadcrumb';

import { FaRegFolderOpen } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface Schedule {
    id: number;
    date: string;
    time: string;
    folder: string;
}

interface CompanyData {
    name: string;
    taxId: string;
    founded: string;
    nature: string;
    full_address: string;
    status: string;
    activities: string[];
    simples_optant: string;
    simei_optant: string;
    registrations_count: number;
    registrations: Registration[]; // Adicionar o campo de registros
}

interface Registration {
    state: string;
    number: string;
    enabled: string;
    statusDate: string;
    status_text: string;
    type_text: string;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const MaCPFArchived = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loadingArchived, setLoadingArchived] = useState(false);
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [scheduleToDelete, setScheduleToDelete] = useState<number | null>(
        null
    );
    const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
    const [statusMessageDelete, setStatusMessageDelete] = useState('');
    const [error, setError] = useState<string | null>(null); // Ajuste de tipo

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const storedUserId = localStorage.getItem('userId');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            if (storedUserId) {
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/inactive-cpf-folders-data/${JSON.parse(
                            storedUserId
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    );
                    console.log('Dados recebidos:', response.data); // Adicionando este log
                    setSchedules(
                        response.data.map((item: any) => ({
                            id: item._id, // Usando o nome da pasta como ID
                            folder: item.folder,
                            date: item.date,
                            time: item.time,
                        }))
                    );

                    setLoading(false);
                } catch (error) {
                    console.error(
                        'Error fetching inactive folders data:',
                        error
                    );
                }
            }
        };

        fetchData();
    }, [id]);

    const activateFolder = async (folderName: string) => {
        const storedUserId = localStorage.getItem('userId');
        setLoadingArchived(true);

        if (storedUserId === null) {
            console.error('UserId não encontrado em localStorage');
            return;
        }

        const bearerToken =
            'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

        try {
            await axios.put(
                `${REACT_APP_SERVER_URL}/api/market-intelligence/activate-folder-cpf/${JSON.parse(
                    storedUserId
                )}/${folderName}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            );
            console.log('Folder ativada com sucesso');
            setLoadingArchived(false);
            window.location.reload(); // Recarrega a página
        } catch (error) {
            console.error('Error activating folder:', error);
            // Trate o erro de acordo com sua lógica
        }
    };

    const deletedFolder = async (folderName: string) => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId === null) {
            console.error('UserId não encontrado em localStorage');
            setLoadingArchived(false); // Certifique-se de desativar o carregamento em caso de erro
            return;
        }

        const bearerToken =
            'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
        const userId = JSON.parse(storedUserId);

        try {
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/market-intelligence/delete-folder-cpf/${userId}/${folderName}`,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            );
            console.log('Folder deletada com sucesso');
            window.location.reload(); // Recarrega a página
        } catch (error) {
            console.error('Error deleting folder:', error);
            // Trate o erro de acordo com sua lógica
        } finally {
        }
    };

    const handleDeleteClick = async (scheduleId: number) => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/schedule/${userId}/${scheduleId}`;

            try {
                await axios.delete(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageDelete('Monitoramento excluído com sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e recarregar a página
                setTimeout(() => {
                    setIsPopupOpenDelete(false);
                    window.location.reload(); // Recarregar a página
                }, 1000);
            } catch (error) {
                console.error('Erro na requisição:', error);
                setError('Erro ao excluir o monitoramento');
            }
        } else {
            setIsPopupOpenDelete(false);
        }
    };

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis-cpf', label: 'Análise de Mercado - CPF' },
        { label: 'Arquivadas' },
    ];

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6 ">
                <div className="flex ml-10 mt-1">
                    <div
                        className="w-[1100px] h-[580px] p-4 mr-6 -ml-8 overflow-y-auto flex"
                        style={{ scrollbarColor: '#6B7280 #4B5563' }}
                    >
                        <div className="container mx-auto">
                            <table className="min-w-full bg-gradient-to-b from-[#0E1621] to-[#192339] shadow-md rounded">
                                <thead>
                                    <tr className="border-b-2 border-[#2a3e5e] text-base">
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Pasta
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Data
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Hora
                                        </th>
                                        <th className="text-left py-3 px-4 font-montserrat text-white">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{
                                        maxHeight: '480px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    {loading ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                className="text-center py-4 font-montserrat text-white text-base"
                                            >
                                                Carregando...
                                            </td>
                                        </tr>
                                    ) : schedules.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                className="text-center py-4 font-montserrat text-white text-base"
                                            >
                                                Nenhuma pasta arquivada
                                                encontrada.
                                            </td>
                                        </tr>
                                    ) : (
                                        schedules.map(schedule => (
                                            <tr
                                                key={schedule.id}
                                                className="border-b-2 border-[#2a3e5e] hover:bg-[#385179] font-montserrat text-white"
                                            >
                                                <td className="py-3 px-4 flex items-center text-base">
                                                    <FaRegFolderOpen className="h-6 w-6 mr-2 text-[#03989E]" />
                                                    {schedule.folder}
                                                </td>
                                                <td className="py-3 px-4 text-base">
                                                    {schedule.date}
                                                </td>
                                                <td className="py-3 px-4 text-base">
                                                    {schedule.time}
                                                </td>
                                                <td className="py-3 px-4">
                                                    <button
                                                        onClick={() =>
                                                            activateFolder(
                                                                schedule.folder
                                                            )
                                                        }
                                                        className="bg-[#2a3e5e] hover:bg-[#00B3BE] border border-transparent hover:border-white rounded-lg text-white font-bold py-1 px-2 mr-2 font-montserrat"
                                                    >
                                                        Desarquivar
                                                    </button>

                                                    <button
                                                        onClick={() =>
                                                            deletedFolder(
                                                                schedule.folder
                                                            )
                                                        }
                                                        className="bg-[#2a3e5e]  border border-transparent text-white font-bold py-1 px-2 rounded-lg font-montserrat transition duration-300 ease-in-out hover:bg-red-600"
                                                    >
                                                        Excluir Definitivamente
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {loadingArchived && (
                        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                            <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                <span className="text-base text-gray-700 font-montserrat">
                                    Desarquivando...
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className=" items-center text-2xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                        <FiTrash2 className="h-8 w-8 mr-2 text-[#27395e] mb-2" />
                        Pastas Arquivadas
                    </h3>
                    <p className="text-gray-300 text-base font-montserrat mt-0">
                        Aqui você pode encontrar todas as pastas arquivadas.
                    </p>

                    <p className="text-gray-300 text-base font-montserrat mt-4">
                        Você pode visualizar, deletar permanentemente ou
                        desarquivar suas pastas.
                    </p>
                </div>
            </div>
            {isPopupOpenDelete && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                            Excluir Pasta Arquivada
                        </h2>
                        <p className="text-gray-300 font-montserrat text-base mb-4">
                            Tem certeza que deseja excluir a pasta arquivada?
                        </p>

                        {statusMessageDelete && (
                            <div className="text-white font-semibold mb-4 text-base font-montserrat">
                                {statusMessageDelete}
                            </div>
                        )}

                        <div className="flex justify-between">
                            <button
                                onClick={() => setIsPopupOpenDelete(false)}
                                className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>
                            <button
                                onClick={() => {
                                    if (scheduleToDelete !== null) {
                                        handleDeleteClick(scheduleToDelete);
                                    }
                                }}
                                className={`flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold`}
                            >
                                <MdDeleteOutline className="w-6 h-6 mr-2" />
                                Excluir
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
