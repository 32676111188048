import { LandingNavbar } from '../../components/Homepage/landing-navbar';
import { MaCPFArchived } from '../../components/MarketAnalysisCPF/ma-cpf-archived';

const ArchivedCPFPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaCPFArchived />
            </div>
        </main>
    );
};

export default ArchivedCPFPage;
