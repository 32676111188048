import { LandingContent } from '../components/Homepage/landing-content';
import Footer from '../components/Homepage/landing-footer';
import { LandingHero } from '../components/Homepage/landing-hero';
import { LandingNavbar } from '../components/Homepage/landing-navbar';

const Home = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <LandingHero />
                <LandingContent />
                <Footer />
            </div>
        </main>
    );
};

export default Home;
