import React from 'react';
import { Link } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    return (
        <nav className="text-[#FBFCF7] text-base font-montserrat -mt-5 mb-2" aria-label="Breadcrumb">
            <ol className="list-none p-0 inline-flex">
                {items.map((item, index) => (
                    <li key={index} className="flex items-center font-montserrat">
                        {item.to ? (
                            <Link to={item.to} className="hover:text-[#64cfd3] flex items-center">
                                {item.icon && <item.icon className="h-5 w-auto mr-1 -mt-2 " />}
                                {item.label}
                            </Link>
                        ) : (
                            <span>{item.label}</span>
                        )}
                        {index < items.length - 1 && (
                            <RiArrowRightSLine className="h-5 w-auto text-gray-500 mx-1" />
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
