import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full h-[1px] bg-gradient-to-r from-[#57B4B9]/50 via-transparent to-[#57B4B9]/50 hover:h-[3px] transition-all duration-300">
    </footer>
  );
};

export default Footer;
