import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import Sidebar from '../Global/Sidebar';
import Breadcrumb from '../Global/Breadcrumb';

import { LuLayoutDashboard } from 'react-icons/lu';
import { LuMonitorCheck } from 'react-icons/lu';
import { IoAlertCircleOutline, IoAnalytics } from 'react-icons/io5';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineFileDownload } from 'react-icons/md';

import { FaRegCheckCircle } from 'react-icons/fa';
import { IoAdd } from 'react-icons/io5';
import { FaRegFolder } from 'react-icons/fa6';
import { FiActivity } from 'react-icons/fi';
import { FiClock } from 'react-icons/fi';
import { HiServer } from 'react-icons/hi';
import { BsQuestionCircle } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import { GoPlusCircle } from 'react-icons/go';
import { FaRegFolderOpen } from 'react-icons/fa';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { CgTemplate } from "react-icons/cg";

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface InvoiceResponse {
    _id: string;
    count: string;
    createdAt: string;
}

interface AlertItem {
    id: number;
    message: string;
    type: 'success' | 'warning' | 'error'; // Tipos de alerta
}

export const DocumentsHero = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [folders, setFolders] = useState<string[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [folderName, setFolderName] = useState<string>('');
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false); // Novo estado para controlar se o botão "Salvar" está habilitado
    const breadcrumbItems = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { label: 'Documentos' },
        { label: 'Dashboard' },
    ];
    const [isHovered, setIsHovered] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [results, setResults] = useState([]);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const companiesPerPage = 4;
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<string>('');

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);

    const [copiedText, setCopiedText] = useState('');

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
    const [currentAlert, setCurrentAlert] = useState<AlertItem | null>(null);
    const [availability, setAvailability] = useState<{
        status: string;
        data: string;
    } | null>(null);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [updatedUserCredits, setUpdatedUserCredits] = useState(0);
    const [executionTime, setExecutionTime] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [successfulFiles, setSuccessfulFiles] = useState(0);
    const [errorFilesNames, setErrorFilesNames] = useState([]);
    const [analysisCompleted, setAnalysisCompleted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Filtra as empresas com base no texto de pesquisa
        const filterCompanies = () => {
            if (!searchFilter.trim()) {
                // Se o filtro de pesquisa estiver vazio, retorne todas as empresas
                return allCompanies;
            }
            return allCompanies.filter(company => {
                return company._id
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase());
            });
        };

        const filtered = filterCompanies();
        setFilteredCompanies(filtered);
    }, [searchFilter, allCompanies]); // Depende do searchFilter e allCompanies para reagir a mudanças

    useEffect(() => {
        // Dados dos alertas
        const alertData: AlertItem[] = [
            {
                id: 1,
                message: 'Pasta Grandes Empresas possui 10 cnpjs alterados!',
                type: 'success',
            },
            {
                id: 2,
                message: 'Pasta Médias Empresas possui 1000 cnpjs alterados!',
                type: 'error',
            },
            {
                id: 3,
                message: 'Pasta Pequenas Empresas possui 50 cnpjs alterados!',
                type: 'warning',
            },
            // Adicione mais alertas conforme necessário
        ];

        // Atualiza o alerta atual a cada 5 segundos
        const interval = setInterval(() => {
            setCurrentAlertIndex(prevIndex =>
                prevIndex === alertData.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        setCurrentAlert(alertData[currentAlertIndex]);

        return () => clearInterval(interval);
    }, [currentAlertIndex]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                '25774656c450400ffe631b6446b490f0af34349e92a8ee7b66246eeedb22b63b';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/documents/folders/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )

                .then(response => {
                    console.log(response.data);
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const bearerToken =
            '25774656c450400ffe631b6446b490f0af34349e92a8ee7b66246eeedb22b63b';
        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/documents/folders-data/${JSON.parse(
                    storedUserId!
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        setIsSaveEnabled(folderName.trim() !== '');
    }, [folderName]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUserId = localStorage.getItem('userId');
                if (storedUserId) {
                    const bearerToken =
                        '25774656c450400ffe631b6446b490f0af34349e92a8ee7b66246eeedb22b63b';
                    const response = await axios.post(
                        `${REACT_APP_SERVER_URL}/api/documents/availability?author_id=${JSON.parse(
                            storedUserId
                        )}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    );

                    const { status, data } = response.data;
                    setAvailability({ status, data });
                }
            } catch (error) {
                console.error('Error fetching availability:', error);
                // Handle error if needed
            }
        };

        fetchData();
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };
    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = async () => {
        if (!selectedFiles) {
            console.error('Nenhum arquivo selecionado.');
            return;
        }

        setLoading(true); // Ativa a barra de loading

        const start = new Date().getTime();
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            try {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const category = folderName; // aqui estamos usando o nome da pasta como "category"

                const endpoint = `${REACT_APP_SERVER_URL}/api/documents/create?author_id=${userId}&category=${category}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer 25774656c450400ffe631b6446b490f0af34349e92a8ee7b66246eeedb22b63b`,
                    },
                });

                const {
                    totalFiles,
                    successfulFiles,
                    errorFiles,
                    errorFilesNames,
                    numberOfPages,
                    totalNumberOfPages,
                    updatedUserCredits,
                    results,
                } = response.data;

                console.log('Número total de arquivos:', totalFiles);
                console.log(
                    'Número de arquivos processados com sucesso:',
                    successfulFiles
                );
                console.log('Número de arquivos que falharam:', errorFiles);
                console.log(
                    'Nomes dos arquivos que falharam:',
                    errorFilesNames
                );
                console.log('Número total de páginas:', numberOfPages);
                console.log(
                    'Número total de páginas em todos os arquivos:',
                    totalNumberOfPages
                );
                console.log(
                    'Créditos atualizados do usuário:',
                    updatedUserCredits
                );
                console.log('Resultados do processamento:', results);

                setNumberOfPages(numberOfPages);
                setTotalNumberOfPages(totalNumberOfPages);
                setUpdatedUserCredits(updatedUserCredits);
                setResults(results);
                setTotalFiles(totalFiles);
                setSuccessfulFiles(successfulFiles);
                setErrorFilesNames(errorFilesNames);

                setAnalysisCompleted(true);
                setIsPopupOpen(false);
                window.location.reload();
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
            } finally {
                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
                setLoading(false); // Desativa a barra de loading
            }
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
                <div className="flex mt-2">
                    <div className="w-64 h-full p-4 -ml-4 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <Sidebar>
                            <Link
                                to="/market-analysis"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm -mt-2"
                            >
                                <LuLayoutDashboard className="w-5 h-5 mr-2" />
                                Dashboard
                            </Link>
                            <Link
                                to="/market-analysis/monitoring"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm"
                            >
                                <CgTemplate className="w-5 h-5 mr-2" />
                                Templates
                            </Link>
                            <Link
                                to="/market-analysis/datastore"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                            >
                                <MdOutlineFileDownload className="w-5 h-5 mr-2" />
                                Downloads
                            </Link>
                            <Link
                                to="/market-analysis/archived"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                            >
                                <FiTrash2 className="w-5 h-5 mr-2" />
                                Arquivadas
                            </Link>
                        </Sidebar>
                    </div>
                    <div className="w-[1100px] h-72 p-4 ml-5 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <div className="ml-[900px] -mt-20">
                            <button
                                onClick={() => {
                                    setIsPopupOpen(true);
                                    setSelectedFiles(null); // Limpa a lista de arquivos selecionados
                                    setFolderName(''); // Limpa o nome da pasta
                                    setShowNewFolderInput(false); // Reseta a opção de criar nova pasta
                                }}
                                className="flex items-center text-white rounded-lg font-montserrat text-base font-semibold p-2 px-10 hover:from-blue-700 hover:to-blue-600 focus:outline-none relative"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{
                                    transition:
                                        'background-color 0.3s ease-in-out',
                                    backgroundColor: isHovered
                                        ? '#04BFBF'
                                        : '#03989E',
                                }}
                            >
                                <IoAdd className="w-5 h-5 mt-0" />
                                <span className="font-montserrat">
                                    Adicionar
                                </span>
                                {isHovered && (
                                    <div
                                        className="absolute w-full h-full top-0 left-0 bg-[#BDDEE0] opacity-25 rounded-full"
                                        style={{
                                            animation: 'pulse 1.5s infinite',
                                        }}
                                    ></div>
                                )}
                            </button>
                        </div>
                        <div className="mt-10 flex items-center justify-between">
                            <div className="flex items-center mb-2">
                                {currentFilteredCompanies.length >= 1 && (
                                    <>
                                        <div className="flex items-center text-white rounded-lg p-2">
                                            <FaRegFolder className="w-4 h-4 mr-2" />
                                            <span className="font-montserrat text-base">
                                                Recentes
                                            </span>
                                        </div>
                                        <div className="text-white">
                                            <input
                                                type="text"
                                                id="search"
                                                value={searchFilter}
                                                onChange={e =>
                                                    setSearchFilter(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Pesquisar pelo nome da pasta..."
                                                className="w-[400px] ml-[480px] h-[30px] px-4 py-2 text-xs rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] font-montserrat text-white"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {currentFilteredCompanies.length >= 1 && (
                                <div className="-mt-1 -ml-2 flex items-center text-gray-400 text-xs font-montserrat">
                                    <span className="mx-2 italic">
                                        Página {currentPage}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            {currentFilteredCompanies.length >= 1 && (
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={`${
                                        currentPage === 1
                                            ? 'cursor-not-allowed'
                                            : 'hover:text-gray-300'
                                    }`}
                                >
                                    <FaAngleDoubleLeft className="w-6 h-6 mr-2 text-white opacity-30" />
                                </button>
                            )}
                            <div
                                className="container mx-auto p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full"
                                id="companies"
                            >
                                {currentFilteredCompanies.length === 0 ? (
                                    <div className="-mt-8 flex items-center justify-between">
                                        <div className="flex items-center text-white rounded-lg p-2">
                                            <FaRegFolder className="w-4 h-4 mr-2" />
                                            <span className="font-montserrat text-base">
                                                Recentes
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    currentFilteredCompanies
                                        .filter(
                                            company =>
                                                selectedFolder === '' ||
                                                company._id === selectedFolder
                                        )
                                        .map(company => (
                                            <div
                                                key={company._id}
                                                className="bg-[#192339] text-white rounded-lg p-4 cursor-pointer h-40 transition-transform hover:scale-105"
                                                onClick={() =>
                                                    navigate(
                                                        `/documents/folder/${company._id}`
                                                    )
                                                }
                                            >
                                                <div className="flex justify-center items-center mt-3">
                                                    <FaRegFolderOpen className="text-[#03989E] w-12 h-12" />
                                                    <p
                                                        className="text-base font-bold font-montserrat ml-4 mt-2"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company._id ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company._id || 'N/A'}
                                                    </p>
                                                </div>
                                                <hr className="border-[#57B4B9] border-opacity-25 my-3" />
                                                <div className="mt-1">
                                                    <p
                                                        className="font-normal text-sm font-montserrat truncate mt-0 text-gray-400"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.count?.toString() ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.count !==
                                                        undefined
                                                            ? `${
                                                                  company.count
                                                              } ${
                                                                  parseInt(
                                                                      company.count
                                                                  ) === 1
                                                                      ? 'Documento'
                                                                      : 'Documentos'
                                                              }`
                                                            : 'N/A'}
                                                    </p>
                                                    <p
                                                        className="text-[#B3D8D9] text-sm font-montserrat font-light"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.createdAt ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.createdAt ||
                                                            'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                )}
                                {copiedText && (
                                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                        {copiedText}
                                    </div>
                                )}
                            </div>
                            {currentFilteredCompanies.length >= 1 && (
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="font-montserrat text-white text-sm opacity-30"
                                >
                                    <FaAngleDoubleRight className="w-6 h-6 ml-2" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="w-60 -mt-14 p-4 -ml-4 rounded-xl shadow-xl border-2 border-dotted border-gray-600 flex items-center cursor-pointer transition-colors duration-300 ease-in-out">
                    <div className="max-w-xs p-0 rounded-xl shadow-xl ">
                        <div className="flex items-center">
                            <h2 className="text-gray-300 font-semibold text-base font-montserrat">
                                Status da IA
                            </h2>
                        </div>
                        <div className="mt-1">
                            {availability ? (
                                <>
                                    <p className="">
                                        <span
                                            className={`inline-flex items-center w-52 -ml-1 ${
                                                availability.status === 'Online'
                                                    ? 'bg-green-500'
                                                    : 'bg-red-500'
                                            } text-[#111827] px-2 py-1 rounded-lg font-montserrat font-bold `}
                                        >
                                            {availability.status ===
                                            'Online' ? (
                                                <FaCheckCircle className="mr-1" />
                                            ) : (
                                                <FaTimesCircle className="mr-1" />
                                            )}
                                            {availability.status}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <p className="text-gray-400 font-montserrat">
                                    Carregando...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-0 w-60 p-4 h-32 -ml-4 ">
                    <Link
                        to="/market-analysis/integration"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                    >
                        <HiServer className="w-5 h-5 mr-2" />
                        Integração
                    </Link>
                    <Link
                        to="/market-analysis/help-feedback"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm"
                    >
                        <BsQuestionCircle className="w-5 h-5 mr-2" />
                        Help e Feedback
                    </Link>
                </div>
            </div>
            <div className="w-[1100px] h-[270px] p-4 ml-[268px] -mt-40 rounded-xl shadow-xl bg-gradient-to-b from-[#192339] to-[#0E1621]">
                <div className="flex items-center mb-2">
                    <div className="flex items-center text-white rounded-lg p-2 -mt-2">
                        <FiActivity className="w-4 h-4 mr-2" />
                        <span className="font-montserrat text-base">KPIs</span>
                    </div>
                </div>
                {/* Grid de KPIs */}
                <div className="container max-w-6xl px-5 mx-auto my-0 mt-1 mb-2">
                    <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-base text-gray-400 font-montserrat">
                                Total Sales
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-2xl font-bold text-white font-montserrat">
                                    $9850.90
                                </div>
                                <span className="flex items-center px-2 py-0.5 mx-2 text-sm text-green-600 bg-green-100 rounded-full">
                                    <svg
                                        className="w-4 h-4"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18 15L12 9L6 15"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <span>1.8%</span>
                                </span>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-base text-gray-400 font-montserrat">
                                Total Sales
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-2xl font-bold text-white font-montserrat">
                                    $9850.90
                                </div>
                                <span className="flex items-center px-2 py-0.5 mx-2 text-sm text-green-600 bg-green-100 rounded-full">
                                    <svg
                                        className="w-4 h-4"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18 15L12 9L6 15"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <span>1.8%</span>
                                </span>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-base text-gray-400 font-montserrat">
                                Total Sales
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-2xl font-bold text-white font-montserrat">
                                    $9850.90
                                </div>
                                <span className="flex items-center px-2 py-0.5 mx-2 text-sm text-green-600 bg-green-100 rounded-full">
                                    <svg
                                        className="w-4 h-4"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18 15L12 9L6 15"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <span>1.8%</span>
                                </span>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-base text-gray-400 font-montserrat">
                                Total Sales
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-2xl font-bold text-white font-montserrat">
                                    $9850.90
                                </div>
                                <span className="flex items-center px-2 py-0.5 mx-2 text-sm text-green-600 bg-green-100 rounded-full">
                                    <svg
                                        className="w-4 h-4"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18 15L12 9L6 15"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <span>1.8%</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center mt-6">
                    {/* Div com as alertas e o currentAlert */}
                    <div className="flex items-center text-white rounded-lg p-1 mr-1">
                        <span className="flex items-center px-2 py-0.5 mx-1 text-sm text-white rounded-full">
                            <IoAlertCircleOutline className="w-4 h-4 mr-2" />

                            <span className="font-montserrat text-base">
                                Alertas
                            </span>
                        </span>
                    </div>
                    <div>
                        <div
                            className={`py-3.5 px-20 z-10 rounded shadow-md ${
                                currentAlert
                                    ? currentAlert.type === 'success'
                                        ? 'bg-[#192339]'
                                        : currentAlert.type === 'warning'
                                        ? 'bg-[#192339]'
                                        : 'bg-[#192339]'
                                    : 'hidden'
                            }`}
                            style={{ width: '480px' }} // Defina a largura fixa desejada aqui
                        >
                            {currentAlert && (
                                <div className="flex justify-between -ml-16">
                                    <p className="text-white font-montserrat text-sm font-medium">
                                        {currentAlert.message}
                                        <p className="text-gray-500 font-montserrat text-xs italic">
                                            {new Date().toLocaleString()}
                                        </p>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="ml-6 flex items-center space-x-4">
                        <div className="flex items-center text-white rounded-lg  -ml-4 mr-2">
                            <span className="flex items-center px-2 py-1 mx-1 text-sm text-white rounded-full -mr-4">
                                <LuMonitorCheck className="w-4 h-4 mr-2" />

                                <span className="font-montserrat text-base">
                                    Monitor
                                </span>
                            </span>
                        </div>
                        {/* Lista de próximos crons */}
                        <div className="flex space-x-1">
                            {/* Cron 1 */}
                            <div className="flex items-center text-white p-2 bg-[#192339] rounded shadow-md mr-2">
                                <div className="w-6 h-6 rounded-full flex items-center justify-center mr-2 ml-2">
                                    <FiClock className="w-4 h-4 text-white" />
                                </div>
                                <div>
                                    <p className="font-montserrat text-xs">
                                        Nome da Pasta
                                    </p>
                                    <p className="font-montserrat text-xs text-gray-500 italic">
                                        Agendado para: 15/05/2024 10:00
                                    </p>
                                </div>
                            </div>
                            {/* Cron 2 */}
                            <div className="flex items-center text-white  p-1 bg-[#192339] rounded shadow-md">
                                <div className="w-6 h-6 rounded-full flex items-center justify-center mr-2 ml-2">
                                    <FiClock className="w-4 h-4 text-white" />
                                </div>
                                <div>
                                    <p className="font-montserrat text-xs">
                                        Nome da Pasta
                                    </p>
                                    <p className="font-montserrat text-xs text-gray-500 italic">
                                        Agendado para: 15/05/2024 10:00
                                    </p>
                                </div>
                            </div>
                            {/* Adicione mais crons conforme necessário */}
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-6">
                            Adicionar Documentos
                        </h2>
                        <div className="mb-6">
                            <label
                                htmlFor="fileInput"
                                className="block w-full p-4 text-center border-2 border-dashed border-gray-400 rounded-lg cursor-pointer hover:border-[#00B3BE] transition font-montserrat"
                            >
                                <div className="text-white">
                                    Clique aqui para selecionar arquivos
                                </div>
                            </label>
                            <input
                                type="file"
                                accept=".pdf"
                                multiple
                                className="hidden"
                                id="fileInput"
                                onChange={handleFileChange}
                            />
                            {selectedFiles && selectedFiles.length > 0 && (
                                <p className="mt-2 text-center text-gray-300 italic font-montserrat text-base">
                                    {`${selectedFiles.length} ${
                                        selectedFiles.length === 1
                                            ? 'arquivo selecionado'
                                            : 'arquivos selecionados'
                                    }`}
                                </p>
                            )}
                        </div>
                        <div className="">
                            <label
                                htmlFor="folderInput"
                                className="block mb-2 text-white font-montserrat text-lg font-bold"
                            >
                                Selecione uma pasta existente
                            </label>
                            <select
                                id="existingFolders"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat focus:border-gray-300 focus:ring focus:ring-gray-200"
                                onChange={e => {
                                    setFolderName(e.target.value);
                                    setIsFolderSelected(e.target.value !== '');
                                }}
                            >
                                <option value="">
                                    Selecione uma pasta existente
                                </option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>

                            <div className="flex items-center">
                                <button
                                    className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                    onClick={() =>
                                        setShowNewFolderInput(
                                            !showNewFolderInput
                                        )
                                    }
                                >
                                    <GoPlusCircle className="w-6 h-6 text-[#03989E]" />
                                </button>
                                <span className="ml-2 font-montserrat text-white text-sm font-medium">
                                    ou crie uma nova pasta
                                </span>
                            </div>

                            {showNewFolderInput && (
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        id="folderInput"
                                        className="w-full p-3 border border-gray-400 rounded-lg focus:outline-none focus:border-gray-300 focus:ring focus:ring-gray-200 font-montserrat"
                                        placeholder="Digite aqui o nome da pasta"
                                        value={folderName}
                                        onChange={e => {
                                            setFolderName(e.target.value);
                                            setIsFolderSelected(
                                                e.target.value !== ''
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between mt-10">
                            <button
                                onClick={() => setIsPopupOpen(false)}
                                className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>

                            <button
                                onClick={handleSearchClick}
                                disabled={!isSaveEnabled}
                                className={`flex items-center px-6 py-3 text-white ${
                                    isSaveEnabled
                                        ? 'bg-blue-600 hover:bg-blue-700'
                                        : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                            >
                                <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                Salvar
                            </button>
                        </div>
                        {loading && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-8 w-8 text-gray-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.003 8.003 0 014.5 4.5M20 12h-4a8 8 0 00-7.938 7.5 8.003 8.003 0 008.063-2.291L20 12zm-2-6.291A8.003 8.003 0 0119.5 19.5M4.5 4.5l1.086 1.086"
                                        ></path>
                                    </svg>
                                    <span className="text-base text-gray-700 font-montserrat">
                                        Salvando...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
