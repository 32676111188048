import React, { FormEvent, useState, ChangeEvent } from 'react';
import { setUserInLocalStorageWithResponseData } from '../../services';
import { createNewUser } from '../../api/users/authentication';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import Navbar from './Navbar';

const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [sector, setSector] = useState('');
    const [position, setPosition] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [profileImageURL, setProfileImageURL] = useState('');

    const onProfileUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProfileImageURL(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            name.trim() === '' ||
            email.trim() === '' ||
            password.trim() === '' ||
            confirmPassword.trim() === '' ||
            cnpj.trim() === '' ||
            sector.trim() === '' ||
            position.trim() === '' ||
            profileImageURL.trim() === '' ||
            !termsChecked
        ) {
            setError('Por favor, preencha todos os campos e aceite os termos!');
        } else if (!isValidEmail(email)) {
            setError('Por favor, insira um endereço de email válido!');
        } else if (!isCnpjValid(cnpj)) {
            setError('Formato de CNPJ inválido');
        } else if (sector.trim() === '') {
            setError('Por favor, insira um setor válido');
        } else if (position.trim() === '') {
            setError('Por favor, insira um cargo válido');
        } else if (!arePasswordsEqual(password, confirmPassword)) {
            setError('As senhas não coincidem');
        } else {
            try {
                const response = await createNewUser(
                    {
                        name,
                        email,
                        password,
                        confirmPassword,
                        cnpj,
                        profileImageURL,
                    },
                    termsChecked
                );

                if (response) {
                    setUserInLocalStorageWithResponseData(response.data);
                    setShowSuccessPopup(true);
                }
            } catch (err) {
                if (err instanceof AxiosError && err.response) {
                    setError(err.response.data.error);
                }

                if (err instanceof Error) {
                    setError(err.message);
                }
            }
        }
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const arePasswordsEqual = (password1: string, password2: string) => {
        return password1 === password2;
    };

    const isCnpjValid = (cnpj: string) => {
        const CNPJ_REGEX = /^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{14})$/;
        return CNPJ_REGEX.test(cnpj);
    };

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const enteredEmail = e.target.value;

        setEmail(enteredEmail);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (enteredEmail.trim() === '') {
            setError(null);
        } else if (!emailRegex.test(enteredEmail)) {
            setError('Formato de e-mail inválido');
        } else {
            setError(null);
        }
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const onPassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword2 = e.target.value;
        setConfirmPassword(newPassword2);

        if (!arePasswordsEqual(password, newPassword2)) {
            setError('As senhas não coincidem');
        } else {
            setError(null);
        }
    };

    const onCnpjChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newCnpj = e.target.value;
        setCnpj(newCnpj);

        if (!isCnpjValid(newCnpj)) {
            setError('Formato de CNPJ inválido');
        } else {
            setError(null);
        }
    };

    const onSectorChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSector = e.target.value;
        setSector(newSector);

        if (newSector.trim() === '') {
            setError('Por favor, insira um setor válido');
        } else {
            setError(null);
        }
    };

    const onPositionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newJobTitle = e.target.value;
        setPosition(newJobTitle);

        if (newJobTitle.trim() === '') {
            setError('Por favor, insira um cargo válido');
        } else {
            setError(null);
        }
    };

    const handleTermsChange = () => {
        setTermsChecked(!termsChecked);
    };

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        navigate('/login');
    };

    return (
        <div>
            {showSuccessPopup && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                        &#8203;
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <svg
                                        className="h-6 w-6 text-green-600"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path d="M5 13l4 4L19 7"></path>
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-xl leading-6 font-medium text-gray-900 font-montserrat">
                                        Cadastro realizado com sucesso!
                                    </h3>
                                    <div className="mt-2">
                                        <p className="text-md text-gray-500 font-montserrat">
                                            Logo você receberá um email com as
                                            instruções para acessar sua conta.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button
                                    onClick={handleClosePopup}
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#003366] text-base font-medium text-white hover:bg-[#003455] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm font-montserrat"
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Navbar
                textoLink="Já tem conta?"
                textoBotao="Faça login!"
                linkCadastro="/login"
            />
            <div className="min-h-screen flex items-center justify-center bg-[#111827]">
                <div className="w-full max-w-4xl p-8 bg-[#192339] rounded-sm shadow-2xl mx-auto mt-8 mb-10">
                    <h1 className="text-3xl font-normal mb-1 text-white font-montserrat text-center">
                        Comece a usar sua conta da{' '}
                        <span className="font-bold text-[#03989E]">
                            Neuron Lab
                        </span>{' '}
                        hoje mesmo.
                    </h1>

                    <h2 className="text-xl font-light mb-6 text-white font-montserrat text-center">
                        Conte-nos mais sobre o seu negócio
                    </h2>
                    <form
                        onSubmit={onFormSubmit}
                        className="grid grid-cols-1 gap-4"
                    >
                        {error && (
                            <p className="error-message bg-red-500 text-white font-semibold py-2 px-4 rounded-md font-montserrat">
                                {error}
                            </p>
                        )}
                        <h3 className="text-base font-semibold mb-0 text-gray-400 font-montserrat">
                            Informações Pessoais
                        </h3>
                        <input
                            type="text"
                            placeholder="Nome Completo"
                            onChange={onNameChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                        />

                        <input
                            type="email"
                            placeholder="Email"
                            onChange={onEmailChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                        />
                        <h3 className="text-base font-semibold mb-0 text-gray-400 font-montserrat">
                            Informações da Empresa
                        </h3>
                        <input
                            type="text"
                            placeholder="CNPJ da Empresa"
                            onChange={onCnpjChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                        />
                        <input
                            type="text"
                            placeholder="Setor da Empresa"
                            onChange={onSectorChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                            autoComplete="off"
                        />
                        <input
                            type="text"
                            placeholder="URL do Perfil"
                            onChange={onProfileUrlChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                        />

                        <input
                            type="text"
                            placeholder="Cargo que Ocupa"
                            onChange={onPositionChange}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg"
                            autoComplete="off"
                        />
                        <h3 className="text-base font-semibold mb-0 text-gray-400 font-montserrat">
                            Informações de Acesso
                        </h3>
                        <div className="flex items-center">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Senha"
                                onChange={onPasswordChange}
                                className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg flex-1"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="text-white hover:text-gray-200 ml-4"
                            >
                                {showPassword ? (
                                    <FaEyeSlash size={20} />
                                ) : (
                                    <FaEye />
                                )}
                            </button>
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirmar Senha"
                            onChange={onPassword2Change}
                            className="input-field text-white bg-[#192339] border px-2 py-2 border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent font-montserrat text-lg flex-1"
                        />
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={termsChecked}
                                onChange={handleTermsChange}
                                className="mr-2 mt-2"
                            />
                            <label
                                htmlFor="terms"
                                className="text-white text-sm font-montserrat"
                            >
                                <span>
                                    Ao marcar esta caixa, você concorda com os{' '}
                                </span>
                                <Link
                                    to="/termos-de-servico"
                                    className="underline"
                                >
                                    Termos de Serviço da Neuron Lab
                                </Link>{' '}
                                <span>
                                    e confirma que leu os termos. Você também
                                    concorda em receber e-mails da Neuron Lab
                                    sobre atualizações de produtos e serviços,
                                    ofertas especiais, eventos e webinars, e
                                    outros materiais de marketing. Você pode
                                    cancelar a inscrição a qualquer momento.
                                </span>
                            </label>
                        </div>
                        <div className="flex items-center justify-end mt-4">
                            <input
                                type="submit"
                                value="Cadastrar"
                                className="bg-[#03989E] hover:bg-[#03989E]/90 text-white text-xl font-normal py-3 px-12 rounded-md shadow-lg focus:outline-none focus:ring-2 focus:ring-[#03989E]/90 focus:ring-opacity-50 font-montserrat"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
