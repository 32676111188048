import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import * as XLSX from 'xlsx';

import axios from 'axios';

import { MdOutlineFileDownload } from 'react-icons/md';
import { LiaBirthdayCakeSolid } from 'react-icons/lia';
import { RiArrowRightSLine, RiFolderSharedLine, RiPassValidLine } from 'react-icons/ri';
import { LuSheet } from 'react-icons/lu';
import { BiCalendar } from 'react-icons/bi';
import { MdCheck } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineAssessment } from 'react-icons/md';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { IoBusinessOutline } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import { IoAnalytics } from 'react-icons/io5';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { MdErrorOutline } from 'react-icons/md';
import { MdOutlineExpandLess } from 'react-icons/md';
import { MdOutlineExpandMore } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

import NavBar from '../../components/Global/NavBar';
import Footer from '../../components/Global/Footer';

interface Registration {
    cnpj: string;
    razao: string;
    fantasia: string;
    dataSociedade: string;
    qualificacao: string;
    situacao: string;
    isCustomer: boolean; // Adicionando isCustomer na interface
}

interface CompanyData {
    nome: string;
    folder: string;
    cpf: string;
    nascimento: string;
    situacao: string;
    situacaoMotivo: string;
    registrations_count: number;
    idade: string;
    maior_de_18: string;
    registrations: Registration[];
    sap_customer_id: string;
    monitoring: string;
    visiting: string;
    territoryZone: string;
}

interface CompanyDataDownload {
    date: string;
    folder: string;
    cpf: string;
    name: string;
    nascimento: string;
    anoNascimento: string;
    idade: string;
    status: string;
    situacao: string;
    possuiEmpresa: string;
    cnpjEmpresa: string;
    eCliente: string;
    empresaAtiva: string;
    sapCustomerId: string;
    territoryZone: string;
    freq: string;
    dvv: string;
}

export const CPFFolder = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isPopupOpenDownload, setIsPopupOpenDownload] = useState(false);
    const [isPopupOpenView, setIsPopupOpenView] = useState(false);
    const [isPopupOpenMap, setIsPopupOpenMap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [copiedText, setCopiedText] = useState('');
    const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
    const [statusMessageArchive, setStatusMessageArchive] = useState('');
    const [companyData, setCompanyData] = useState<CompanyData[]>([]);
    const [companyDataDownload, setCompanyDataDownload] = useState<
        CompanyDataDownload[]
    >([]);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [excelFileAvailable, setExcelFileAvailable] = useState<
        boolean | null
    >(null);
    const [loadingExcelCheck, setLoadingExcelCheck] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage] = useState(5);
    const [pageDownload, setPageDownload] = useState(1);
    const [perPageDownload] = useState(1000);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [menuOpen, setMenuOpen] = useState(
        Array(companyData.length).fill(false)
    );
    const [isSaveEnabledMove, setIsSaveEnabledMove] = useState(false);
    const [taxIdCount, setTaxIdCount] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(null);
    const [monitoringCount, setMonitoringCount] = useState(null);

    const nextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const nextPageDownload = () => {
        setPageDownload(prevPage => prevPage + 1);
    };

    const prevPageDownload = () => {
        setPageDownload(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const getStatusStyle = (status: string) => {
        // Normaliza: primeira letra maiúscula e o restante minúsculo
        const normalizedStatus =
            status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

        switch (normalizedStatus) {
            case 'Nula':
                return { backgroundColor: '#D1D5DB', color: '#000000' };
            case 'Regular':
                return { backgroundColor: '#10B981', color: '#FFFFFF' };
            case 'Pendente':
                return { backgroundColor: '#FBBF24', color: '#000000' };
            case 'Inapta':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            case 'Ativa não regular': // Atenção: se "Ativa Não Regular" for o valor esperado, ajuste a capitalização
                return { backgroundColor: '#3B82F6', color: '#FFFFFF' };
            case 'Cancelado':
                return { backgroundColor: '#8B5CF6', color: '#FFFFFF' };
            default:
                return {}; // Padrão para outros casos
        }
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleDownloadExcel = async () => {
        console.log('Download state:', companyDataDownload);

        if (companyDataDownload.length === 0) {
            console.log('Nenhum dado para exportar.');
            return;
        }

        const rows = companyDataDownload.map((company, index) => {
            return {
                Nº: index + 1,
                DATA: company.date || 'N/A',
                PASTA: company.folder || 'N/A',
                CPF: company.cpf || 'N/A',
                NOME: company.name || 'N/A',
                'DATA DE NASCIMENTO': company.nascimento || 'N/A',
                'ANO DE NASCIMENTO': company.anoNascimento || 'N/A',
                IDADE: company.idade || 'N/A',
                STATUS: company.status || 'N/A',
                'SITUACAO CADASTRAL': company.situacao || 'N/A',
                'POSSUI EMPRESA': company.possuiEmpresa || 'N/A',
                'CNPJ EMPRESA': company.cnpjEmpresa || 'N/A',
                'BASE DE CLIENTES': company.eCliente || 'N/A',
                'EMPRESA ATIVA': company.empresaAtiva || 'N/A',
                'SAP CUSTOMER ID': company.sapCustomerId || 'N/A',
                'SALES TERRITORY': company.territoryZone || 'N/A',
                FREQ: company.freq || 'N/A',
                DVV: company.dvv || 'N/A',
            };
        });

        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Informações');

        // Obtém a data atual no formato YYYY-MM-DD
        const today = new Date().toISOString().split('T')[0];
        const fileName = `CPF-${today}.xlsx`;

        XLSX.writeFile(workbook, fileName, { compression: true });
    };

    const handleDownloadClick = async () => {
        const user = getUserFromLocalStorage();
        setLoadingDownload(true);
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/cpf/download-cpf-all-data/${userId}/${folder}`;
            try {
                await axios.get(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageArchive(
                    'Download iniciado com sucesso. Você receberá um e-mail quando o processamento for finalizado.'
                );
                setLoadingDownload(false);
                setTimeout(() => {
                    setIsPopupOpenDownload(false);
                    navigate('/cnpj');
                }, 3000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setIsPopupOpenDownload(false);
            navigate('/cnpj');
        }
    };

    useEffect(() => {
        if (isPopupOpenDownload && id) {
            const user = getUserFromLocalStorage();
            if (user && user.userId) {
                const userId = user.userId.replace(/^"(.*)"$/, '$1');
                const bearerToken =
                    'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
                const endpointDownload = `${REACT_APP_SERVER_URL}/api/cpf/download-excel-file-cpf/${userId}/${id}`;

                setLoadingExcelCheck(true);

                axios
                    .head(endpointDownload, {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    })
                    .then(() => {
                        setExcelFileAvailable(true);
                        setLoadingExcelCheck(false);
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 404) {
                            setExcelFileAvailable(false);
                        } else {
                            console.error(
                                'Erro ao verificar a existência do Excel:',
                                err
                            );
                            setExcelFileAvailable(false);
                        }
                        setLoadingExcelCheck(false);
                    });
            }
        }
    }, [isPopupOpenDownload, id]);

    const handleDownloadExcelFileClick = async () => {
        const user = getUserFromLocalStorage();
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const endpointDownload = `${REACT_APP_SERVER_URL}/api/cpf/download-excel-file-cpf/${userId}/${folder}`;

            try {
                const response = await axios.get(endpointDownload, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    responseType: 'arraybuffer',
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;

                const disposition = response.headers['content-disposition'];
                let fileName = `Base de Clientes CPF - ${folder}.xlsx`;
                if (disposition && disposition.indexOf('filename=') !== -1) {
                    const filenameRegex =
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error(
                    'Erro ao fazer download do arquivo Excel:',
                    error
                );
            }
        } else {
            console.error('Usuário não autenticado para realizar o download');
        }
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const userId = JSON.parse(storedUserId);
            axios
                .all([
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cpf/cpf-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cpf/cpf-active-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cpf/cpf-monitoring-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                ])
                .then(
                    axios.spread(
                        (
                            countResponse,
                            activeCountResponse,
                            monitoringCountResponse
                        ) => {
                            setTaxIdCount(countResponse.data.cpf_count);
                            setActiveStatusCount(
                                activeCountResponse.data.activeStatusCount
                            );
                            setMonitoringCount(
                                monitoringCountResponse.data.monitoringCount
                            );
                        }
                    )
                )
                .catch(error => {
                    console.error('Error fetching counts:', error);
                });
        } else {
            console.log('No storedUserId found');
        }
    }, [id]);

    useEffect(() => {
        setIsSaveEnabledMove(isFolderSelected);
    }, [isFolderSelected]);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');

            if (storedUserId) {
                const folder = id; // Obtém o parâmetro folder da URL

                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/cpf/personal-data/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&page=${page}&perPage=${perPage}`
                    );
                    if (page === 1) {
                        // Se for a primeira página, substitui os dados anteriores
                        setCompanyData(response.data);
                    } else {
                        // Caso contrário, acumula os dados novos aos anteriores
                        setCompanyData(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações da empresa.');
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };

        fetchData();
    }, [id, page, perPage]);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');

            if (storedUserId) {
                const folder = id; // Obtém o parâmetro folder da URL

                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/cpf/download-personal-data/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&pageDownload=${pageDownload}&perPageDownload=${perPageDownload}`
                    );
                    if (pageDownload === 1) {
                        // Se for a primeira página, substitui os dados anteriores
                        setCompanyDataDownload(response.data);
                    } else {
                        // Caso contrário, acumula os dados novos aos anteriores
                        setCompanyDataDownload(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações da empresa.');
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };

        fetchData();
    }, [id, pageDownload, perPageDownload]);

    return (
        <div className="min-h-screen bg-[#0F172A] text-white flex flex-col">
            <NavBar />
            <div className="flex items-center justify-start mt-4 mb-0 ml-6">
                <div className="flex items-center bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg font-montserrat text-white px-6 py-3 transition duration-300 font-semibold">
                    {/* Texto ou ícone para CNPJ */}
                    <span className="font-montserrat text-lg font-semibold">
                        CPF
                    </span>
                    {/* Ícone de seta indicando a hierarquia */}
                    <RiArrowRightSLine className="w-5 h-5 mx-3" />
                    {/* Ícone e nome da pasta */}
                    <RiFolderSharedLine className="w-5 h-5 mr-2 text-orangeNeuron" />
                    <span className="font-montserrat text-lg font-semibold">
                        {id}
                    </span>
                </div>
            </div>
            <div className="text-white ml-2 mt-6 px-4">
                <input
                    type="text"
                    id="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Pesquisar pelo CPF..."
                    className="w-full md:max-w-[1070px] h-[40px] px-4 py-2 text-base rounded-xl shadow-2xl bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 font-montserrat text-white"
                />
            </div>
            <div className="flex flex-col md:flex-row ml-6 px-4">
                <div
                    className="w-full md:w-[1100px] h-[590px] p-4 mr-0 md:mr-6 md:-ml-8 rounded-xl overflow-y-auto"
                    style={{ scrollbarColor: '#6B7280 #4B5563' }}
                >
                    {companyData
                        .filter(company => company.cpf.includes(searchTerm))
                        .map((company, index) => (
                            <div
                                key={index}
                                className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl rounded-xl p-4 mb-4 mt-4 flex flex-col md:flex-row"
                            >
                                <div className="flex-1 mr-8">
                                    <div className="flex items-center">
                                        <div>
                                            <p
                                                className="text-sm font-medium font-montserrat overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap mt-3"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.nome || 'N/A'
                                                    )
                                                }
                                            >
                                                <h2 className="text-white text-xl font-bold font-montserrat">
                                                    {truncateText(
                                                        company.nome,
                                                        34
                                                    )}
                                                </h2>
                                            </p>

                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                        </div>
                                        <p
                                            className="text-base font-montserrat rounded-lg px-2 py-1 text-center font-bold ml-3 mt-3"
                                            style={getStatusStyle(
                                                company.situacao
                                            )}
                                            onClick={() =>
                                                handleCopyClick(
                                                    company.situacao || 'N/A'
                                                )
                                            }
                                        >
                                            {company.situacao}
                                        </p>
                                    </div>
                                    <p
                                        className="text-gray-200 flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                `CPF: ${company.cpf || 'N/A'}`
                                            )
                                        }
                                    >
                                        CPF: {company.cpf || 'N/A'}
                                    </p>
                                    <p
                                        className="text-white flex items-center text-base font-montserrat mt-4"
                                        onClick={() =>
                                            handleCopyClick(
                                                `Fundada em: ${
                                                    company.nascimento || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        <BiCalendar className="mr-1 w-5 h-5" />{' '}
                                        Data de Nascimento:{' '}
                                        {company.nascimento || 'N/A'}
                                    </p>

                                    <p
                                        className="text-white flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.idade || 'N/A'
                                            )
                                        }
                                    >
                                        <LiaBirthdayCakeSolid className="mr-1 w-5 h-5" />
                                        {company.idade} anos
                                    </p>

                                    <p
                                        className="text-white flex items-center text-base mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.maior_de_18 || 'N/A'
                                            )
                                        }
                                    >
                                        <RiPassValidLine className="mr-1 w-5 h-5" />
                                        {company.maior_de_18}
                                    </p>
                                </div>
                                <div className="flex-1 ml-4 mt-4">
                                    <div className="w-[500px]">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 -mt-4 -ml-10">
                                            <MdOutlineMapsHomeWork className="mr-2 w-7 h-7" />{' '}
                                            Empresas Vinculadas -{' '}
                                            {company.registrations_count}
                                        </h3>

                                        {company.registrations_count === 0 ? (
                                            <div className="flex justify-center items-center w-full h-32">
                                                <MdErrorOutline className="w-16 h-16 text-[#212e4b]" />
                                            </div>
                                        ) : (
                                            <div className="flex overflow-x-auto -ml-10">
                                                {company.registrations.map(
                                                    (registration, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`rounded-lg p-4 m-2 flex-shrink-0 font-montserrat text-black mb-4 ${
                                                                registration.isCustomer
                                                                    ? 'bg-yellow-400'
                                                                    : 'bg-red-400'
                                                            }`}
                                                        >
                                                            <p
                                                                className="text-black font-montserrat  font-medium rounded-md inline-block mb-2"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Status: ${
                                                                            registration.isCustomer
                                                                                ? 'CNPJ - Está na base de clientes'
                                                                                : 'CNPJ - Não está na base de clientes'
                                                                        }`
                                                                    )
                                                                }
                                                            >
                                                                <span className="font-semibold">
                                                                    {registration.isCustomer
                                                                        ? 'CNPJ está na base de clientes'
                                                                        : 'CNPJ não está na base de clientes'}
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `CNPJ: ${registration.cnpj}`
                                                                    )
                                                                }
                                                            >
                                                                CNPJ:{' '}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.cnpj
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Razão Social: ${registration.razao.slice(
                                                                            0,
                                                                            20
                                                                        )}${
                                                                            registration
                                                                                .razao
                                                                                .length >
                                                                            20
                                                                                ? '...'
                                                                                : ''
                                                                        }`
                                                                    )
                                                                }
                                                            >
                                                                Razão Social:{' '}
                                                                <span className="font-semibold">
                                                                    {registration.razao.slice(
                                                                        0,
                                                                        25
                                                                    )}
                                                                    {registration
                                                                        .razao
                                                                        .length >
                                                                    20
                                                                        ? '...'
                                                                        : ''}
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black mt-1 font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Data da Sociedade: ${registration.dataSociedade}`
                                                                    )
                                                                }
                                                            >
                                                                Data da
                                                                Sociedade:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.dataSociedade
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black mt-1"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Situação: ${registration.situacao}`
                                                                    )
                                                                }
                                                            >
                                                                Situação:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.situacao
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {loading && (
                        <p className="font-monteserrat text-white">
                            Carregando...
                        </p>
                    )}
                    {error && <p>{error}</p>}
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={prevPage}
                            disabled={page === 1 || loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandLess className="w-6 h-6" />
                        </button>
                        <button
                            onClick={nextPage}
                            disabled={loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandMore className="w-6 h-6" />
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-6 md:mt-[-10] w-full md:w-auto">
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2">
                        <RiRobot2Line className="mr-2 w-7 h-7" /> Escolha uma
                        ação
                    </h3>
                    <button
                        onClick={() => {
                            setIsPopupOpenView(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-darkAlt hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-full md:w-56 mt-1 border border-transparent"
                    >
                        <LuSheet className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Planilha
                        </span>
                    </button>
                    <button
                        onClick={() => {
                            setIsPopupOpenDownload(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-darkAlt hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-full md:w-56 mt-3 border border-transparent"
                    >
                        <MdOutlineFileDownload className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Download
                        </span>
                    </button>
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-10">
                        <IoAnalytics className="mr-2 w-7 h-7" /> Indicadores
                    </h3>
                    <div className="flex mt-2 w-full">
                        <div className="flex mt-2 w-full">
                            {taxIdCount !== null && (
                                <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full md:w-56">
                                    {taxIdCount} CPFs
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex mt-4 w-full md:w-56">
                        {activeStatusCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full">
                                {activeStatusCount} Regulares
                            </div>
                        )}
                    </div>
                    <div className="flex mt-4 w-full md:w-56">
                        {monitoringCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full">
                                {monitoringCount} Monitorados
                            </div>
                        )}
                    </div>
                </div>
                {isPopupOpenView && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-[1300px] h-auto md:h-[700px] p-8 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] overflow-auto">
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsPopupOpenView(false)}
                                    className="text-gray-300 hover:text-gray-400 focus:outline-none absolute top-4 right-4"
                                >
                                    <IoMdCloseCircleOutline className="h-6 w-6" />
                                </button>
                            </div>
                            <h2 className="text-[#57B4B9] font-montserrat text-3xl font-semibold mb-0 -mt-2">
                                Baixar Dados da Pasta
                            </h2>
                            <p className="text-gray-300 font-montserrat text-base mb-5">
                                Baixe os dados detalhados em formato Excel.
                            </p>
                            <button
                                onClick={handleDownloadExcel}
                                className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md font-montserrat hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400 absolute top-16 right-8 mb-4"
                            >
                                Download Excel
                            </button>
                            <div className="overflow-x-auto h-[500px] mt-12">
                                <div className="sticky top-0">
                                    <table className="w-full table-fixed border-collapse">
                                        {/* Cabeçalho da tabela */}
                                        <thead className="bg-[#0E1621] text-white font-normal font-montserrat text-xs">
                                            <tr className="border-b border-[#57B4B9]">
                                                <th className="px-3 py-2 w-[50px]">
                                                    Nº
                                                </th>
                                                <th className="px-3 py-2 w-[100px]">
                                                    DATA
                                                </th>
                                                <th className="px-3 py-2 w-[100px]">
                                                    PASTA
                                                </th>
                                                <th className="px-3 py-2 w-[100px]">
                                                    CPF
                                                </th>
                                                <th className="px-3 py-2 w-[200px]">
                                                    NOME
                                                </th>
                                                <th className="px-3 py-2 w-[200px]">
                                                    DATA DE <br /> NASCIMENTO
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    ANO DE NASCIMENTO
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    IDADE
                                                </th>
                                                <th className="px-3 py-2 w-[250px]">
                                                    STATUS
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    SITUACAO CADASTRAL
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    POSSUI EMPRESA
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    CNPJ EMPRESA
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    BASE DE CLIENTES
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    EMPRESA ATIVA
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    SAP CUSTOMER ID
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    SALES TERRITORY
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    FREQ
                                                </th>
                                                <th className="px-3 py-2 w-[150px]">
                                                    DVV
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="mt-8">
                                    <table className="w-full table-fixed border-collapse">
                                        {/* Corpo da tabela */}
                                        <tbody className="bg-[#0E1621] text-gray-300 font-montserrat text-xs text-center">
                                            {companyDataDownload.map(
                                                (item, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border-b border-[#2D3748]"
                                                    >
                                                        <td className="px-3 py-2 w-[50px]">
                                                            {index + 1}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px]">
                                                            {item.date}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px]">
                                                            {item.folder}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px]">
                                                            {item.cpf}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px]">
                                                            {item.name}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px]">
                                                            {item.nascimento}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.anoNascimento}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.idade}
                                                        </td>
                                                        <td className="px-3 py-2 w-[250px]">
                                                            {item.status}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.situacao}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.possuiEmpresa}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.cnpjEmpresa}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.eCliente}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.empresaAtiva}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.sapCustomerId}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.territoryZone}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.freq}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px]">
                                                            {item.dvv}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={prevPageDownload}
                                    disabled={pageDownload === 1}
                                    className={`px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 ${
                                        pageDownload === 1
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-[#1A202C]'
                                    }`}
                                >
                                    Anterior
                                </button>
                                <button
                                    onClick={nextPageDownload}
                                    className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400"
                                >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isPopupOpenMap && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenMap(false)}
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isPopupOpenDownload && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-lg p-8 rounded-2xl shadow-2xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="mb-6 border-b border-gray-800 pb-4">
                                <h2 className="text-2xl text-[#57B4B9] font-montserrat font-bold text-center">
                                    Download em Massa da Base de Clientes
                                </h2>
                                <p className="mt-2 text-center text-gray-300 font-montserrat text-sm">
                                    Se o arquivo Excel já foi gerado, você pode
                                    baixá-lo abaixo.
                                </p>
                            </div>
                            <div className="flex items-center space-x-4 p-4 rounded-lg border-2 border-dotted border-gray-800 shadow-2xl mb-6">
                                <div className="flex items-center justify-center w-16 h-16 bg-gray-700 rounded-lg">
                                    <img
                                        src="https://logospng.org/download/microsoft-excel/logo-microsoft-excel-1024.png"
                                        alt="Ícone de Excel"
                                        className="w-10 h-10"
                                    />
                                </div>
                                <div className="flex-1">
                                    {loadingExcelCheck ? (
                                        <div className="text-sm text-gray-300 font-montserrat">
                                            <FaSpinner className="animate-spin inline-block w-5 h-5 mr-2" />
                                            Verificando disponibilidade...
                                        </div>
                                    ) : excelFileAvailable ? (
                                        <button
                                            onClick={async () => {
                                                setLoadingDownloadExcel(true);
                                                await handleDownloadExcelFileClick();
                                                setLoadingDownloadExcel(false);
                                            }}
                                            disabled={loadingDownloadExcel}
                                            className="w-full flex items-center justify-center text-white rounded-xl font-montserrat text-sm font-semibold p-2 bg-[#223750] shadow-xl border-2 border-gray-700 hover:border-[#00B3BE] transition duration-300 ease-in-out hover:bg-[#2f4a68]"
                                        >
                                            {loadingDownloadExcel ? (
                                                <FaSpinner className="animate-spin inline-block w-6 h-6 mr-2" />
                                            ) : (
                                                <FiDownload className="w-6 h-6 mr-2" />
                                            )}
                                            {loadingDownloadExcel
                                                ? 'Carregando...'
                                                : 'Baixar Excel'}
                                        </button>
                                    ) : (
                                        <div className="text-sm text-red-500 font-montserrat">
                                            Nenhum arquivo disponível.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-6 border-b border-gray-800 pb-4">
                                <h2 className="text-2xl text-[#57B4B9] font-montserrat font-bold text-center">
                                    Solicitar Download em Massa
                                </h2>
                                <p className="mt-2 text-center text-gray-300 font-montserrat text-sm">
                                    A versão exibida acima é a última que você
                                    solicitou.
                                    <br />
                                    Para obter os dados mais atualizados, clique
                                    em Download para gerar um novo arquivo.
                                </p>
                                {statusMessageArchive && (
                                    <div className="text-center text-green-500 font-montserrat text-base font-bold mt-4">
                                        {statusMessageArchive}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <button
                                    onClick={() =>
                                        setIsPopupOpenDownload(false)
                                    }
                                    className="flex-1 mr-2 flex items-center justify-center px-6 py-3 text-white bg-red-600 hover:bg-red-500 rounded-lg font-montserrat text-base font-semibold transition duration-300 ease-in-out"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleDownloadClick}
                                    className="flex-1 ml-2 flex items-center justify-center px-6 py-3 text-[#192339] bg-[#00B3BE] hover:bg-[#00D3CF] rounded-lg font-montserrat text-base font-semibold transition duration-300 ease-in-out"
                                >
                                    <FiDownload className="w-6 h-6 mr-2" />
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {loadingDownload && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                            <FaSpinner className="animate-spin w-6 h-6 text-white" />
                            <span className="text-base text-white font-montserrat font-semibold">
                                Enviando para Download...
                            </span>
                        </div>
                    </div>
                )}
                {copiedText && (
                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                        {copiedText}
                    </div>
                )}
                {loadingCompanyData && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                            <FaSpinner className="animate-spin w-6 h-6 text-white" />
                            <span className="text-base text-white font-montserrat font-semibold">
                                Carregando...
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};
