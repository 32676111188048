import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';

import * as XLSX from 'xlsx';

import axios from 'axios';

import { MdOutlineFileDownload } from 'react-icons/md';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { LuSheet } from 'react-icons/lu';
import { BiBuildingHouse, BiCalendar, BiWorld } from 'react-icons/bi';
import { MdCheck } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineAssessment } from 'react-icons/md';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { IoBusinessOutline } from 'react-icons/io5';
import { RiArrowRightSLine, RiRobot2Line } from 'react-icons/ri';
import { IoAnalytics } from 'react-icons/io5';
import { FiArchive, FiDownload } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { MdOutlineExpandLess } from 'react-icons/md';
import { MdOutlineExpandMore } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { RiFolderSharedLine } from 'react-icons/ri';
import { MdDeleteOutline } from 'react-icons/md';
import { IoMdTime } from 'react-icons/io';
import { FaCar } from 'react-icons/fa';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

import { stateImages } from './state-imgs'; // Importe o objeto com as URLs das imagens
import NavBar from '../../components/Global/NavBar';
import Footer from '../../components/Global/Footer';
import Header from '../../components/Global/Header';

interface Registration {
    state: string;
    number: string;
    enabled: string;
    statusDate: string;
    status_text: string;
    type_text: string;
}

interface CompanyData {
    name: string;
    taxId: string;
    founded: string;
    nature: string;
    full_address: string;
    status: string;
    activities: string[];
    simples_optant: string;
    simei_optant: string;
    registrations_count: number;
    registrations: Registration[];
    monitoring: string;
    visiting: string;
    territoryZone: string;
}

interface CompanyDataDownload {
    taxId: string;
    nature: string;
    sap_customer_id: string;
    territoryZone: string;
    name: string;
    date: string;
    alias: string;
    address: String;
    addon_address: String;
    district: String;
    zip: String;
    city: String;
    state: String;
    status: String;
    registrations_count: String;
    most_recent_registration_number: String;
    most_recent_registration_status: String;
    most_recent_registration_state: String;
    most_recent_registration_status_text: String;
    simples_optant: String;
    simei_optant: String;
}

export const CNPJFolder = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isPopupDeleteCompany, setIsPopupDeleteCompany] = useState(false);
    const [isPopupMoveFolder, setIsPopupMoveFolder] = useState(false);
    const [isPopupRenameFolder, setIsPopupRenameFolder] = useState(false);
    const [folders, setFolders] = useState<string[]>([]);
    const [selectedTaxId, setSelectedTaxId] = useState<string | null>(null);
    const [isPopupOpenDownload, setIsPopupOpenDownload] = useState(false);
    const [isPopupOpenView, setIsPopupOpenView] = useState(false);
    const [isPopupOpenMap, setIsPopupOpenMap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [copiedText, setCopiedText] = useState('');
    const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
    const [statusMessageArchive, setStatusMessageArchive] = useState('');
    const [companyData, setCompanyData] = useState<CompanyData[]>([]);
    const [companyDataDownload, setCompanyDataDownload] = useState<
        CompanyDataDownload[]
    >([]);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [excelFileAvailable, setExcelFileAvailable] = useState<
        boolean | null
    >(null);
    const [loadingExcelCheck, setLoadingExcelCheck] = useState(false);
    const [repeatOption, setRepeatOption] = useState<string | null>(null);
    const isSaveEnabled = selectedDateTime !== '';
    const [page, setPage] = useState(1);
    const [perPage] = useState(5);
    const [pageDownload, setPageDownload] = useState(1);
    const [perPageDownload] = useState(1000);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [menuOpen, setMenuOpen] = useState(
        Array(companyData.length).fill(false)
    );
    const [isSaveEnabledMove, setIsSaveEnabledMove] = useState(false);
    const [taxIdCount, setTaxIdCount] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(null);
    const [monitoringCount, setMonitoringCount] = useState(null);

    const nextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const nextPageDownload = () => {
        setPageDownload(prevPage => prevPage + 1);
    };

    const prevPageDownload = () => {
        setPageDownload(prevPage => Math.max(prevPage - 1, 1));
    };

    // Mapeamento dos valores numéricos para os dias da semana
    const visitingLabels: { [key: string]: string } = {
        '2': 'Segunda-Feira',
        '3': 'Terça-Feira',
        '4': 'Quarta-Feira',
        '5': 'Quinta-Feira',
        '6': 'Sexta-Feira',
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const getStatusStyle = (status: string) => {
        switch (status) {
            case 'Nula':
                return { backgroundColor: '#D1D5DB', color: '#000000' };
            case 'Ativa':
                return { backgroundColor: '#10B981', color: '#FFFFFF' };
            case 'Suspensa':
                return { backgroundColor: '#FBBF24', color: '#000000' };
            case 'Inapta':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            case 'Ativa Não Regular':
                return { backgroundColor: '#3B82F6', color: '#FFFFFF' };
            case 'Baixada':
                return { backgroundColor: '#8B5CF6', color: '#FFFFFF' };
            default:
                return {};
        }
    };

    const getSimplesOptantStyle = (simplesOptant: string) => {
        switch (simplesOptant) {
            case 'Optante':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' };
            case 'Não Optante':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            default:
                return {};
        }
    };

    const getSimeiOptantStyle = (simeiOptant: string) => {
        switch (simeiOptant) {
            case 'Enquadrado':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' };
            case 'Não Enquadrado':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            default:
                return {};
        }
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const renderizarImagemEstado = (estado: string) => {
        const urlImagem = stateImages[estado];
        if (urlImagem) {
            return (
                <img
                    src={urlImagem}
                    alt={`Bandeira ${estado}`}
                    className="w-8 h-6 ml-3 mr-1"
                />
            );
        }
        return null;
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleDownloadExcel = async () => {
        console.log('Download state:', companyDataDownload);
        if (companyDataDownload.length === 0) {
            console.log('Nenhum dado para exportar.');
            return;
        }
        const rows = companyDataDownload.map(company => {
            return {
                DATA: company.date || 'N/A',
                'SAP CUSTOMER ID': company.sap_customer_id || 'N/A',
                'SALES TERRITORY': company.territoryZone || 'N/A',
                'RAZÃO SOCIAL': company.name || 'N/A',
                NOME_FANTASIA: company.alias || 'N/A',
                CNPJ: company.taxId || 'N/A',
                'TIPO SOCIETARIO': company.nature || 'N/A',
                'ENDEREÇO COM NÚMERO': company.address || 'N/A',
                COMPLEMENTO: company.addon_address || 'N/A',
                BAIRRO: company.district || 'N/A',
                CEP: company.zip || 'N/A',
                CIDADE: company.city || 'N/A',
                UF: company.state || 'N/A',
                'SITUAÇÃO CADASTRAL': company.status || 'N/A',
                'QUANTIDADE DE I.E': company.registrations_count || 'N/A',
                'NÚMERO DA INSCRIÇÃO ESTADUAL':
                    company.most_recent_registration_number.replace(/"/g, '') ||
                    'N/A',
                'STATUS DA INSCRIÇÃO':
                    company.most_recent_registration_status || 'N/A',
                'UF DA IE': company.most_recent_registration_state || 'N/A',
                'SITUAÇÃO DO CNPJ':
                    company.most_recent_registration_status_text || 'N/A',
                'OPTANTE SIMPLES': company.simples_optant || 'N/A',
                'OPTANTE SIMEI': company.simei_optant || 'N/A',
            };
        });
        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Informações');
        const today = new Date().toISOString().split('T')[0];
        const fileName = `CNPJ-${today}.xlsx`;
        XLSX.writeFile(workbook, fileName, { compression: true });
    };

    const handleDownloadClick = async () => {
        const user = getUserFromLocalStorage();
        setLoadingDownload(true);
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/download-cnpj-all-data/${userId}/${folder}`;
            try {
                await axios.get(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
                setStatusMessageArchive(
                    'Download iniciado com sucesso. Você receberá um e-mail quando o processamento for finalizado.'
                );
                setLoadingDownload(false);
                setTimeout(() => {
                    setIsPopupOpenDownload(false);
                    navigate('/cnpj');
                }, 3000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setIsPopupOpenDownload(false);
            navigate('/cnpj');
        }
    };

    const handleDownloadExcelFileClick = async () => {
        const user = getUserFromLocalStorage();
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const endpointDownload = `${REACT_APP_SERVER_URL}/api/download-excel-file/${userId}/${folder}`;
            try {
                const response = await axios.get(endpointDownload, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    responseType: 'arraybuffer',
                });
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const disposition = response.headers['content-disposition'];
                let fileName = `Base de Clientes CNPJ - ${folder}.xlsx`;
                if (disposition && disposition.indexOf('filename=') !== -1) {
                    const filenameRegex =
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error(
                    'Erro ao fazer download do arquivo Excel:',
                    error
                );
            }
        } else {
            console.error('Usuário não autenticado para realizar o download');
        }
    };

    useEffect(() => {
        if (isPopupOpenDownload && id) {
            const user = getUserFromLocalStorage();
            if (user && user.userId) {
                const userId = user.userId.replace(/^"(.*)"$/, '$1');
                const bearerToken =
                    'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
                const endpointDownload = `${REACT_APP_SERVER_URL}/api/download-excel-file/${userId}/${id}`;
                setLoadingExcelCheck(true);
                axios
                    .head(endpointDownload, {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    })
                    .then(() => {
                        setExcelFileAvailable(true);
                        setLoadingExcelCheck(false);
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 404) {
                            setExcelFileAvailable(false);
                        } else {
                            console.error(
                                'Erro ao verificar a existência do Excel:',
                                err
                            );
                            setExcelFileAvailable(false);
                        }
                        setLoadingExcelCheck(false);
                    });
            }
        }
    }, [isPopupOpenDownload, id]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id;
            const userId = JSON.parse(storedUserId);
            axios
                .all([
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-active-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                    axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-monitoring-count/${userId}?folder=${folder}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    ),
                ])
                .then(
                    axios.spread(
                        (
                            countResponse,
                            activeCountResponse,
                            monitoringCountResponse
                        ) => {
                            setTaxIdCount(countResponse.data.taxId_count);
                            setActiveStatusCount(
                                activeCountResponse.data.activeStatusCount
                            );
                            setMonitoringCount(
                                monitoringCountResponse.data.monitoringCount
                            );
                        }
                    )
                )
                .catch(error => {
                    console.error('Error fetching counts:', error);
                });
        } else {
            console.log('No storedUserId found');
        }
    }, [id]);

    useEffect(() => {
        setIsSaveEnabledMove(isFolderSelected);
    }, [isFolderSelected]);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                const folder = id;
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-data-folder/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&page=${page}&perPage=${perPage}`
                    );
                    if (page === 1) {
                        setCompanyData(response.data);
                    } else {
                        setCompanyData(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };
        fetchData();
    }, [id, page, perPage]);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                const folder = id;
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/download-cnpj-data-folder/${JSON.parse(
                            storedUserId
                        )}?folder=${folder}&pageDownload=${pageDownload}&perPageDownload=${perPageDownload}`
                    );
                    if (pageDownload === 1) {
                        setCompanyDataDownload(response.data);
                    } else {
                        setCompanyDataDownload(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };
        fetchData();
    }, [id, pageDownload, perPageDownload]);

    return (
        <div className="min-h-screen bg-[#0F172A] text-white flex flex-col">
            <NavBar />
            <div className="flex items-center justify-start mt-4 mb-0 ml-6">
                <div className="flex items-center bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg font-montserrat text-white px-6 py-3 transition duration-300 font-semibold">
                    {/* Texto ou ícone para CNPJ */}
                    <span className="font-montserrat text-lg font-semibold">
                        CNPJ
                    </span>
                    {/* Ícone de seta indicando a hierarquia */}
                    <RiArrowRightSLine className="w-5 h-5 mx-3" />
                    {/* Ícone e nome da pasta */}
                    <RiFolderSharedLine className="w-5 h-5 mr-2 text-orangeNeuron" />
                    <span className="font-montserrat text-lg font-semibold">
                        {id}
                    </span>
                </div>
            </div>

            <div className="text-white ml-2 mt-6 mb-0 px-4">
                <input
                    type="text"
                    id="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Pesquisar pelo CNPJ..."
                    className="w-full md:max-w-[1070px] h-[40px] px-4 py-2 text-base rounded-xl shadow-2xl bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 font-montserrat text-white"
                />
            </div>
            <div className="flex flex-col md:flex-row ml-6 px-4">
                <div
                    className="w-full md:w-[1100px] h-[590px] p-4 mr-0 md:mr-6 md:-ml-8 rounded-xl overflow-y-auto"
                    style={{ scrollbarColor: '#6B7280 #4B5563' }}
                >
                    {companyData
                        .filter(company => company.taxId.includes(searchTerm))
                        .map((company, index) => (
                            <div
                                key={index}
                                className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl rounded-xl p-4 mb-4 mt-4 flex flex-col md:flex-row"
                            >
                                <div className="flex-1 mr-0 md:mr-8">
                                    <div className="flex items-center">
                                        <div>
                                            <p
                                                className="text-sm font-medium font-montserrat overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap mt-3 cursor-pointer"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.name || 'N/A'
                                                    )
                                                }
                                            >
                                                <h2 className="text-white text-lg font-bold font-montserrat">
                                                    {truncateText(
                                                        company.name,
                                                        34
                                                    )}
                                                </h2>
                                            </p>
                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                        </div>
                                        <p
                                            className="text-sm font-montserrat rounded-lg px-2 py-1 text-center font-bold ml-3 mt-3 cursor-pointer"
                                            style={getStatusStyle(
                                                company.status
                                            )}
                                            onClick={() =>
                                                handleCopyClick(
                                                    company.status || 'N/A'
                                                )
                                            }
                                        >
                                            {company.status}
                                        </p>
                                    </div>
                                    <p
                                        className="text-gray-200 flex items-center text-sm mt-1 font-montserrat cursor-pointer"
                                        onClick={() =>
                                            handleCopyClick(
                                                `CNPJ: ${
                                                    company.taxId || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        CNPJ: {company.taxId || 'N/A'}
                                    </p>
                                    <p
                                        className="text-white flex items-center text-sm mt-4 font-montserrat cursor-pointer"
                                        onClick={() =>
                                            handleCopyClick(
                                                `Fundada em: ${
                                                    company.founded || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        <BiCalendar className="mr-1 w-5 h-5" />{' '}
                                        Fundada em: {company.founded || 'N/A'}
                                    </p>
                                    <p
                                        className="text-white flex items-center text-sm mt-1 font-montserrat cursor-pointer"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.nature || 'N/A'
                                            )
                                        }
                                    >
                                        <BiWorld className="mr-1 w-5 h-5" />
                                        {company.nature}
                                    </p>
                                    <div className="text-white flex items-center text-sm mt-1 font-montserrat">
                                        <BiBuildingHouse className="mr-1 w-5 h-5" />
                                        <p
                                            className="text-sm cursor-pointer"
                                            onClick={() =>
                                                handleCopyClick(
                                                    truncateText(
                                                        company.full_address,
                                                        40
                                                    ) || 'N/A'
                                                )
                                            }
                                        >
                                            {truncateText(
                                                company.full_address,
                                                60
                                            )}
                                        </p>
                                    </div>
                                    <div className="text-white flex items-center text-sm mt-1 font-montserrat">
                                        <IoMdTime className="mr-1 w-5 h-5" />
                                        <p
                                            className="text-sm cursor-pointer"
                                            onClick={() =>
                                                handleCopyClick(
                                                    truncateText(
                                                        company.territoryZone?.trim()
                                                            ? company.territoryZone
                                                            : 'Não possui',
                                                        60
                                                    )
                                                )
                                            }
                                        >
                                            Sales Territory:{' '}
                                            {company.territoryZone?.trim()
                                                ? company.territoryZone
                                                : 'Não possui'}
                                        </p>
                                    </div>
                                    <div className="text-white flex items-center text-sm mt-1 font-montserrat">
                                        <IoMdTime className="mr-1 w-5 h-5" />
                                        <p
                                            className="text-sm cursor-pointer"
                                            onClick={() =>
                                                handleCopyClick(
                                                    truncateText(
                                                        company.monitoring?.trim()
                                                            ? company.monitoring
                                                            : 'Não possui',
                                                        60
                                                    )
                                                )
                                            }
                                        >
                                            Monitoramento:{' '}
                                            {company.monitoring?.trim()
                                                ? company.monitoring
                                                : 'Não possui'}
                                        </p>
                                    </div>
                                    <div className="text-white flex items-center text-sm mt-1 font-montserrat">
                                        <FaCar className="mr-1 w-5 h-5" />
                                        <p
                                            className="text-sm cursor-pointer"
                                            onClick={() =>
                                                handleCopyClick(
                                                    truncateText(
                                                        visitingLabels[
                                                            company.visiting?.trim() as string
                                                        ] || 'Não possui',
                                                        60
                                                    )
                                                )
                                            }
                                        >
                                            Visita:{' '}
                                            {visitingLabels[
                                                company.visiting?.trim() as string
                                            ] || 'Não possui'}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-6">
                                            <IoBusinessOutline className="mr-2 w-7 h-7" />{' '}
                                            Atividades
                                        </h3>
                                        <ul className="space-y-2">
                                            {company.activities
                                                .slice(0, 5)
                                                .map((activity, idx) => (
                                                    <li
                                                        key={idx}
                                                        className="text-sm font-montserrat text-white flex items-center cursor-pointer"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                activity ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        <MdCheck
                                                            size={16}
                                                            className="mr-2 text-green-500"
                                                        />
                                                        {truncateText(
                                                            activity,
                                                            56
                                                        )}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex-1 ml-0 md:ml-4 mt-4 md:mt-0 relative">
                                    <div>
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-1">
                                            <MdOutlineAssessment className="mr-2 w-7 h-7" />{' '}
                                            Simples Nacional
                                        </h3>
                                        <div className="text-white flex items-center">
                                            <h2 className="text-md font-bold text-white font-montserrat">
                                                Simples:
                                            </h2>
                                            <p
                                                className="text-base font-montserrat rounded-lg px-2 py-1 w-30 text-center font-semibold ml-2 cursor-pointer"
                                                style={getSimplesOptantStyle(
                                                    company.simples_optant
                                                )}
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.simples_optant ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                {company.simples_optant}
                                            </p>
                                            <h2 className="text-md font-bold text-white font-montserrat ml-10">
                                                Simei:
                                            </h2>
                                            <p
                                                className="text-base font-montserrat rounded-lg px-2 py-1 w-30 text-center font-semibold ml-2 cursor-pointer"
                                                style={getSimeiOptantStyle(
                                                    company.simei_optant
                                                )}
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.simei_optant ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                {company.simei_optant}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[500px] mt-4">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700  pb-2 mt-6">
                                            <MdOutlineMapsHomeWork className="mr-2 w-7 h-7" />{' '}
                                            Inscrições Estaduais -{' '}
                                            {company.registrations_count}
                                        </h3>
                                        {company.registrations_count === 0 ? (
                                            <div className="flex justify-center items-center w-full h-32">
                                                <MdErrorOutline className="w-16 h-16 text-[#212e4b]" />
                                            </div>
                                        ) : (
                                            <div className="flex overflow-x-auto">
                                                {company.registrations.map(
                                                    (registration, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="rounded-lg p-4 m-2 flex-shrink-0 font-montserrat text-white bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] shadow-lg border border-[#2A3B55]/60 mb-4"
                                                        >
                                                            <p
                                                                className="text-gray-300 font mb-2 flex items-center cursor-pointer"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Estado da Inscrição: ${registration.state}`
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Estado da
                                                                    Inscrição:
                                                                </span>{' '}
                                                                {renderizarImagemEstado(
                                                                    registration.state
                                                                )}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.state
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="text-gray-300 font-montserrat cursor-pointer"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Número da Inscrição: ${registration.number}`
                                                                    )
                                                                }
                                                            >
                                                                Número da
                                                                Inscrição:{' '}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.number
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p className="mt-1">
                                                                Status da
                                                                Inscrição:{' '}
                                                                <span
                                                                    className={`text-gray-300 p-1 rounded-md font-montserrat font-semibold ${
                                                                        registration.enabled ===
                                                                        'Habilitado'
                                                                            ? 'bg-green-500'
                                                                            : 'bg-red-500'
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleCopyClick(
                                                                            `Status da Inscrição: ${registration.enabled}`
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        registration.enabled
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="text-gray-300 mt-1 font-montserrat cursor-pointer"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Situação: ${registration.status_text}`
                                                                    )
                                                                }
                                                            >
                                                                Situação:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.status_text
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="text-gray-300 mt-1 cursor-pointer"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Tipo: ${registration.type_text}`
                                                                    )
                                                                }
                                                            >
                                                                Tipo:{' '}
                                                                <span className="font-semibold font-montserrat text-gray-300">
                                                                    {
                                                                        registration.type_text
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {loading && (
                        <p className="font-monteserrat text-white">
                            Carregando...
                        </p>
                    )}
                    {error && <p>{error}</p>}
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={prevPage}
                            disabled={page === 1 || loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandLess className="w-6 h-6" />
                        </button>
                        <button
                            onClick={nextPage}
                            disabled={loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandMore className="w-6 h-6" />
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-6 md:mt-[-10] w-full md:w-auto">
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2">
                        <RiRobot2Line className="mr-2 w-7 h-7" /> Escolha uma
                        ação
                    </h3>
                    <button
                        onClick={() => {
                            setIsPopupOpenView(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-darkAlt hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-full md:w-56 mt-1 border border-transparent"
                    >
                        <LuSheet className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Planilha
                        </span>
                    </button>
                    <button
                        onClick={() => {
                            setIsPopupOpenDownload(true);
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-darkAlt hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-full md:w-56 mt-3 border border-transparent"
                    >
                        <MdOutlineFileDownload className="w-5 h-5 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Download
                        </span>
                    </button>
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-10">
                        <IoAnalytics className="mr-2 w-7 h-7" /> Indicadores
                    </h3>
                    <div className="flex mt-2 w-full">
                        <div className="flex mt-2 w-full">
                            {taxIdCount !== null && (
                                <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full md:w-56">
                                    {taxIdCount} CNPJs
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex mt-4 w-full md:w-56">
                        {activeStatusCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full">
                                {activeStatusCount} Ativos
                            </div>
                        )}
                    </div>
                    <div className="flex mt-4 w-full md:w-56">
                        {monitoringCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] p-3 w-full">
                                {monitoringCount} Monitorados
                            </div>
                        )}
                    </div>
                </div>
                {isPopupOpenView && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-[1300px] h-auto md:h-[700px] p-8 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] overflow-auto">
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsPopupOpenView(false)}
                                    className="text-gray-300 hover:text-gray-400 focus:outline-none absolute top-4 right-4"
                                >
                                    <IoMdCloseCircleOutline className="h-6 w-6" />
                                </button>
                            </div>
                            <h2 className="text-[#57B4B9] font-montserrat text-3xl font-semibold mb-0 -mt-2">
                                Baixar Dados Empresariais
                            </h2>
                            <p className="text-gray-300 font-montserrat text-base mb-5">
                                Baixe os dados detalhados das empresas em
                                formato Excel.
                            </p>
                            <button
                                onClick={handleDownloadExcel}
                                className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md font-montserrat hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400 absolute top-16 right-8 mb-4"
                            >
                                Download Excel
                            </button>
                            <div className="overflow-x-auto h-[500px] mt-12">
                                <div className="sticky top-0">
                                    <table className="w-full table-fixed border-collapse">
                                        <thead className="sticky top-0 bg-[#0E1621] text-white font-normal font-montserrat text-xs">
                                            <tr className="border-b border-[#57B4B9]">
                                                <th className="px-3 py-2 w-[50px] text-center">
                                                    Nº
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    DATA
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    SAP ID
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    SALES TERRITORY
                                                </th>
                                                <th className="px-3 py-2 w-[200px] text-center">
                                                    RAZÃO SOCIAL
                                                </th>
                                                <th className="px-3 py-2 w-[200px] text-center">
                                                    NOME_FANTASIA
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    CNPJ
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    TIPO SOCIETARIO
                                                </th>
                                                <th className="px-3 py-2 w-[250px] text-center">
                                                    ENDEREÇO <br /> COM NÚMERO
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    COMPLEMENTO
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    BAIRRO
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    CEP
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    CIDADE
                                                </th>
                                                <th className="px-3 py-2 w-[50px] text-center">
                                                    UF
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    SITUAÇÃO
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    QUANTIDADE DE I.E
                                                </th>
                                                <th className="px-3 py-2 w-[250px] text-center">
                                                    NÚMERO DA <br /> INSCRIÇÃO
                                                    ESTADUAL
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    STATUS DA <br /> INSCRIÇÃO
                                                </th>
                                                <th className="px-3 py-2 w-[60px] text-center">
                                                    UF DA IE
                                                </th>
                                                <th className="px-3 py-2 w-[150px] text-center">
                                                    SITUAÇÃO DO <br /> CNPJ
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    OPTANTE <br /> SIMPLES
                                                </th>
                                                <th className="px-3 py-2 w-[100px] text-center">
                                                    OPTANTE <br /> SIMEI
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-[#0E1621] text-gray-300 font-montserrat text-xs">
                                            {companyDataDownload.map(
                                                (item, index) => (
                                                    <tr
                                                        key={index}
                                                        className="border-b border-[#2D3748]"
                                                    >
                                                        <td className="px-3 py-2 w-[50px] text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {item.date}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {
                                                                item.sap_customer_id
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {item.territoryZone}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px] text-center">
                                                            {item.name}
                                                        </td>
                                                        <td className="px-3 py-2 w-[200px] text-center">
                                                            {item.alias}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.taxId}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.nature}
                                                        </td>
                                                        <td className="px-3 py-2 w-[250px] text-center">
                                                            {item.address}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.addon_address}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.district}
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {item.zip}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.city}
                                                        </td>
                                                        <td className="px-3 py-2 w-[50px] text-center">
                                                            {item.state}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {item.status}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {
                                                                item.registrations_count
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[250px] text-center">
                                                            {item.most_recent_registration_number.replace(
                                                                /"/g,
                                                                ''
                                                            )}
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {
                                                                item.most_recent_registration_status
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[60px] text-center">
                                                            {
                                                                item.most_recent_registration_state
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[150px] text-center">
                                                            {
                                                                item.most_recent_registration_status_text
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {
                                                                item.simples_optant
                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 w-[100px] text-center">
                                                            {item.simei_optant}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={prevPageDownload}
                                    disabled={pageDownload === 1}
                                    className={`px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 ${
                                        pageDownload === 1
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-[#1A202C]'
                                    }`}
                                >
                                    Anterior
                                </button>
                                <button
                                    onClick={nextPageDownload}
                                    className="px-4 py-2 bg-[#2D3748] text-gray-200 rounded-md hover:bg-[#1A202C] focus:outline-none focus:ring-2 focus:ring-gray-400"
                                >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isPopupOpenMap && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenMap(false)}
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {isPopupOpenDownload && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85 p-4">
                        <div className="relative w-full max-w-lg p-8 rounded-2xl shadow-2xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <div className="mb-6 border-b border-gray-800 pb-4">
                                <h2 className="text-2xl text-[#57B4B9] font-montserrat font-bold text-center">
                                    Download em Massa da Base de Clientes
                                </h2>
                                <p className="mt-2 text-center text-gray-300 font-montserrat text-sm">
                                    Se o arquivo Excel já foi gerado, você pode
                                    baixá-lo abaixo.
                                </p>
                            </div>
                            <div className="flex items-center space-x-4 p-4 rounded-lg border-2 border-dotted border-gray-800 shadow-2xl mb-6">
                                <div className="flex items-center justify-center w-16 h-16 bg-gray-700 rounded-lg">
                                    <img
                                        src="https://logospng.org/download/microsoft-excel/logo-microsoft-excel-1024.png"
                                        alt="Ícone de Excel"
                                        className="w-10 h-10"
                                    />
                                </div>
                                <div className="flex-1">
                                    {loadingExcelCheck ? (
                                        <div className="text-sm text-gray-300 font-montserrat">
                                            <FaSpinner className="animate-spin inline-block w-5 h-5 mr-2" />
                                            Verificando disponibilidade...
                                        </div>
                                    ) : excelFileAvailable ? (
                                        <button
                                            onClick={async () => {
                                                setLoadingDownloadExcel(true);
                                                await handleDownloadExcelFileClick();
                                                setLoadingDownloadExcel(false);
                                            }}
                                            disabled={loadingDownloadExcel}
                                            className="w-full flex items-center justify-center text-white rounded-xl font-montserrat text-sm font-semibold p-2 bg-[#223750] shadow-xl border-2 border-gray-700 hover:border-[#00B3BE] transition duration-300 ease-in-out hover:bg-[#2f4a68]"
                                        >
                                            {loadingDownloadExcel ? (
                                                <FaSpinner className="animate-spin inline-block w-6 h-6 mr-2" />
                                            ) : (
                                                <FiDownload className="w-6 h-6 mr-2" />
                                            )}
                                            {loadingDownloadExcel
                                                ? 'Carregando...'
                                                : 'Baixar Excel'}
                                        </button>
                                    ) : (
                                        <div className="text-sm text-red-500 font-montserrat">
                                            Nenhum arquivo disponível.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-6 border-b border-gray-800 pb-4">
                                <h2 className="text-2xl text-[#57B4B9] font-montserrat font-bold text-center">
                                    Solicitar Download em Massa
                                </h2>
                                <p className="mt-2 text-center text-gray-300 font-montserrat text-sm">
                                    A versão exibida acima é a última que você
                                    solicitou.
                                    <br />
                                    Para obter os dados mais atualizados, clique
                                    em Download para gerar um novo arquivo.
                                </p>
                                {statusMessageArchive && (
                                    <div className="text-center text-green-500 font-montserrat text-base font-bold mt-4">
                                        {statusMessageArchive}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <button
                                    onClick={() =>
                                        setIsPopupOpenDownload(false)
                                    }
                                    className="flex-1 mr-2 flex items-center justify-center px-6 py-3 text-white bg-red-600 hover:bg-red-500 rounded-lg font-montserrat text-base font-semibold transition duration-300 ease-in-out"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleDownloadClick}
                                    className="flex-1 ml-2 flex items-center justify-center px-6 py-3 text-[#192339] bg-[#00B3BE] hover:bg-[#00D3CF] rounded-lg font-montserrat text-base font-semibold transition duration-300 ease-in-out"
                                >
                                    <FiDownload className="w-6 h-6 mr-2" />
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {loadingDownload && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                            <FaSpinner className="animate-spin w-6 h-6 text-white" />
                            <span className="text-base text-white font-montserrat font-semibold">
                                Enviando para Download...
                            </span>
                        </div>
                    </div>
                )}
                {copiedText && (
                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                        {copiedText}
                    </div>
                )}
                {loadingCompanyData && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                            <FaSpinner className="animate-spin w-6 h-6 text-white" />
                            <span className="text-base text-white font-montserrat font-semibold">
                                Carregando...
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};
