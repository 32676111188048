import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import Breadcrumb from '../Global/Breadcrumb';

import { MdOutlineFileDownload } from 'react-icons/md';
import { BiBuildingHouse, BiCalendar, BiWorld } from 'react-icons/bi';
import { MdCheck } from 'react-icons/md'; // Importe o ícone desejado
import { MdOutlineAssessment } from 'react-icons/md';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { IoBusinessOutline } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import { IoAnalytics } from 'react-icons/io5';
import { FiArchive } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { MdOutlineExpandLess } from 'react-icons/md';
import { MdOutlineExpandMore } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { MdOutlineFindInPage } from "react-icons/md";
import { FaFilter } from "react-icons/fa";


import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

import { stateImages } from './state-imgs'; // Importe o objeto com as URLs das imagens

interface CompanyData {
    name: string;
    taxId: string;
    founded: string;
    nature: string;
    full_address: string;
    status: string;
    activities: string[];
    simples_optant: string;
    simei_optant: string;
    registrations_count: number;
    registrations: Registration[]; // Adicionar o campo de registros
}

interface Registration {
    state: string;
    number: string;
    enabled: string;
    statusDate: string;
    status_text: string;
    type_text: string;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const MaDatastore = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpenAlert, setIsPopupOpenAlert] = useState(false);
    const [isPopupOpenDownload, setIsPopupOpenDownload] = useState(false);
    const [isPopupOpenArchive, setIsPopupOpenArchive] = useState(false);
    const [folderName, setFolderName] = useState<string>('');
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [registrationsCount, setRegistrationsCount] = useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [copiedText, setCopiedText] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [taxIdCount, setTaxIdCount] = useState(null);
    const [activeStatusCount, setActiveStatusCount] = useState(null);
    const [companyData, setCompanyData] = useState<CompanyData[]>([]);
    const [repeatOption, setRepeatOption] = useState<string | null>(null);
    const isSaveEnabled = selectedDateTime !== '';
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage] = useState(5); // Mantendo perPage fixo a 5, você pode torná-lo dinâmico se necessário
    const [error, setError] = useState<string | null>(null); // Ajuste de tipo
    const [searchTerm, setSearchTerm] = useState('');

    const [availability, setAvailability] = useState<{
        status: string;
        data: string;
    } | null>(null);

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' }, // Adiciona a rota aqui
        { label: 'Datastore' },
    ];

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folder = id; // Obtém o parâmetro folder da URL

            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/cnpj-count/${JSON.parse(
                        storedUserId
                    )}?folder=${folder}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    console.log('API response:', response.data); // Log de depuração
                    setTaxIdCount(response.data.taxId_count);
                    setRegistrationsCount(response.data.registrations_count);
                    setActiveStatusCount(response.data.active_status_count); // Definir o estado activeStatusCount
                })
                .catch(error => {
                    console.error('Error fetching tax ID count:', error);
                });
        } else {
            console.log('No storedUserId found'); // Log de depuração
        }
    }, [id]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/availability/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const { status, data } = response.data;
                    setAvailability({ status, data });
                })
                .catch(error => {
                    console.error('Error fetching availability:', error);
                    // Handle error if needed
                });
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingCompanyData(true);
            const storedUserId = localStorage.getItem('userId');
    
            if (storedUserId) {
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/datastore-data/${JSON.parse(storedUserId)}?page=${page}&perPage=${perPage}`
                    );
                    if (page === 1) {
                        // Se for a primeira página, substitui os dados anteriores
                        setCompanyData(response.data);
                    } else {
                        // Caso contrário, acumula os dados novos aos anteriores
                        setCompanyData(prevData => [
                            ...prevData,
                            ...response.data,
                        ]);
                    }
                    setLoadingCompanyData(false);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações da empresa.');
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };
    
        fetchData();
    }, [id, page, perPage]);
    

    useEffect(() => {
        const fetchData = async () => {
            // Sua lógica de busca
        };
        fetchData();
    }, [id, page, perPage]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const getStatusStyle = (status: string) => {
        switch (status) {
            case 'Nula':
                return { backgroundColor: '#D1D5DB', color: '#000000' };
            case 'Ativa':
                return { backgroundColor: '#10B981', color: '#FFFFFF' };
            case 'Suspensa':
                return { backgroundColor: '#FBBF24', color: '#000000' };
            case 'Inapta':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' };
            case 'Ativa Não Regular':
                return { backgroundColor: '#3B82F6', color: '#FFFFFF' };
            case 'Baixada':
                return { backgroundColor: '#8B5CF6', color: '#FFFFFF' };
            default:
                return {}; // Padrão para outros casos
        }
    };

    const getSimplesOptantStyle = (simplesOptant: string) => {
        switch (simplesOptant) {
            case 'Optante':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' }; // Verde claro com texto branco
            case 'Não Optante':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' }; // Laranja com texto branco
            default:
                return {}; // Padrão para outros casos
        }
    };

    const getSimeiOptantStyle = (simeiOptant: string) => {
        switch (simeiOptant) {
            case 'Enquadrado':
                return { backgroundColor: '#4CAF50', color: '#FFFFFF' }; // Azul com texto branco
            case 'Não Enquadrado':
                return { backgroundColor: '#EF4444', color: '#FFFFFF' }; // Roxo com texto branco
            default:
                return {}; // Padrão para outros casos
        }
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const renderizarImagemEstado = (estado: string) => {
        const urlImagem = stateImages[estado];
        if (urlImagem) {
            return (
                <img
                    src={urlImagem}
                    alt={`Bandeira ${estado}`}
                    className="w-8 h-6 ml-3 mr-1"
                />
            );
        }
        return null; // Retorna null se a URL da imagem não estiver disponível
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            const schedule = selectedDateTime;
            const folderId = id;
            let repeat = repeatOption;

            if (!repeat) {
                repeat = 'empty';
            }

            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/schedule`;

            try {
                await axios.post(
                    endpointMonitoring,
                    {
                        schedule: schedule,
                        user_id: userId,
                        folder: folderId,
                        repeat: repeat,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                );

                setStatusMessage('Monitoramento Agendado com Sucesso'); // Define a mensagem de sucesso
                // Limpar a mensagem após 3 segundos
                setTimeout(() => {
                    setStatusMessage('');
                }, 2000); // 3000 milissegundos = 3 segundos
            } catch (error) {
                console.error('Request error:', error);
            }
        } else {
            setLoading(false);
        }
    };

    const handleDownloadClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            const folderId = id;
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/download/${userId}`;

            // Verifica se end_date está vazio e preenche com a data atual se for o caso
            const currentDate = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
            const requestData = {
                folder: folderId || '', // Garantindo que 'folder' seja uma string
                start_date: selectedStartDate,
                end_date: selectedEndDate || currentDate, // Preenche com a data atual se estiver vazio
            };

            try {
                await axios.get(endpointMonitoring, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    params: requestData,
                });

                setStatusMessage('Download Feito com Sucesso'); // Define a mensagem de sucesso
            } catch (error) {
                console.error('Request error:', error);
                setStatusMessage('Erro no Download'); // Define a mensagem de erro
            }
        } else {
            setLoading(false);
        }
    };

    const handleArchiveClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            const folderId = id; // Certifique-se de que id esteja definido corretamente
            const endpointMonitoring = `${REACT_APP_SERVER_URL}/api/market-intelligence/archive-folder/${userId}/${folderId}`;

            try {
                await axios.put(endpointMonitoring, null, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });

                setStatusMessage('Pasta Arquivada com Sucesso'); // Define a mensagem de sucesso

                // Exibir mensagem por 2 segundos antes de fechar o popup e navegar para a rota '/market-analysis'
                setTimeout(() => {
                    setIsPopupOpenArchive(false);
                    navigate('/market-analysis');
                }, 2000);
            } catch (error) {
                console.error('Erro na requisição:', error);
            }
        } else {
            setLoading(false);
            setIsPopupOpenArchive(false);
            navigate('/market-analysis'); // Redirecionar para a rota '/market-analysis'
        }
    };

    const handleRepeatOption = (option: string) => {
        if (repeatOption === option) {
            // Se a opção atual já estiver selecionada, desmarque-a
            setRepeatOption(null);
        } else {
            // Caso contrário, selecione a opção clicada
            setRepeatOption(option);
        }
    };

    const nextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-5">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="text-white ml-6 -mt-5">
                <input
                    type="text"
                    id="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Pesquisar pelo CNPJ..."
                    className="w-[1055px] h-[40px] px-4 py-2 text-base rounded-md shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] font-montserrat text-white"
                />
            </div>

            <div className="flex ml-10 mt-1 ">
                <div
                    className="w-[1100px] h-[550px] p-4 mr-6 -ml-8 rounded-xl  overflow-y-auto"
                    style={{ scrollbarColor: '#6B7280 #4B5563' }}
                >
                    {companyData
                        .filter(company => company.taxId.includes(searchTerm))
                        .map((company, index) => (
                            <div
                                key={index}
                                className="bg-gradient-to-b from-[#0E1621] to-[#192339] rounded-xl shadow-md p-4 mb-4 mt-4 flex"
                            >
                                <div className="flex-1 mr-8">
                                    <div className="flex items-center">
                                        <div>
                                            <p
                                                className="text-sm font-medium font-montserrat overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.name || 'N/A'
                                                    )
                                                }
                                            >
                                                <h2 className="text-white text-lg font-bold font-montserrat">
                                                    {truncateText(
                                                        company.name,
                                                        34
                                                    )}
                                                </h2>
                                            </p>

                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                        </div>
                                        <p
                                            className="text-sm font-montserrat rounded-lg px-2 py-1 text-center font-bold ml-3 mt-0"
                                            style={getStatusStyle(
                                                company.status
                                            )}
                                            onClick={() =>
                                                handleCopyClick(
                                                    company.status || 'N/A'
                                                )
                                            }
                                        >
                                            {company.status}
                                        </p>
                                    </div>
                                    <p
                                        className="text-gray-200 flex items-center text-sm mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                `CNPJ: ${
                                                    company.taxId || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        CNPJ: {company.taxId || 'N/A'}
                                    </p>

                                    <p
                                        className="text-white flex items-center text-sm font-montserrat mt-4"
                                        onClick={() =>
                                            handleCopyClick(
                                                `Fundada em: ${
                                                    company.founded || 'N/A'
                                                }`
                                            )
                                        }
                                    >
                                        <BiCalendar className="mr-1 w-5 h-5" />{' '}
                                        Fundada em: {company.founded || 'N/A'}
                                    </p>

                                    <p
                                        className="text-white flex items-center text-sm mt-1 font-montserrat"
                                        onClick={() =>
                                            handleCopyClick(
                                                company.nature || 'N/A'
                                            )
                                        }
                                    >
                                        <BiWorld className="mr-1 w-5 h-5" />
                                        {company.nature}
                                    </p>

                                    <div className="text-white flex items-center text-sm mt-1 font-montserrat mb-2">
                                        <BiBuildingHouse className="mr-1 w-5 h-5" />
                                        <p
                                            className="text-sm"
                                            onClick={() =>
                                                handleCopyClick(
                                                    truncateText(
                                                        company.full_address,
                                                        60
                                                    ) || 'N/A'
                                                )
                                            }
                                        >
                                            {truncateText(
                                                company.full_address,
                                                60
                                            )}
                                        </p>
                                    </div>

                                    <div className="mt-6">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-6">
                                            <IoBusinessOutline className="mr-2 w-7 h-7" />{' '}
                                            Atividades
                                        </h3>
                                        <ul className="space-y-2">
                                            {company.activities
                                                .slice(0, 5)
                                                .map((activity, idx) => (
                                                    <li
                                                        key={idx}
                                                        className="text-sm font-montserrat text-white flex items-center"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                activity ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        <MdCheck
                                                            size={16}
                                                            className="mr-2 text-green-500"
                                                        />
                                                        {truncateText(
                                                            activity,
                                                            56
                                                        )}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex-1 ml-2 -mt-4">
                                    <div>
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-4">
                                            <MdOutlineAssessment className="mr-2 w-7 h-7" />{' '}
                                            Simples Nacional
                                        </h3>

                                        <div className="text-white flex items-center">
                                            <h2 className="text-md font-bold text-white font-montserrat">
                                                Simples:
                                            </h2>
                                            <p
                                                className="text-base font-montserrat rounded-lg px-2 py-1 w-30 text-center font-semibold ml-2"
                                                style={getSimplesOptantStyle(
                                                    company.simples_optant
                                                )}
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.simples_optant ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                {company.simples_optant}
                                            </p>

                                            <h2 className="text-md font-bold text-white font-montserrat ml-10">
                                                Simei:
                                            </h2>
                                            <p
                                                className="text-base font-montserrat rounded-lg px-2 py-1 w-30 text-center font-semibold ml-2"
                                                style={getSimeiOptantStyle(
                                                    company.simei_optant
                                                )}
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.simei_optant ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                {company.simei_optant}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-[500px] ">
                                        <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-6">
                                            <MdOutlineMapsHomeWork className="mr-2 w-7 h-7" />{' '}
                                            Inscrições Estaduais -{' '}
                                            {company.registrations_count}
                                        </h3>

                                        {company.registrations_count === 0 ? (
                                            <div className="flex justify-center items-center w-full h-32">
                                                <MdErrorOutline className="w-16 h-16 text-[#212e4b]" />
                                            </div>
                                        ) : (
                                            <div className="flex overflow-x-auto">
                                                {company.registrations.map(
                                                    (registration, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="rounded-lg p-4 m-2 flex-shrink-0 font-montserrat text-black bg-[#d6d7da] mb-4"
                                                        >
                                                            <p
                                                                className="text-black font mb-2 flex items-center"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Estado da Inscrição: ${registration.state}`
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Estado da
                                                                    Inscrição:
                                                                </span>{' '}
                                                                {renderizarImagemEstado(
                                                                    registration.state
                                                                )}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.state
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Número da Inscrição: ${registration.number}`
                                                                    )
                                                                }
                                                            >
                                                                Número da
                                                                Inscrição:{' '}
                                                                <span className="font-semibold">
                                                                    {
                                                                        registration.number
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p className="mt-1">
                                                                Status da
                                                                Inscrição:{' '}
                                                                <span
                                                                    className={`text-black p-1 rounded-md font-montserrat font-semibold ${
                                                                        registration.enabled ===
                                                                        'Habilitado'
                                                                            ? 'bg-green-500'
                                                                            : 'bg-red-500'
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleCopyClick(
                                                                            `Status da Inscrição: ${registration.enabled}`
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        registration.enabled
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="text-black mt-1 font-montserrat"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Situação: ${registration.status_text}`
                                                                    )
                                                                }
                                                            >
                                                                Situação:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.status_text
                                                                    }
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="text-black mt-1"
                                                                onClick={() =>
                                                                    handleCopyClick(
                                                                        `Tipo: ${registration.type_text}`
                                                                    )
                                                                }
                                                            >
                                                                Tipo:{' '}
                                                                <span className="font-semibold font-montserrat">
                                                                    {
                                                                        registration.type_text
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {loading && (
                        <p className="font-monteserrat text-white">
                            Carregando...
                        </p>
                    )}
                    {error && <p>{error}</p>}
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={prevPage}
                            disabled={page === 1 || loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandLess className="w-6 h-6 " />
                        </button>
                        <button
                            onClick={nextPage}
                            disabled={loading}
                            className="bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] text-white px-4 py-2 rounded"
                        >
                            <MdOutlineExpandMore className="w-6 h-6 " />
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-0">
                        <RiRobot2Line className="mr-2 w-7 h-7" /> Escolha uma
                        ação
                    </h3>
                    <button
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-56 mt-3 border border-transparent"
                    >
                        <MdOutlineFindInPage className="w-6 h-6 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Identificar
                        </span>
                    </button>
                    <button
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-56 mt-3 border border-transparent"
                    >
                        <FaFilter className="ml-1 w-4 h-4 mr-1 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Filtrar
                        </span>
                    </button>
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 mt-28">
                        <IoAnalytics className="mr-2 w-7 h-7" /> Indicadores
                    </h3>

                    <div className="flex mt-2 -mb-4 w-full">
                        {taxIdCount !== null && (
                            <div className="text-lg font-montserrat  text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339] p-3  w-56">
                                {taxIdCount} CNPJs
                            </div>
                        )}
                    </div>

                    <div className="flex mt-6 w-56">
                        {registrationsCount !== null && (
                            <div className="text-lg font-montserrat text-white font-semibold bg-gradient-to-b from-[#0E1621] to-[#192339]  p-3 w-80">
                                {registrationsCount} Ativos
                            </div>
                        )}
                    </div>
                    <div className="flex mt-2 w-56">
                        {activeStatusCount !== null && ( // Renderizar o novo campo activeStatusCount
                            <div className="text-lg font-montserrat text-[#0E1621] font-semibold bg-gradient-to-b from-[#03989E] to-[#57B4B9] p-3 w-80">
                                {activeStatusCount} Potenciais
                            </div>
                        )}
                    </div>
                </div>

                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                Adicionar Monitoramento
                            </h2>
                            <p className="text-gray-300 font-montserrat text-sm mb-6">
                                Selecione abaixo os horários que você deseja
                                monitorar.
                            </p>
                            <div className="relative mb-3">
                                <label
                                    htmlFor="dateTimePicker"
                                    className="block text-white font-semibold mb-2 font-montserrat"
                                >
                                    Selecione a Data e Hora:
                                </label>
                                <div className="flex items-center">
                                    <input
                                        type="datetime-local"
                                        id="dateTimePicker"
                                        value={selectedDateTime}
                                        onChange={e =>
                                            setSelectedDateTime(e.target.value)
                                        }
                                        className="font-montserrat block w-full py-2 px-4 border border-gray-600 bg-[#0E1621] text-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#02989E]/90 focus:border-[#02989E] cursor-pointer"
                                    />
                                </div>
                            </div>

                            <div className="relative mb-10">
                                <label className="block text-gray-400 font-medium mb-2 font-montserrat">
                                    Repetir:
                                </label>
                                <div className="flex items-center space-x-4">
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('semanal')
                                        }
                                        className={`flex items-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'semanal'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Semanalmente
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('mensal')
                                        }
                                        className={`flex items-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'mensal'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Mensalmente
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleRepeatOption('diario')
                                        }
                                        className={`flex items-center px-4 py-2 text-white rounded-lg font-montserrat text-base font-semibold ${
                                            repeatOption === 'diario'
                                                ? 'bg-[#03989E] hover:bg-[#03989E]/90'
                                                : 'bg-gray-600 hover:bg-gray-700'
                                        }`}
                                    >
                                        Diariamente
                                    </button>
                                </div>
                            </div>

                            {statusMessage && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat -mt-5">
                                    {statusMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpen(false)}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>

                                <button
                                    onClick={handleSearchClick}
                                    disabled={!isSaveEnabled}
                                    className={`flex items-center px-6 py-3 text-white ${
                                        isSaveEnabled
                                            ? 'bg-blue-600 hover:bg-blue-700'
                                            : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                    } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenAlert && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                Adicionar Alertas
                            </h2>
                            <p className="text-gray-300 font-montserrat text-sm mb-6">
                                Adicione Alertas e Monitore sua base de clientes
                            </p>
                            <div className="relative mb-3"> Alertas </div>

                            {statusMessage && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat -mt-5">
                                    {statusMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenAlert(false)}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>

                                <button
                                    onClick={handleSearchClick}
                                    disabled={!isSaveEnabled}
                                    className={`flex items-center px-6 py-3 text-white ${
                                        isSaveEnabled
                                            ? 'bg-blue-600 hover:bg-blue-700'
                                            : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                    } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenDownload && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                Fazer Download
                            </h2>
                            <p className="text-gray-300 font-montserrat text-sm mb-6">
                                Faça o download dos dados
                            </p>
                            <div className="relative mb-3">
                                <label
                                    htmlFor="startDatePicker"
                                    className="block text-white font-semibold mb-2 font-montserrat"
                                >
                                    Selecione a Data de Início:
                                </label>
                                <div className="flex items-center mb-8">
                                    <input
                                        type="date"
                                        id="startDatePicker"
                                        value={selectedStartDate}
                                        onChange={e =>
                                            setSelectedStartDate(e.target.value)
                                        }
                                        className="font-montserrat block w-full py-2 px-4 border border-gray-600 bg-[#0E1621] text-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#02989E]/90 focus:border-[#02989E] cursor-pointer"
                                    />
                                </div>
                            </div>

                            <div className="relative mb-4 flex items-center -mt-4">
                                <input
                                    type="checkbox"
                                    id="enableEndDate"
                                    checked={isEndDateEnabled}
                                    onChange={e =>
                                        setIsEndDateEnabled(e.target.checked)
                                    }
                                    className="mr-1"
                                />
                                <label
                                    htmlFor="enableEndDate"
                                    className="text-white text-sm font-normal font-montserrat"
                                >
                                    Deseja selecionar uma data final?
                                </label>
                            </div>

                            {isEndDateEnabled && (
                                <div className="relative mb-3">
                                    <label
                                        htmlFor="endDatePicker"
                                        className="block text-white font-semibold mb-2 font-montserrat"
                                    >
                                        Selecione a Data de Fim:
                                    </label>
                                    <div className="flex items-center mb-8">
                                        <input
                                            type="date"
                                            id="endDatePicker"
                                            value={selectedEndDate}
                                            onChange={e =>
                                                setSelectedEndDate(
                                                    e.target.value
                                                )
                                            }
                                            className="font-montserrat block w-full py-2 px-4 border border-gray-600 bg-[#0E1621] text-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#02989E]/90 focus:border-[#02989E] cursor-pointer"
                                        />
                                    </div>
                                </div>
                            )}

                            {statusMessage && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat mt-0">
                                    {statusMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() =>
                                        setIsPopupOpenDownload(false)
                                    }
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleDownloadClick}
                                    disabled={!selectedStartDate} // Desabilita o botão se o campo de data de início estiver vazio
                                    className={`flex items-center px-6 py-3 text-white ${
                                        selectedStartDate // Adiciona a classe condicional apenas se o campo de data de início estiver preenchido
                                            ? 'bg-blue-600 hover:bg-blue-700'
                                            : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                    } rounded-lg font-montserrat text-base font-semibold`}
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpenArchive && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                                Arquivar Pasta
                            </h2>
                            <p className="text-gray-300 font-montserrat text-xl mb-4">
                                Tem certeza que deseja arquivar a pasta?
                            </p>

                            {statusMessage && (
                                <div className="text-white font-semibold mb-4 text-base font-montserrat">
                                    {statusMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    onClick={() => setIsPopupOpenArchive(false)}
                                    className="flex items-center px-6 py-3 text-black bg-gray-300 hover:bg-gray-400 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleArchiveClick}
                                    className={`flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold`}
                                >
                                    <FiArchive className="w-6 h-6 mr-2" />
                                    Arquivar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {loading && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                            <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                            <span className="text-base text-gray-700 font-montserrat">
                                Pesquisando...
                            </span>
                        </div>
                    </div>
                )}
                {copiedText && (
                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                        {copiedText}
                    </div>
                )}

                {loadingCompanyData && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                            <FaSpinner className="w-6 h-6 text-[#00B3BE] animate-spin" />
                            <span className="text-base text-gray-700 font-montserrat">
                                Carregando...
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
