import { LandingNavbar } from '../components/Homepage/landing-navbar';
import { MaHelpFeedbackPage } from '../components/MarketAnalysis/ma-helpfeedback';

const HelpFeedBackCompaniesPage = () => {
    return (
        <main className="h-screen bg-[#111827] overflow-auto">
            <div className="flex flex-col h-full">
                <LandingNavbar />
                <MaHelpFeedbackPage />
            </div>
        </main>
    );
};

export default HelpFeedBackCompaniesPage;
