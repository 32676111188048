import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa o locale para português

import axios from 'axios';

import Breadcrumb from '../Global/Breadcrumb';

import { MdOutlineFileDownload } from 'react-icons/md';
import { IoAnalytics } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface Results {
    message: string;
    taxId: string;
    cpf: string;
    new_situation: string;
    savedSituation: string;
    taxId_situation: string;
    taxId_new_situation: string;
    new_folder: string;
    changed: boolean;
    type: string;
    state: string;
    number: string;
    mostRecentRegistrationEnabled: string;
    matchingRegistrationEnabled: string;
    status: string;
    _id: string;
}

interface Item {
    _id: string;
    message: string;
    totalDocumentsProcessed: number;
    folder: string;
    user_id: string;
    monitoring: string;
    visiting: string;
    results: Results;
    active: boolean;
    dataCreated: string;
    __v: number;
    formattedDate: string;
    time: string;
    type: string;
}

interface GroupedData {
    dateCreated: string;
    items: Item[];
}

interface NotificationsResponse {
    type: string;
    totalDocumentsProcessed: number;
    groupedData: GroupedData[];
    message: string;
}

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

export const MaNotifications = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [companyData, setCompanyData] =
        useState<NotificationsResponse | null>(null);
    const isSaveEnabled = selectedDateTime !== '';
    const [error, setError] = useState<string | null>(null); // Ajuste de tipo

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis/notifications', label: 'Notificações' }, // Adiciona a rota aqui
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const storedUserId = localStorage.getItem('userId');

            if (storedUserId) {
                try {
                    const response = await axios.get<NotificationsResponse>(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/notification-combined/${JSON.parse(
                            storedUserId
                        )}`
                    );
                    console.log('Fetched data:', response.data);
                    setCompanyData(response.data);
                } catch (error) {
                    console.error('Error fetching user information:', error);
                    setError('Erro ao buscar informações');
                } finally {
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDownloadExcel = async () => {
        console.log('Download state:', companyData);

        if (!companyData || companyData.groupedData.length === 0) {
            console.log('Nenhum dado para exportar.');
            return;
        }

        // Mapeia os dados dos itens para linhas do Excel
        const rows = companyData.groupedData.flatMap(group =>
            group.items.map(item => ({
                'Data de Monitoramento': group.dateCreated,
                'Pasta de Origem': item.folder,
                'Log Message': item.message,
                Tipo: item.results.type,
                'Frequencia de Monitoramento': item.monitoring,
                Visita: item.visiting,
                CNPJ: item.results.taxId,
                'Status na RF (Antes da Atualizacao)':
                    item.results.taxId_situation,
                'Status na RF (Apos Atualizacao)':
                    item.results.taxId_new_situation,
                'UF da IE': item.results.state,
                'Numero da IE': item.results.number,
                'Status da IE (Antes da Atualizacao)':
                    item.results.mostRecentRegistrationEnabled,
                'Status da IE (Apos Atualizacao)':
                    item.results.matchingRegistrationEnabled,
                'Status do CPF na RF (Antes da Atualizacao)':
                    item.results.savedSituation,
                'Status do CPF na RF (Apos Atualizacao)':
                    item.results.new_situation,
                'Houve Alteracao': item.results.changed ? 'Sim' : 'Não',
                'Pasta de Destino': item.results.new_folder,
                CPF: item.results.cpf,
            }))
        );

        console.log(rows);

        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Informações');

        const fileName = `Monitoramento.xlsx`;

        XLSX.writeFile(workbook, fileName, { compression: true });
    };

    // Função para formatar a data
    // Função para formatar a data
    const formatDate = (date: string) => {
        // Tenta converter a string no formato 'DD-MM-YYYY HH:mm:ss'
        const momentDate = moment(date, 'DD-MM-YYYY HH:mm:ss', true); // 'true' para modo estrito
        if (!momentDate.isValid()) {
            console.error('Data inválida:', date);
            return 'Data inválida';
        }
        return momentDate.locale('pt-br').format('D [de] MMMM [de] YYYY');
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-5">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 mt-1 ">
                <div
                    className="w-[1100px] h-[550px] p-4 mr-6 -ml-8 rounded-xl overflow-y-auto"
                    style={{ scrollbarColor: '#6B7280 #4B5563' }}
                >
                    <div className="text-white ml-4 font-bold">
                        <span className="font-montserrat text-2xl">
                            Monitoramentos Realizados
                        </span>
                    </div>
                    <div className="p-4 min-h-screen">
                        {companyData?.groupedData.map(group => (
                            <div
                                key={group.dateCreated}
                                className="mb-4 p-4 bg-gradient-to-b from-[#0E1621] to-[#192339] border border-gray-800 rounded-lg shadow-sm"
                            >
                                <div className="flex items-center justify-between mb-3">
                                    <h2 className="text-2xl font-semibold text-white font-montserrat">
                                        {formatDate(group.dateCreated)}
                                    </h2>
                                </div>
                                {group.items.map(item => (
                                    <div
                                        key={item._id}
                                        className="mb-3 p-3 bg-[#192339] border border-gray-800 rounded-lg text-lg"
                                    >
                                        <p className="font-bold text-gray-300 font-montserrat mb-2">
                                            {item.message}
                                        </p>
                                        <div className="space-y-1 mb-3 text-sm text-white font-montserrat">
                                            <div className="flex justify-between">
                                                <span>Pasta de Origem:</span>
                                                <span>{item.folder}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Monitoramento:</span>
                                                <span>{item.monitoring}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Visita:</span>
                                                <span>{item.visiting}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Tipo:</span>
                                                <span>{item.type}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>
                                                    Data de Monitoramento:
                                                </span>
                                                <span>
                                                    {item.formattedDate}
                                                </span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Houve Alteração:</span>
                                                <span
                                                    className={`font-montserrat font-extrabold text-sm ${
                                                        item.results.changed
                                                            ? 'text-green-600'
                                                            : 'text-red-600'
                                                    }`}
                                                >
                                                    {item.results.changed
                                                        ? 'Sim'
                                                        : 'Não'}
                                                </span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Pasta de Destino:</span>
                                                <span>
                                                    {item.results.new_folder}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {loading && (
                            <p className="font-monteserrat text-white text-lg">
                                Carregando...
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3 h-[520px]">
                    <h3 className="flex items-center text-xl font-semibold mb-3 text-white font-montserrat border-b border-gray-700 pb-2 -mt-5">
                        <RiRobot2Line className="mr-2 w-7 h-7" /> Escolha uma
                        ação
                    </h3>
                    <button
                        onClick={() => {
                            handleDownloadExcel();
                        }}
                        className="flex items-center text-white rounded-r-lg font-montserrat text-sm font-normal p-2 px-10 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] focus:outline-none w-56 mt-1 border border-transparent"
                    >
                        <MdOutlineFileDownload className="w-6 h-6 text-gray-300 font-bold" />
                        <span className="font-montserrat ml-2 font-semibold text-lg">
                            Download
                        </span>
                    </button>
                    <h3 className="flex items-center text-xl font-semibold mb-3 mt-10 text-white font-montserrat border-b border-gray-700 pb-2">
                        <IoAnalytics className="mr-2 w-7 h-7" /> Indicadores
                    </h3>
                </div>
            </div>
        </div>
    );
};
