import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import Sidebar from '../Global/Sidebar';
import Breadcrumb from '../Global/Breadcrumb';

import { LuLayoutDashboard } from 'react-icons/lu';
import { LuMonitorCheck } from 'react-icons/lu';
import { IoAlertCircleOutline, IoAnalytics } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoAdd } from 'react-icons/io5';
import { FaRegFolder } from 'react-icons/fa6';
import { FiActivity } from 'react-icons/fi';
import { FiClock } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { HiServer } from 'react-icons/hi';
import { BsQuestionCircle } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import { GoPlusCircle } from 'react-icons/go';
import { FaRegFolderOpen } from 'react-icons/fa';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { FaRegCopy } from 'react-icons/fa6';
import { RiRobot2Line } from 'react-icons/ri';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdOutlineAddAlert } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { getUserFromLocalStorage } from '../../services';

interface InvoiceResponse {
    _id: string;
    count: string;
    createdAt: string;
}

interface YourScheduleType {
    _id: string;
    date: string;
    time: string;
    folder: string;
    repeat: string;
    active: string;
}

interface AlertItem {
    id: number;
    alert: string;
    type: 'success' | 'error' | 'warning';
    date?: string;
}

interface AlertScheduleType {
    _id: string;
    folder: string;
    date: string;
    time: string;
}

export const MaHero = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [folders, setFolders] = useState<string[]>([]);
    const [alerts, setAlerts] = useState<AlertItem[]>([]);
    const [kpis, setKpis] = useState({
        totalUniqueFolders: 0,
        totalDocuments: 0,
        activeDocuments: 0,
        percentActive: 0,
    }); // Inicialize kpis com as propriedades esperadas e valores padrão
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [folderName, setFolderName] = useState<string>('');
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false); // Novo estado para controlar se o botão "Salvar" está habilitado
    const breadcrumbItems = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { label: 'Análise de Mercado - CNPJ' },
        { label: 'Dashboard' },
    ];
    const [isHovered, setIsHovered] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [results, setResults] = useState([]);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const companiesPerPage = 4;
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [scheduleDataKpis, setScheduleDataKpis] = useState<
        AlertScheduleType[]
    >([]);

    const [copiedText, setCopiedText] = useState('');

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
    const [currentAlert, setCurrentAlert] = useState<AlertItem | null>(null);
    const [availability, setAvailability] = useState<{
        status: string;
        data: string;
    } | null>(null);

    function normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const navigate = useNavigate();

    useEffect(() => {
        // Filtra as empresas com base no texto de pesquisa
        const filterCompanies = () => {
            if (!searchFilter.trim()) {
                // Se o filtro de pesquisa estiver vazio, retorne todas as empresas
                return allCompanies;
            }
            return allCompanies.filter(company => {
                return company._id
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase());
            });
        };

        const filtered = filterCompanies();
        setFilteredCompanies(filtered);
    }, [searchFilter, allCompanies]); // Depende do searchFilter e allCompanies para reagir a mudanças

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/folders/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )

                .then(response => {
                    console.log(response.data);
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const bearerToken =
            'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/market-intelligence/folders-data/${JSON.parse(
                    storedUserId!
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        setIsSaveEnabled(folderName.trim() !== '' && searchTerm.trim() !== '');
    }, [folderName, searchTerm]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/availability/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    const { status, data } = response.data;
                    setAvailability({ status, data });
                })
                .catch(error => {
                    console.error('Error fetching availability:', error);
                    // Handle error if needed
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/kpis/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    console.log(response.data);
                    setKpis(response.data); // Defina os KPIs recebidos do servidor
                })
                .catch(error => {
                    console.error('Error fetching kpis:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/schedule-data/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    setScheduleDataKpis(response.data); // Defina os dados do cronograma recebidos do servidor
                })
                .catch(error => {
                    console.error('Error fetching schedule data:', error);
                });
        }
    }, []);

    useEffect(() => {
        // Atualiza o alerta atual a cada 5 segundos
        const interval = setInterval(() => {
            setCurrentAlertIndex(prevIndex =>
                prevIndex === alerts.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        setCurrentAlert(alerts[currentAlertIndex]);

        return () => clearInterval(interval);
    }, [alerts, currentAlertIndex]);

    useEffect(() => {
        // Atualiza o alerta atual a cada 5 segundos
        const interval = setInterval(() => {
            setCurrentAlertIndex(prevIndex =>
                prevIndex === alerts.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        setCurrentAlert(alerts[currentAlertIndex]);

        return () => clearInterval(interval);
    }, [alerts, currentAlertIndex]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/market-intelligence/alerts/${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    setAlerts(response.data); // Defina os dados do cronograma recebidos do servidor
                })
                .catch(error => {
                    console.error('Error fetching alerts data:', error);
                });
        }
    }, []);

    const handleDownload = () => {};

    const handleConfirm = async () => {};

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handlePasteClick = () => {
        if (textareaRef.current) {
            navigator.clipboard.readText().then(pastedText => {
                setSearchTerm(pastedText);
            });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;
        const filteredText = inputText.replace(/[^a-zA-Z0-9\s]/g, ''); // Filtra apenas letras, números e espaços

        if (inputText !== filteredText) {
            setErrorMessage('Caracteres especiais não são permitidos.');
        } else {
            setErrorMessage('');
        }

        setFolderName(filteredText);
        setIsFolderSelected(filteredText.trim() !== '');
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();
        setLoading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc'; // Function to get the Bearer token

            // Tratamento para CNPJ
            const sanitizedData = searchTerm
                .split(/\s+/) // Dividir por espaços em branco (espaço, tabulação, quebra de linha, etc.)
                .map(cnpj => cnpj.replace(/\D/g, '')) // Remover caracteres não numéricos
                .filter(cnpj => cnpj); // Filtrar valores vazios

            const formattedData = sanitizedData.map(data => {
                // Formato CNPJ
                return data.replace(
                    /^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d{2})$/,
                    '$1$2$3$4$5'
                );
            });

            const endpointCNPJ = `${REACT_APP_SERVER_URL}/api/market-intelligence/company`;

            try {
                const response = await axios.get(endpointCNPJ, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    params: {
                        data: formattedData.join(','),
                        user_id: userId,
                        folder: folderName,
                        type_selected: 'CNPJ', // Passa o tipo selecionado (CNPJ)
                    },
                });
                console.log('Response:', response.data);
            } catch (error) {
                console.error('Request error:', error);
            } finally {
                setLoading(false);
                setIsPopupOpen(false); // Fecha o popup
                window.location.replace('/market-analysis'); // Navega e recarrega a página
            }
        } else {
            setLoading(false);
            setIsPopupOpen(false); // Fecha o popup
            window.location.replace('/market-analysis'); // Navega e recarrega a página
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
                <div className="flex mt-2">
                    <div className="w-64 h-full p-4 -ml-4 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <Sidebar>
                            <Link
                                to="/market-analysis"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm -mt-2"
                            >
                                <LuLayoutDashboard className="w-5 h-5 mr-2" />
                                Dashboard
                            </Link>
                            <Link
                                to="/market-analysis/monitoring"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm"
                            >
                                <LuMonitorCheck className="w-5 h-5 mr-2" />
                                Monitoramento
                            </Link>
                            <Link
                                to="/market-analysis/alerts"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 mb-2 font-montserrat text-sm"
                            >
                                <MdOutlineAddAlert className="w-5 h-5 mr-2" />
                                Alertas
                            </Link>
                            <Link
                                to="/market-analysis/datastore"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                            >
                                <IoAnalytics className="w-5 h-5 mr-2" />
                                Datastore
                            </Link>
                            <Link
                                to="/market-analysis/archived"
                                className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                            >
                                <FiTrash2 className="w-5 h-5 mr-2" />
                                Arquivadas
                            </Link>
                        </Sidebar>
                    </div>
                    <div className="w-[1100px] h-72 p-4 ml-5 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <div className="ml-[900px] -mt-20">
                            <button
                                onClick={() => {
                                    setIsPopupOpen(true);
                                    setSelectedFiles(null); // Limpa a lista de arquivos selecionados
                                    setFolderName(''); // Limpa o nome da pasta
                                    setShowNewFolderInput(false); // Reseta a opção de criar nova pasta
                                }}
                                className="flex items-center text-white rounded-lg font-montserrat text-base font-semibold p-2 px-10 hover:from-blue-700 hover:to-blue-600 focus:outline-none relative"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{
                                    transition:
                                        'background-color 0.3s ease-in-out',
                                    backgroundColor: isHovered
                                        ? '#04BFBF'
                                        : '#03989E',
                                }}
                            >
                                <IoAdd className="w-5 h-5 mt-0" />
                                <span className="font-montserrat">
                                    Adicionar
                                </span>
                                {isHovered && (
                                    <div
                                        className="absolute w-full h-full top-0 left-0 bg-[#BDDEE0] opacity-25 rounded-full"
                                        style={{
                                            animation: 'pulse 1.5s infinite',
                                        }}
                                    ></div>
                                )}
                            </button>
                        </div>
                        <div className="mt-10 flex items-center justify-between">
                            <div className="flex items-center mb-2">
                                {currentFilteredCompanies.length >= 1 && (
                                    <>
                                        <div className="flex items-center text-white rounded-lg p-2">
                                            <FaRegFolder className="w-4 h-4 mr-2" />
                                            <span className="font-montserrat text-base">
                                                Recentes
                                            </span>
                                        </div>
                                        <div className="text-white">
                                            <input
                                                type="text"
                                                id="search"
                                                value={searchFilter}
                                                onChange={e =>
                                                    setSearchFilter(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Pesquise pelo nome da pasta..."
                                                className="w-[400px] ml-[480px] h-[30px] px-4 py-2 text-xs rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] font-montserrat text-white"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {currentFilteredCompanies.length >= 1 && (
                                <div className="-mt-1 -ml-2 flex items-center text-gray-400 text-xs font-montserrat">
                                    <span className="mx-2 italic">
                                        Página {currentPage}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            {currentFilteredCompanies.length >= 1 && (
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={`${
                                        currentPage === 1
                                            ? 'cursor-not-allowed'
                                            : 'hover:text-gray-300'
                                    }`}
                                >
                                    <FaAngleDoubleLeft className="w-6 h-6 mr-2 text-white opacity-30" />
                                </button>
                            )}
                            <div
                                className="container mx-auto p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full"
                                id="companies"
                            >
                                {currentFilteredCompanies.length === 0 ? (
                                    <div className="-mt-8 flex items-center justify-between">
                                        <div className="flex items-center text-white rounded-lg p-2">
                                            <FaRegFolder className="w-4 h-4 mr-2" />
                                            <span className="font-montserrat text-base">
                                                Recentes
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    currentFilteredCompanies
                                        .filter(
                                            company =>
                                                selectedFolder === '' ||
                                                company._id === selectedFolder
                                        )
                                        .map(company => (
                                            <div
                                                key={company._id}
                                                className="bg-[#192339] text-white rounded-lg p-4 cursor-pointer h-40 transition-transform hover:scale-105"
                                                onClick={() =>
                                                    navigate(
                                                        `/market-analysis/folder/${normalizeString(
                                                            company._id
                                                        )}`
                                                    )
                                                }
                                            >
                                                <div className="flex justify-center items-center mt-3">
                                                    <FaRegFolderOpen className="text-[#03989E] w-12 h-12" />
                                                    <p
                                                        className="text-base font-bold font-montserrat ml-4 mt-2 truncate max-w-[200px] break-words"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company._id ||
                                                                    'N/A'
                                                            )
                                                        }
                                                        title={company._id} // Tooltip ao passar o mouse
                                                    >
                                                        {company._id || 'N/A'}
                                                    </p>
                                                </div>
                                                <hr className="border-[#57B4B9] border-opacity-25 my-3" />
                                                <div className="mt-1">
                                                    <p
                                                        className="font-normal text-sm font-montserrat truncate mt-0 text-gray-400"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.count?.toString() ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.count !==
                                                        undefined
                                                            ? `${
                                                                  company.count
                                                              } ${
                                                                  parseInt(
                                                                      company.count
                                                                  ) === 1
                                                                      ? 'CNPJ'
                                                                      : 'CNPJs'
                                                              }`
                                                            : 'N/A'}
                                                    </p>
                                                    <p
                                                        className="text-[#B3D8D9] text-sm font-montserrat font-light"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.createdAt ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.createdAt ||
                                                            'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                )}
                                {copiedText && (
                                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                        {copiedText}
                                    </div>
                                )}
                            </div>
                            {currentFilteredCompanies.length >= 1 && (
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="font-montserrat text-white text-sm opacity-30"
                                >
                                    <FaAngleDoubleRight className="w-6 h-6 ml-2" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className=" w-56 -mt-4 p-4 -ml-4 rounded-xl shadow-xl border-2 border-dotted border-gray-600 flex items-center cursor-pointer transition-colors duration-300 ease-in-out">
                    <div className="max-w-xs p-0 rounded-xl shadow-xl ">
                        <div className="flex items-center">
                            <h2 className="text-gray-300 font-semibold text-base font-montserrat">
                                Status da IA
                            </h2>
                        </div>
                        <div className="mt-1">
                            {availability ? (
                                <>
                                    <p className="">
                                        <span
                                            className={`inline-flex items-center w-48 -ml-1 ${
                                                availability.status === 'Online'
                                                    ? 'bg-green-500'
                                                    : 'bg-red-500'
                                            } text-[#111827] px-2 py-1 rounded-lg font-montserrat font-bold `}
                                        >
                                            {availability.status ===
                                            'Online' ? (
                                                <FaCheckCircle className="mr-1" />
                                            ) : (
                                                <FaTimesCircle className="mr-1" />
                                            )}
                                            {availability.status}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <p className="text-gray-400 font-montserrat">
                                    Carregando...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-0 w-60 p-4 h-32 -ml-4 ">
                    <Link
                        to="/market-analysis/analytics"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                    >
                        <TbReportAnalytics className="w-5 h-5 mr-2" />
                        Analytics
                    </Link>
                    <Link
                        to="/market-analysis/integration"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm mb-2"
                    >
                        <HiServer className="w-5 h-5 mr-2" />
                        Integração
                    </Link>
                    <Link
                        to="/market-analysis/help-feedback"
                        className="flex items-center text-white hover:text-white hover:bg-[#2a3e5e] rounded-lg p-2 font-montserrat text-sm"
                    >
                        <BsQuestionCircle className="w-5 h-5 mr-2" />
                        Help e Feedback
                    </Link>
                </div>
            </div>
            <div className="w-[1100px] h-[195px] p-4 ml-[268px] -mt-52 rounded-xl shadow-xl bg-gradient-to-b from-[#192339] to-[#0E1621]">
                <div className="flex items-center mb-2">
                    <div className="flex items-center text-white rounded-lg p-2 -mt-2">
                        <FiActivity className="w-4 h-4 mr-2" />
                        <span className="font-montserrat text-base">KPIs</span>
                    </div>
                </div>
                {/* Grid de KPIs */}
                <div className="container max-w-6xl px-5 mx-auto my-0 mt-1 mb-2">
                    <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-lg text-white font-montserrat">
                                Pastas
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-3xl font-bold text-white font-montserrat">
                                    {kpis.totalUniqueFolders}{' '}
                                    {/* Exiba totalUniqueFolders */}
                                </div>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-lg text-white font-montserrat">
                                CNPJS
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-3xl font-bold text-white font-montserrat">
                                    {kpis.totalDocuments}{' '}
                                    {/* Exiba totalDocuments */}
                                </div>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-lg text-white font-montserrat">
                                Ativos
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-3xl font-bold text-white font-montserrat">
                                    {kpis.activeDocuments}{' '}
                                    {/* Exiba activeDocuments */}
                                </div>
                            </div>
                        </div>
                        {/* Adicione mais KPIs aqui */}
                        <div className="p-5 bg-[#192339] rounded shadow-md">
                            <div className="text-lg text-white font-montserrat">
                                % de Ativos
                            </div>
                            <div className="flex items-center pt-1">
                                <div className="text-3xl font-bold text-white font-montserrat">
                                    {kpis.percentActive !== null
                                        ? `${kpis.percentActive.toFixed(2)}%`
                                        : 'N/A'}
                                    {/* Check if percentActive is not null before calling toFixed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                            Adicione os dados do seu cliente
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-6">
                            Cole os números de CNPJ no campo abaixo, separados
                            por espaços ou quebras de linha.
                        </p>
                        <div className="flex items-center mb-4 mt-3">
                            <textarea
                                ref={textareaRef}
                                id="searchTerm"
                                name="searchTerm"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                className="flex-1 w-72 h-56 p-2 bg-[#1f2937] border-2 border-dashed border-gray-400 rounded-lg cursor-pointer hover:border-[#00B3BE] transition focus:outline-none font-montserrat text-white"
                                placeholder="Cole os CNPJs aqui"
                                style={{ resize: 'none' }}
                            ></textarea>

                            <button
                                onClick={handlePasteClick}
                                className="ml-4 bg-white text-black px-2 py-2 rounded hover:bg-gray-100 focus:outline-none focus:ring focus:border-blue-300 font-montserrat flex items-center"
                            >
                                <FaRegCopy
                                    size={14}
                                    className="font-montserrat"
                                />
                            </button>
                        </div>
                        <div className="">
                            <label
                                htmlFor="folderInput"
                                className="block mb-2 text-white font-montserrat text-lg font-bold"
                            >
                                Selecione uma pasta existente
                            </label>
                            <select
                                id="existingFolders"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat focus:border-gray-300 focus:ring focus:ring-gray-200"
                                onChange={e => {
                                    setFolderName(e.target.value);
                                    setIsFolderSelected(e.target.value !== '');
                                }}
                            >
                                <option value="">
                                    Selecione uma pasta existente
                                </option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>

                            <div className="flex items-center">
                                <button
                                    className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                    onClick={() =>
                                        setShowNewFolderInput(
                                            !showNewFolderInput
                                        )
                                    }
                                >
                                    <GoPlusCircle className="w-6 h-6 text-[#03989E]" />
                                </button>
                                <span className="ml-2 font-montserrat text-white text-sm font-medium">
                                    ou crie uma nova pasta
                                </span>
                            </div>

                            {showNewFolderInput && (
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        id="folderInput"
                                        className="w-full p-3 border border-gray-400 rounded-lg focus:outline-none focus:border-gray-300 focus:ring focus:ring-gray-200 font-montserrat"
                                        placeholder="Digite aqui o nome da pasta"
                                        value={folderName}
                                        onChange={handleInputChange}
                                    />
                                    {errorMessage && (
                                        <p className="text-red-500 italic mt-2 font-montserrat text-base">
                                            {errorMessage}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between mt-10">
                            <button
                                onClick={() => setIsPopupOpen(false)}
                                className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>

                            <button
                                onClick={handleSearchClick}
                                disabled={!isSaveEnabled}
                                className={`flex items-center px-6 py-3 text-white ${
                                    isSaveEnabled
                                        ? 'bg-blue-600 hover:bg-blue-700'
                                        : 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                            >
                                <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                Salvar
                            </button>
                        </div>
                        {loading && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                    <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                    <span className="text-base text-gray-700 font-montserrat">
                                        Salvando...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
