import React, { useState, useEffect } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Breadcrumb from '../Global/Breadcrumb';
import { REACT_APP_SERVER_URL } from '../../config/keys';

interface BreadcrumbItem {
    to?: string;
    icon?: React.ElementType;
    label: string;
}

interface Schedule {
    id: number;
    folder: string;
    monitoring: string;
    visiting: string;
    dataCreated: string;
    type: string;
    totalDocumentsProcessed: string;
}

const getTypeBgColor = (type: string) => {
    switch (type) {
        case 'CCC':
            return 'bg-gray-300'; // Cinza claro para CCC
        case 'RF':
            return 'bg-[#192339]'; // Bege claro para RF
        default:
            return 'bg-gray-200'; // Pêssego claro como cor padrão
    }
};

export const MaMonitoring = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [schedulesPerPage] = useState(10);
    const [totalSchedules, setTotalSchedules] = useState(0);
    const [activeTab, setActiveTab] = useState<'monitoring' | 'ccc'>('monitoring');

    const breadcrumbItems: BreadcrumbItem[] = [
        { to: '/', icon: IoHomeOutline, label: 'Página Inicial' },
        { to: '/market-analysis', label: 'Análise de Mercado' },
        { label: 'Monitoramento' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const storedUserId = localStorage.getItem('userId');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            if (storedUserId) {
                try {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/market-intelligence/monitoring-data/${JSON.parse(
                            storedUserId
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        }
                    );
                    const data = response.data;
                    setSchedules(data);
                    setTotalSchedules(data.length);
                    setLoading(false);
                } catch (error) {
                    console.error(
                        'Error fetching inactive folders data:',
                        error
                    );
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [id]);

    // Paginação
    const indexOfLastSchedule = currentPage * schedulesPerPage;
    const indexOfFirstSchedule = indexOfLastSchedule - schedulesPerPage;
    const currentSchedules = schedules.slice(indexOfFirstSchedule, indexOfLastSchedule);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalSchedules / schedulesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="bg-[#111827] py-4 px-6">
            <div className="bg-[#111827] py-4 px-6 mb-1">
                <div className="flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="flex ml-10 -mt-6">
                <div className="flex ml-10 mt-1"></div>
            </div>

            <div className="w-full px-4 mt-6">
                <div className="bg-gradient-to-b from-[#0E1621] to-[#192339] shadow-md rounded">
                    <div className="flex justify-center">
                        <div className="w-full">
                            <ul className="flex border-b border-[#2a3e5e]">
                                <li
                                    className={`flex-1 text-center ${activeTab === 'monitoring' ? 'bg-gray-700' : 'text-[#A0AEC0]'} transition-colors`}
                                >
                                    <button
                                        className="py-2 px-4 w-full flex items-center justify-center font-montserrat text-white"
                                        onClick={() => setActiveTab('monitoring')}
                                    >
                                        Tabela de Monitoramento
                                    </button>
                                </li>

                                <li
                                    className={`flex-1 text-center ${activeTab === 'ccc' ? 'bg-gray-700' : 'text-[#A0AEC0]'} transition-colors`}
                                >
                                    <button
                                        className="py-2 px-4 w-full font-montserrat text-white"
                                        onClick={() => setActiveTab('ccc')}
                                    >
                                        Gráficos
                                    </button>
                                </li>
                            </ul>
                            <div className="p-4">
                                <div>
                                    {activeTab === 'monitoring' && (
                                        <table className="min-w-full bg-gradient-to-b from-[#0E1621] to-[#192339] shadow-md rounded table-fixed mt-4">
                                            <thead className="sticky top-0 bg-[#0E1621] z-10">
                                                <tr className="border-b-2 border-[#2a3e5e] text-base">
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        Pasta
                                                    </th>
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        Monitoramento
                                                    </th>
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        Visita
                                                    </th>
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        Última Atualização
                                                    </th>
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        Tipo
                                                    </th>
                                                    <th className="text-left py-3 px-4 font-montserrat text-white w-1/5">
                                                        CNPJs
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <tr>
                                                        <td
                                                            colSpan={5}
                                                            className="text-center py-4 text-white font-montserrat"
                                                        >
                                                            Carregando...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    currentSchedules.map(schedule => (
                                                        <tr
                                                            key={schedule.id}
                                                            className={`border-b border-[#2a3e5e] text-sm ${getTypeBgColor(
                                                                schedule.type
                                                            )}`}
                                                        >
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.folder}
                                                            </td>
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.monitoring}
                                                            </td>
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.visiting}
                                                            </td>
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.dataCreated}
                                                            </td>
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.type}
                                                            </td>
                                                            <td className="py-3 px-4 font-montserrat text-white w-1/5 text-base">
                                                                {schedule.totalDocumentsProcessed}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    )}

                                    {activeTab === 'ccc' && (
                                        <div className="p-4 text-white font-montserrat">
                                            {/* Aqui você pode colocar a tabela ou conteúdo específico para "Cadastro Centralizado de Contribuinte (CCC)" */}
                                            <p>Em Breve</p>
                                        </div>
                                    )}

                                    {activeTab === 'monitoring' && (
                                        <div className="flex justify-between items-center mt-4 font-montserrat">
                                            <button
                                                className="bg-gray-800 text-white py-2 px-4 rounded"
                                                disabled={currentPage === 1}
                                                onClick={() => setCurrentPage(currentPage - 1)}
                                            >
                                                Anterior
                                            </button>
                                            <div className="text-white">
                                                Página {currentPage} de {pageNumbers.length}
                                            </div>
                                            <button
                                                className="bg-gray-800 text-white py-2 px-4 rounded"
                                                disabled={currentPage === pageNumbers.length}
                                                onClick={() => setCurrentPage(currentPage + 1)}
                                            >
                                                Próxima
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
