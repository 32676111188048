import { useState, useEffect } from 'react';
import axios from 'axios';
import { Disclosure } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import {
    HomeIcon,
    UsersIcon,
    NewspaperIcon,
    ChartBarIcon,
    BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import { PiGraphThin } from 'react-icons/pi';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logoNeuronLab from '../../assets/neuron.png';

import { REACT_APP_SERVER_URL } from '../../config/keys';

interface UserInfo {
    name: string;
    email: string;
    imageUrl: string;
}

const navigation = [
    { name: 'Dashboard', href: '/', current: true, icon: HomeIcon },
    { name: 'CNPJ', href: '/cnpj', current: false, icon: BuildingOfficeIcon },
    { name: 'CPF', href: '/cpf', current: false, icon: UsersIcon },
    { name: 'Datastore', href: '', current: false, icon: PiGraphThin },
    { name: 'Notícias', href: '', current: false, icon: NewspaperIcon },
    { name: 'Analytics', href: '', current: false, icon: ChartBarIcon },
];

const NavBar = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(
        () => JSON.parse(localStorage.getItem('userInfo') || 'null')
    );
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            const userId = JSON.parse(storedUserId);

            axios
                .get(`${REACT_APP_SERVER_URL}/api/auth/users/navbar/${userId}`)
                .then(response => {
                    const fetchedUser = {
                        name: response.data.name,
                        email: response.data.email,
                        imageUrl:
                            response.data.profileImageURL ||
                            'https://via.placeholder.com/150',
                    };
                    setUserInfo(fetchedUser);
                    localStorage.setItem('userInfo', JSON.stringify(fetchedUser));
                })
                .catch(error => {
                    console.error('Erro ao buscar informações do usuário:', error);
                });
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
        window.location.reload();
    };

    return (
        <Disclosure as="nav" className="bg-dark">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-20 md:h-24 items-center justify-between">
                    <div className="flex items-center">
                        <div className="shrink-0">
                            <Link to="/">
                                <img
                                    alt="Neuron Lab"
                                    src={logoNeuronLab}
                                    className="mt-1 h-20 w-auto max-w-[200px] object-contain"
                                />
                            </Link>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-20 mt-1 flex items-baseline space-x-4 font-montserrat">
                                {navigation.map(item => {
                                    const Icon = item.icon;
                                    return (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="rounded-md hover:bg-[#2a3e5e]/50 hover:shadow-md hover:shadow-orangeNeuron/30 px-3 py-2 text-base font-medium flex items-center text-gray-300 hover:bg-[#2a3e5e] hover:text-white"
                                        >
                                            {Icon && (
                                                <Icon className="w-4 h-4 mr-1" />
                                            )}
                                            {item.name}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:flex items-center space-x-4">
                        {/* Botão de Notificações */}
                        <button className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Ver notificações</span>
                            <BellIcon className="w-6 h-6" />
                            {hasNewNotifications && (
                                <span className="absolute top-0 right-0 block w-3 h-3 rounded-full bg-red-500 animate-pulse"></span>
                            )}
                        </button>

                        {/* Perfil do Usuário e botão de Logout */}
                        <div className="flex items-center space-x-4">
                            {userInfo ? (
                                <img
                                    alt={userInfo.name}
                                    src={userInfo.imageUrl}
                                    className="w-12 h-12 rounded-full border-2 border-gray-300 shadow-md transition-transform duration-300 ease-in-out hover:scale-105"
                                />
                            ) : (
                                <div className="w-12 h-12 rounded-full bg-gray-200 border-2 border-gray-300 shadow-md animate-pulse"></div>
                            )}
                            <button
                                onClick={handleLogout}
                                className="flex items-center text-gray-300 hover:text-white focus:outline-none"
                                title="Sair"
                            >
                                <FaSignOutAlt className="w-5 h-5" />
                                <span className="ml-1 hidden md:inline font-montserrat">Sair</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Disclosure>
    );
};

export default NavBar;
