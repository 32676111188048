// LandingFooter.js
import React from 'react';
import { Link } from 'react-router-dom';
import { RxSwitch } from 'react-icons/rx';

const LandingFooter = () => {
    return (
        <footer className="bg-[#192339] border-[#192339]/10 border-t-[1px] text-white p-1 text-center w-full fixed bottom-0">
            <div className="flex justify-between items-center">
                <div className="flex items-center ml-3">
                    <RxSwitch className="mr-2 text-[#57B4B9] w-5 h-5" />
                    <Link
                        to="/privacy-choices"
                        className="hover:underline font-montserrat text-xs"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Política de Privacidade
                    </Link>
                </div>
                <div className="">
                    <p className="font-montserrat text-xs">
                        Encarregado de Proteção de Dados (DPO):{' '}
                        <a
                            href="mailto:your-email@example.com"
                            className="text-[#57B4B9] hover:underline"
                        >
                            dpo@neuronlab.io
                        </a>
                    </p>
                </div>
                <div className="flex items-center">
                    <Link
                        to="/privacy-cookies"
                        className="mr-4 hover:underline font-montserrat text-xs"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacidade & Cookies
                    </Link>
                    <p className="font-montserrat text-xs mr-3">&copy; Neuron Lab</p>
                </div>
            </div>
        </footer>
    );
};

export default LandingFooter;
