import NavBar from '../../components/Global/NavBar';
import Header from '../../components/Global/Header';
import Footer from '../../components/Global/Footer';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import {
    ExclamationCircleIcon,
    ArchiveBoxIcon,
    FolderIcon,
    DocumentTextIcon,
    AdjustmentsVerticalIcon,
    MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import {
    FaArrowRight,
    FaCalendarAlt,
    FaCheckCircle,
    FaDownload,
    FaExchangeAlt,
    FaFilter,
    FaFolder,
    FaFolderOpen,
    FaMapMarkedAlt,
    FaMapMarkerAlt,
    FaSpinner,
} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { getUserFromLocalStorage } from '../../services';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import { CheckIcon } from '@heroicons/react/24/outline';
import { PercentBadgeIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';

interface FolderObj {
    folder: string;
}

interface ChartStat {
    date: string; // no formato "dd/mm/yyyy"
    totalDocuments: number;
    totalUpdates: number;
    totalNoUpdates: number;
}

interface ChartSeriesItem {
    name: string;
    data: number[];
}

interface MonitoringRecordCNPJ {
    _id: string;
    recordSaved: {
        company: {
            id: number;
            name: string;
            nature: {
                id: number;
                text: string;
            };
            size: {
                id: number;
                acronym: string;
                text: string;
            };
            equity: number;
            members: any[];
        };
        address?: {
            street?: string;
            number?: string;
            city?: string;
            state?: string;
        };
    };
    recordLastUpdate: any;
    user_id: string;
    sourcefolder: string;
    destinationFolder: string;
    freq: string;
    dvv: string;
    sap_customer_id: string;
    cnpj: string;
    finishedRecordFilter: boolean;
    updateCompleted: boolean;
    updateDatabaseCompleted: boolean;
    statusChangedRF: string;
    previousStatusRF: string;
    currentStatusRF: string;
    finishedCheckUpdate: boolean;
    stateRegistrationChanged: string;
    previousStateRegistration: any;
    currentStateRegistration: any;
    previousStateRegistrationStatus: string;
    currentStateRegistrationStatus: string;
    alternativeStateRegistration: any;
    stateRegistrationReactivated: string;
    stateRegistrationMissingFound: string;
    companyNameChanged: string;
    addressChanged: string;
    createdAt: string;
    __v: number;
    sendEmail: boolean;
}

const CNPJMonitoring = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const foldersPerPage = 3;

    // Estados para dados carregados da API
    const [fetchedFolders, setFetchedFolders] = useState<
        { id: string; createdAt: string; count: number; color: string }[]
    >([]);
    const filteredFolders = fetchedFolders.filter(
        folder =>
            folder.id &&
            folder.id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const [cnpjStats, setCnpjStats] = useState({
        totalFolders: 0,
        totalCNPJs: 0,
        ativos: 0,
        percentual: 0,
    });
    const [companyData, setCompanyData] = useState<MonitoringRecordCNPJ[]>([]);
    const [chartOptions, setChartOptions] = useState<any>({});
    const [chartSeries, setChartSeries] = useState<any[]>([]);

    // Outros estados de UI e formulário
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [folderName, setFolderName] = useState<string>('');
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [folders, setFolders] = useState<FolderObj[]>([]);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [statusMessageArchive, setStatusMessageArchive] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<
        'success' | 'error'
    >('success');
    const [loading, setLoading] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);
    const [loadingChart, setLoadingChart] = useState(false);
    const [error, setError] = useState<string>('');

    // Estados para filtros
    const [filterStatusRF, setFilterStatusRF] = useState<string>('Todos');
    const [filterIEChanged, setFilterIEChanged] = useState<string>('Todos');
    const [filterDate, setFilterDate] = useState<Date | null>(null);

    // Estados e refs para o popup de consulta em massa
    const [activeTab, setActiveTab] = useState<'existingTab' | 'newTab'>(
        'existingTab'
    );
    const [searchTerm, setSearchTerm] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [popupCnpj, setPopupCnpj] = useState('');

    // Configuração do carousel de registros
    const cardWidth = 350;
    const filteredRecords = companyData.filter(record => {
        const matchesSearch = record.cnpj
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        const matchesStatusRF =
            filterStatusRF === 'Todos' ||
            record.currentStatusRF === filterStatusRF;
        const matchesIEChanged =
            filterIEChanged === 'Todos' ||
            record.stateRegistrationChanged === filterIEChanged;
        const matchesDate = filterDate
            ? moment(record.createdAt).isSame(moment(filterDate), 'day')
            : true;
        return (
            matchesSearch && matchesStatusRF && matchesIEChanged && matchesDate
        );
    });
    const itemsPerPage = 3;
    const containerWidth = filteredRecords.length * cardWidth;
    const pageWidth = itemsPerPage * cardWidth;
    const offset = (currentPage - 1) * pageWidth;
    const totalPages = Math.ceil(filteredRecords.length / itemsPerPage);

    const handlePasteClick = () => {
        if (textareaRef.current) {
            navigator.clipboard.readText().then(pastedText => {
                setPopupCnpj(pastedText);
            });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;
        const filteredText = inputText.replace(/[^a-zA-Z0-9\s]/g, ''); // Filtra apenas letras, números e espaços

        if (inputText !== filteredText) {
            setErrorMessage('Caracteres especiais não são permitidos.');
        } else {
            setErrorMessage('');
        }

        setFolderName(filteredText);
        setIsFolderSelected(filteredText.trim() !== '');
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();
        setLoadingQueue(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

            // Tratamento para CNPJ: remove caracteres não numéricos e formata para 14 dígitos
            const sanitizedData = popupCnpj
                .split(/\s+/)
                .map(cnpj => cnpj.replace(/\D/g, ''))
                .filter(cnpj => cnpj);

            const formattedData = sanitizedData.map(data =>
                data.padStart(14, '0')
            );

            const endpointCNPJ = `${REACT_APP_SERVER_URL}/api/process-cnpjs`;

            try {
                setLoading(true);
                console.log('Iniciando a validação dos CNPJs...');

                const response = await axios.get(endpointCNPJ, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    params: {
                        cnpjs: formattedData.join(','),
                        user_id: userId,
                        folder: folderName,
                    },
                });

                console.log('Response:', response.data);

                setTimeout(() => {
                    setIsPopupOpen(false);
                    navigate('/cnpj');
                    window.location.reload(); // Força o refresh após a navegação
                }, 500);
            } catch (error) {
                console.error('Erro na requisição:', error);
                setProgressMessage('Ocorreu um erro. Tente novamente.');
            } finally {
                setLoadingQueue(false);
                setIsPopupOpen(false);
                navigate('/cnpj');
                window.location.reload(); // Garante o refresh completo
            }
        } else {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        const user = getUserFromLocalStorage();

        if (!user || !user.userId) {
            setMessage('Usuário não autenticado.');
            setNotificationType('error');
            setShowNotification(true);
            return;
        }

        if (!selectedFiles || selectedFiles.length === 0) {
            setMessage('Nenhum arquivo selecionado.');
            setNotificationType('error');
            setShowNotification(true);
            return;
        }

        setLoadingQueue(true);
        setShowNotification(false);

        const userId = user.userId.replace(/^"(.*)"$/, '$1');
        const bearerToken =
            'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            console.log('Adicionando arquivo:', selectedFiles[i].name);
            formData.append('excelFiles', selectedFiles[i]); // Nome do campo deve ser 'excelFiles'
        }

        try {
            const response = await axios.post(
                `${REACT_APP_SERVER_URL}/api/excel-processing-queue-cnpj?user_id=${userId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('Resposta do servidor:', response.data);

            setStatusMessageArchive(
                'Consulta em Massa iniciada com sucesso. Você receberá um e-mail quando o processamento for finalizado.'
            );
            setMessage(response.data.message);
            setNotificationType('success');
        } catch (error) {
            console.error('Erro durante o envio de arquivos:', error);
            setMessage('Erro durante o envio de arquivos.');
            setNotificationType('error');
        } finally {
            setLoadingQueue(false);
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
                window.location.reload();
            }, 3000);
        }
    };

    const clearFilters = () => {
        setSearchTerm('');
        setFilterStatusRF('Todos');
        setFilterIEChanged('Todos');
        setFilterDate(null);
        setCurrentPage(1);
    };

    useEffect(() => {
        // Verifica se tanto o campo de CNPJs do popup quanto o nome da pasta estão preenchidos
        if (popupCnpj.trim() !== '' && folderName.trim() !== '') {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
    }, [popupCnpj, folderName]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                try {
                    const userId = JSON.parse(storedUserId);
                    const response = await axios.get<MonitoringRecordCNPJ[]>(
                        `${REACT_APP_SERVER_URL}/api/cnpj-monitoring-records?user_id=${userId}`
                    );
                    setCompanyData(response.data);
                } catch (err: any) {
                    console.error(
                        'Error fetching data:',
                        err.response || err.message || err
                    );
                    setError('Erro ao buscar registros.');
                } finally {
                    setLoading(false);
                }
            } else {
                setError('User ID não encontrado.');
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/cnpj-monitoring-stats?user_id=${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` },
                    }
                )
                .then(response => {
                    // Mapeia os valores retornados para o estado usado para exibir os KPIs
                    setCnpjStats({
                        totalFolders: response.data.totalDocs, // KPI 1
                        totalCNPJs: response.data.changedDocs, // KPI 2
                        ativos: response.data.notChangedDocs, // KPI 3
                        percentual: response.data.ratioChanged, // KPI 4 (valor fracionário; você pode converter para percentual, se necessário)
                    });
                })
                .catch(error => {
                    console.error('Error fetching monitoring stats:', error);
                });
        }
    }, []);

    useEffect(() => {
        const fetchMonitoringStats = async () => {
            setLoadingChart(true);
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                try {
                    const userId = JSON.parse(storedUserId);
                    const bearerToken =
                        'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-monitoring-stats-by-date`,
                        {
                            headers: { Authorization: `Bearer ${bearerToken}` },
                            params: { user_id: userId },
                        }
                    );
                    // Dados retornados: array de ChartStat
                    const stats: ChartStat[] = response.data;
                    // Ordena os dados por data
                    const sortedStats = stats.sort(
                        (a: ChartStat, b: ChartStat) => {
                            const [dayA, monthA, yearA] = a.date
                                .split('/')
                                .map(Number);
                            const [dayB, monthB, yearB] = b.date
                                .split('/')
                                .map(Number);
                            const dateA = new Date(yearA, monthA - 1, dayA);
                            const dateB = new Date(yearB, monthB - 1, dayB);
                            return dateA.getTime() - dateB.getTime();
                        }
                    );
                    const categories = sortedStats.map(item => item.date);
                    const series: ChartSeriesItem[] = [
                        {
                            name: 'Monitoramentos',
                            data: sortedStats.map(item => item.totalDocuments),
                        },
                        {
                            name: 'Sem Alterações',
                            data: sortedStats.map(item => item.totalNoUpdates),
                        },
                        {
                            name: 'Com Alterações',
                            data: sortedStats.map(item => item.totalUpdates),
                        },
                    ];

                    setChartOptions({
                        chart: {
                            type: 'bar',
                            stacked: true,
                            toolbar: { show: false },
                            background: 'transparent',
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                dataLabels: {
                                    position: 'center', // posiciona os labels no centro de cada segmento
                                },
                            },
                        },
                        dataLabels: {
                            enabled: true, // Habilita os rótulos dentro da barra
                            formatter: function (val: number) {
                                return val.toFixed(0); // Formata sem casas decimais
                            },
                            style: {
                                fontSize: '12px',
                                fontFamily: 'Montserrat',
                                colors: ['#fff'], // Cor branca para o texto
                            },
                        },

                        grid: {
                            borderColor: '#2A3B55',
                            strokeDashArray: 5,
                        },
                        xaxis: {
                            categories: categories, // seu array de datas (já ordenado)
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                            axisBorder: { show: false },
                            axisTicks: { show: false },
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                        },
                        tooltip: {
                            theme: 'dark',
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            labels: {
                                colors: '#ffffff',
                                fontFamily: 'Montserrat',
                            },
                        },
                        title: {
                            text: 'Estatísticas Diárias\nde Monitoramento dos últimos 5 dias',
                            style: {
                                color: '#ffffff',
                                fontFamily: 'Montserrat',
                            },
                        },
                        colors: ['#00D3CF', '#FF5733', '#8E44AD'], // Cores para: Total, Sem Alterações e Com Alterações
                    });

                    setChartSeries(series);
                } catch (err) {
                    console.error(
                        'Erro ao buscar estatísticas de monitoramento:',
                        err
                    );
                } finally {
                    setLoadingChart(false);
                }
            }
        };

        fetchMonitoringStats();
    }, []);

    function normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const handleDownloadExcel = async () => {
        if (!companyData || companyData.length === 0) {
            console.log('Nenhum dado para exportar.');
            return;
        }
        const rows = companyData.map(item => ({
            'Data de Criação': moment(item.createdAt)
                .tz('America/Sao_Paulo')
                .format('DD/MM/YYYY HH:mm:ss'),
            CNPJ: item.cnpj,
            UF: item.recordSaved?.address?.state || '',
            Monitoramento: item.freq,
            Visita: item.dvv,
            'Pasta de Origem': item.sourcefolder,
            'Pasta de Destino': item.destinationFolder || 'Não definida',
            'SAP Customer ID': item.sap_customer_id
                ? String(item.sap_customer_id).replace(/\.0$/, '')
                : 'N/A',
            'Houve alteracao na RF?': item.statusChangedRF,
            'Situação Cadastral RF Anterior': item.previousStatusRF,
            'Situação Cadastral RF Atual': item.currentStatusRF,
            'Houve alteracao no CCC?': item.stateRegistrationChanged,
            'Numero IE': item.currentStateRegistration?.number || '',
            'UF IE': item.currentStateRegistration?.state || '',
            'Status IE Anterior': item.previousStateRegistrationStatus,
            'Status IE Atual': item.currentStateRegistrationStatus,
            'IE Alternativa': item.alternativeStateRegistration
                ? `${item.alternativeStateRegistration.number} - ${
                      item.alternativeStateRegistration.enabled
                          ? 'HABILITADO'
                          : 'NAO HABILITADO'
                  }`
                : '',
            'IE Reativada?': item.stateRegistrationReactivated,
            'IE Desapareceu?': item.stateRegistrationMissingFound,
            'Houve alteracao na Razao Social?': item.companyNameChanged,
            'Nova Razao Social':
                item.companyNameChanged === 'Sim'
                    ? item.recordLastUpdate?.company?.name
                    : '',
            'Houve alteracao no Endereco?': item.addressChanged,
            'Novo Endereco':
                item.addressChanged === 'Sim'
                    ? `${item.recordLastUpdate?.address?.street || ''} ${
                          item.recordLastUpdate?.address?.number || ''
                      }`.trim()
                    : '',
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Informações');
        XLSX.writeFile(workbook, 'Monitoramento - CNPJ.xlsx', {
            compression: true,
        });
    };

    useEffect(() => {
        const fetchMonitoringStats = async () => {
            setLoadingChart(true);
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                try {
                    const userId = JSON.parse(storedUserId);
                    const bearerToken =
                        'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/cnpj-monitoring-stats-by-date`,
                        {
                            headers: { Authorization: `Bearer ${bearerToken}` },
                            params: { user_id: userId },
                        }
                    );
                    // Dados retornados: array de ChartStat
                    const stats: ChartStat[] = response.data;
                    // Ordena os dados por data convertendo "dd/mm/yyyy" para Date
                    const sortedStats = stats.sort((a, b) => {
                        const [dayA, monthA, yearA] = a.date
                            .split('/')
                            .map(Number);
                        const [dayB, monthB, yearB] = b.date
                            .split('/')
                            .map(Number);
                        const dateA = new Date(yearA, monthA - 1, dayA);
                        const dateB = new Date(yearB, monthB - 1, dayB);
                        return dateA.getTime() - dateB.getTime();
                    });
                    const categories = sortedStats.map(item => item.date);
                    // Ordem das séries: Total, Sem Alterações, Com Alterações
                    const series: ChartSeriesItem[] = [
                        {
                            name: 'Monitoramentos',
                            data: sortedStats.map(item => item.totalDocuments),
                        },
                        {
                            name: 'Sem Alterações',
                            data: sortedStats.map(item => item.totalNoUpdates),
                        },
                        {
                            name: 'Com Alterações',
                            data: sortedStats.map(item => item.totalUpdates),
                        },
                    ];
                    setChartOptions({
                        chart: {
                            type: 'bar', // Mudança para barras
                            stacked: true, // Habilita o empilhamento
                            background: 'transparent',
                            toolbar: { show: false },
                        },
                        grid: {
                            borderColor: '#2A3B55',
                            strokeDashArray: 5,
                        },
                        xaxis: {
                            categories,
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                            axisBorder: { show: false },
                            axisTicks: { show: false },
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    colors: '#ffffff',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                },
                            },
                        },
                        tooltip: {
                            theme: 'dark',
                            style: { fontFamily: 'Montserrat' },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            labels: {
                                colors: '#ffffff',
                                fontFamily: 'Montserrat',
                            },
                        },
                        // Título quebrado em duas linhas
                        title: {
                            text: 'Estatísticas Diárias\nde Monitoramento dos últimos 5 dias',
                            style: {
                                color: '#ffffff',
                                fontFamily: 'Montserrat',
                            },
                        },
                        colors: ['#00D3CF', '#FF5733', '#8E44AD'],
                    });
                    setChartSeries(series);
                } catch (err) {
                    console.error(
                        'Erro ao buscar estatísticas de monitoramento:',
                        err
                    );
                } finally {
                    setLoadingChart(false);
                }
            }
        };

        fetchMonitoringStats();
    }, []);

    const getRFBadgeClasses = (status: string) => {
        switch (status) {
            case 'Ativa':
                return 'bg-green-500 text-white';
            case 'Suspensa':
                return 'bg-yellow-500 text-black';
            case 'Inapta':
                return 'bg-red-500 text-white';
            case 'Baixada':
                return 'bg-purple-500 text-white';
            default:
                return 'bg-gray-500 text-white';
        }
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            const bearerToken =
                'a54aba819cf273fe192cbb4226cdd2633fb553277abfe49677689f93c4f73ccc';
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/folders?user_id=${JSON.parse(
                        storedUserId
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                )
                .then(response => {
                    console.log('Folders:', response.data);
                    // Supondo que response.data seja um array de objetos do tipo { folder: "Teste" }
                    setFolders(response.data);
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    const getStatusBadgeClasses = (status: string | boolean) => {
        // Se o status for booleano, converta para string "Sim" ou "Não"
        if (typeof status === 'boolean') {
            return status ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
        }
        // Se for string, podemos normalizar para comparação case-insensitive e sem espaços
        const normalized = status.trim().toLowerCase();
        if (normalized === 'sim') return 'bg-green-500 text-white';
        if (normalized === 'não') return 'bg-red-500 text-white';
        return 'bg-gray-500 text-white';
    };

    return (
        <div className="min-h-screen bg-[#0F172A] text-white flex flex-col">
            <NavBar />
            <main className="flex-1 pt-2 min-h-screen">
                <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 space-y-6">
                    <Header
                        title="CNPJ"
                        Icon={BuildingOfficeIcon}
                        route="/cnpj"
                    />
                    {/* Primeira linha: Navegação interna + KPIs */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:-ml-10">
                        {/* Menu lateral de links */}
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl p-4 space-y-2 rounded-xl">
                            {[
                                {
                                    to: '#',
                                    label: 'Consultar',
                                    icon: (
                                        <MagnifyingGlassIcon className="w-5 h-5" />
                                    ),
                                    action: () => setIsPopupOpen(true),
                                },
                                {
                                    to: '/monitoring-cnpj',
                                    label: 'Monitoramentos',
                                    icon: (
                                        <AdjustmentsVerticalIcon className="w-5 h-5" />
                                    ),
                                },
                                {
                                    to: '',
                                    label: 'Alertas',
                                    icon: (
                                        <ExclamationCircleIcon className="w-5 h-5" />
                                    ),
                                },
                                {
                                    to: '',
                                    label: 'Arquivadas',
                                    icon: (
                                        <ArchiveBoxIcon className="w-5 h-5" />
                                    ),
                                },
                            ].map(({ to, label, icon, action }) => (
                                <Link
                                    key={label}
                                    to={to}
                                    onClick={action}
                                    className="flex items-center w-full text-white hover:bg-[#2a3e5e]/50 hover:shadow-md hover:shadow-orangeNeuron/30 rounded-lg p-2 font-montserrat text-base transition-all duration-300"
                                >
                                    <div className="p-2 bg-[#2a3e5e]/50  rounded-lg">
                                        {icon}
                                    </div>
                                    <span className="ml-2">{label}</span>
                                </Link>
                            ))}
                        </div>

                        {/* Bloco de KPIs */}
                        <div className="bg-darkAlt md:col-span-3 p-6 rounded-xl border border-[#2A3B55] shadow-2xl">
                            {/* Título "KPIs" com espaçamento maior abaixo */}
                            <h2 className="text-lg font-montserrat font-semibold text-white mb-6 sm:mb-6 md:mb-8 lg:mb-10 flex items-center">
                                <AdjustmentsVerticalIcon className="w-5 h-5 mr-2 text-orangeNeuron animate-pulse" />
                                KPIs
                            </h2>

                            {/* Cards com espaçamento maior do título */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 sm:mt-6 md:mt-8 lg:mt-10">
                                {[
                                    {
                                        label: 'Monitoramentos Concluídos',
                                        value: cnpjStats.totalFolders,
                                        icon: (
                                            <ArrowPathIcon className="w-6 h-6 text-[#FFD700]" />
                                        ),
                                    },
                                    {
                                        label: 'Alterações RF',
                                        value: cnpjStats.totalCNPJs,
                                        icon: (
                                            <BellAlertIcon className="w-6 h-6 text-[#00D3CF]" />
                                        ),
                                    },
                                    {
                                        label: 'Alterações CCC',
                                        value: cnpjStats.ativos,
                                        icon: (
                                            <ArrowPathRoundedSquareIcon className="w-6 h-6 text-[#4CAF50]" />
                                        ),
                                    },
                                    {
                                        label: 'Taxa de Alterações',
                                        value: `${(
                                            cnpjStats.percentual * 100
                                        ).toFixed(2)}%`,
                                        icon: (
                                            <PercentBadgeIcon className="w-6 h-6 text-[#FFA500]" />
                                        ),
                                    },
                                ].map(({ label, value, icon }) => (
                                    <div
                                        key={label}
                                        className="py-2 px-4 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg border border-[#2A3B55]/60 hover:border-orangeNeuron/60 transition-all duration-300"
                                    >
                                        <div className="flex justify-between items-center">
                                            <p className="text-base text-white font-semibold font-montserrat">
                                                {label}
                                            </p>
                                            <div className="p-2 bg-[#ffffff1a] rounded-lg shadow-md">
                                                {icon}
                                            </div>
                                        </div>
                                        <p className="text-3xl font-bold text-white mt-2 transition-transform duration-200 hover:scale-105 font-montserrat">
                                            {typeof value === 'number'
                                                ? value.toLocaleString()
                                                : value}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Segunda linha: Conteúdo principal e sidebar */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:-ml-10">
                        {/* Div About com as Pastas */}
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] md:col-span-2 p-4 max-h-[400px] rounded-xl border border-[#2A3B55]/60 shadow-2xl overflow-y-auto">
                            {/* Header das Pastas */}
                            <div className="mb-8 flex justify-between items-center">
                                <div className="flex flex-col md:flex-row items-center w-full md:w-4/5 space-y-2 md:space-y-0 md:space-x-4">
                                    <div className="flex items-center w-full md:w-1/2 space-x-2">
                                        <FaFilter className="text-gray-400" />
                                        <input
                                            type="text"
                                            placeholder="Filtrar por CNPJ..."
                                            value={searchTerm}
                                            onChange={e => {
                                                setSearchTerm(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                            className="w-full p-3 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg border border-[#2A3B55]/60 hover:border-orangeNeuron/60 focus:outline-none focus:ring-2 focus:ring-[#57B4B9] text-white placeholder-gray-400 font-montserrat"
                                        />
                                    </div>
                                    {/* Filtros adicionais */}
                                    <div className="flex items-center space-x-4">
                                        <div className="relative">
                                            <select
                                                value={filterStatusRF}
                                                onChange={e => {
                                                    setFilterStatusRF(
                                                        e.target.value
                                                    );
                                                    setCurrentPage(1);
                                                }}
                                                className="appearance-none w-full p-2 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg text-white text-xs font-montserrat focus:outline-none focus:ring-2 focus:ring-[#57B4B9]"
                                            >
                                                <option value="Todos">
                                                    Status RF: Todos
                                                </option>
                                                <option value="Ativa">
                                                    Ativa
                                                </option>
                                                <option value="Suspensa">
                                                    Suspensa
                                                </option>
                                                <option value="Inapta">
                                                    Inapta
                                                </option>
                                                <option value="Baixada">
                                                    Baixada
                                                </option>
                                            </select>
                                        </div>
                                        <div className="relative">
                                            <select
                                                value={filterIEChanged}
                                                onChange={e => {
                                                    setFilterIEChanged(
                                                        e.target.value
                                                    );
                                                    setCurrentPage(1);
                                                }}
                                                className="appearance-none w-full p-2  bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg text-white text-xs font-montserrat focus:outline-none focus:ring-2 focus:ring-[#57B4B9]"
                                            >
                                                <option value="Todos">
                                                    IE Alterada: Todos
                                                </option>
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                            </select>
                                        </div>
                                        {/* Botão para limpar os filtros de forma discreta */}
                                        <button
                                            onClick={clearFilters}
                                            className="px-2 py-1 text-gray-400 text-xs font-montserrat hover:text-gray-300 transition duration-300"
                                        >
                                            Limpar
                                        </button>
                                    </div>
                                </div>
                                <button
                                    onClick={handleDownloadExcel}
                                    className="flex items-center hover:border-orangeNeuron/60 hover:shadow-orangeNeuron/30 bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] rounded-lg shadow-lg font-montserrat text-white px-6 py-3  transition duration-300 font-semibold"
                                >
                                    <FaDownload className="mr-2" /> Download
                                </button>
                            </div>

                            {/* Grid de Pastas */}
                            {/* Carousel Container para 3 cards por página */}
                            <div className="relative w-full overflow-hidden mx-auto">
                                <div
                                    className="flex transition-transform duration-300"
                                    style={{
                                        // Aqui, mantemos a largura total dinâmica:
                                        width: `${containerWidth}px`,
                                        transform: `translateX(-${offset}px)`,
                                    }}
                                >
                                    {filteredRecords.map(record => (
                                        <div
                                            key={record._id}
                                            className="flex-shrink-0"
                                            style={{
                                                width: `${cardWidth}px`,
                                                marginRight: '20px',
                                            }}
                                        >
                                            <div className="flex flex-col h-[500px] bg-[#111827] rounded-xl shadow-lg overflow-hidden border-l-4 border-[#77E1E5] transition transform  hover:shadow-2xl  hover:border-orangeNeuron/60 hover:shadow-orangeNeuron/30">
                                                {/* Cabeçalho Fixo com Data, FREQ e DVV */}
                                                <div className="px-4 pt-4 pb-2 border-b border-gray-600 flex items-center justify-between">
                                                    <div className="flex items-center text-xs text-gray-300">
                                                        <FaCalendarAlt className="mr-1" />
                                                        {moment(
                                                            record.createdAt
                                                        )
                                                            .tz(
                                                                'America/Sao_Paulo'
                                                            )
                                                            .format(
                                                                'DD/MM/YYYY HH:mm:ss'
                                                            )}
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="bg-gray-600 text-white rounded-full px-3 py-1 text-xs font-semibold">
                                                            {record.freq}
                                                        </div>
                                                        <div className="bg-gray-600 text-white rounded-full px-3 py-1 text-xs font-semibold">
                                                            {record.dvv}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 p-4 overflow-hidden">
                                                    {/* Cabeçalho do Card */}
                                                    <div className="mb-3 -mt-2">
                                                        <h2 className="text-lg font-bold text-white">
                                                            {record.recordSaved
                                                                ?.company
                                                                ?.name ||
                                                                'Empresa não informada'}
                                                        </h2>
                                                        <p className="text-gray-400 text-sm mt-2">
                                                            <span className="font-semibold">
                                                                CNPJ:
                                                            </span>{' '}
                                                            {record.cnpj}
                                                        </p>
                                                        {record.sap_customer_id && (
                                                            <p className="text-gray-400 text-sm">
                                                                <span className="font-semibold">
                                                                    SAP Customer
                                                                    ID:
                                                                </span>{' '}
                                                                {String(
                                                                    record.sap_customer_id
                                                                ).replace(
                                                                    /\.0$/,
                                                                    ''
                                                                )}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {/* Endereço */}
                                                    {record.recordSaved
                                                        ?.address && (
                                                        <div className="mb-3 text-xs flex items-center -mt-2">
                                                            <FaMapMarkerAlt className="mr-1 text-gray-400" />
                                                            <p className="text-gray-400">
                                                                <span className="font-semibold">
                                                                    Endereço:
                                                                </span>{' '}
                                                                {record
                                                                    .recordSaved
                                                                    .address
                                                                    .street ||
                                                                    ''}
                                                                ,{' '}
                                                                {record
                                                                    .recordSaved
                                                                    .address
                                                                    .number ||
                                                                    ''}{' '}
                                                                -{' '}
                                                                {record
                                                                    .recordSaved
                                                                    .address
                                                                    .city ||
                                                                    ''}{' '}
                                                                {record
                                                                    .recordSaved
                                                                    .address
                                                                    .state ||
                                                                    ''}
                                                            </p>
                                                        </div>
                                                    )}

                                                    {/* Status Receita Federal */}
                                                    <div className="mb-3 text-sm -mt-1">
                                                        <h3 className="text-white font-semibold font-montserrat mb-1">
                                                            Status Receita
                                                            Federal
                                                        </h3>
                                                        <div className="flex items-center space-x-2">
                                                            <span
                                                                className={`px-2 py-1 rounded ${getRFBadgeClasses(
                                                                    record.previousStatusRF
                                                                )}`}
                                                            >
                                                                {record.previousStatusRF ||
                                                                    'N/A'}
                                                            </span>
                                                            <FaArrowRight className="text-gray-300" />
                                                            <span
                                                                className={`px-2 py-1 rounded ${getRFBadgeClasses(
                                                                    record.currentStatusRF
                                                                )}`}
                                                            >
                                                                {record.currentStatusRF ||
                                                                    'N/A'}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* Alteração de Razão Social */}
                                                    {record.companyNameChanged ===
                                                        'Sim' && (
                                                        <div className="mt-4 text-sm p-3 bg-gray-900 rounded-lg border border-blue-800">
                                                            <h3 className="text-white font-semibold mb-2 flex items-center">
                                                                <FaExchangeAlt className="mr-2 text-blue-400" />
                                                                Alteração de
                                                                Razão Social
                                                            </h3>
                                                            <p className="text-gray-300 text-sm">
                                                                Nova razão
                                                                social:
                                                                <span className="ml-1 text-white font-semibold">
                                                                    {record
                                                                        .recordLastUpdate
                                                                        ?.company
                                                                        ?.name ||
                                                                        'N/A'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )}

                                                    {/* Alteração de Endereço */}
                                                    {record.addressChanged ===
                                                        'Sim' && (
                                                        <div className="mt-4 text-sm p-3 bg-gray-900 rounded-lg border border-green-800">
                                                            <h3 className="text-white font-semibold mb-2 flex items-center">
                                                                <FaMapMarkedAlt className="mr-2 text-green-400" />
                                                                Alteração de
                                                                Endereço
                                                            </h3>
                                                            <p className="text-gray-300 text-sm">
                                                                Novo endereço:
                                                                <span className="ml-1 text-white font-semibold">
                                                                    {record
                                                                        .recordLastUpdate
                                                                        ?.address
                                                                        ?.street ||
                                                                        ''}
                                                                    ,{' '}
                                                                    {record
                                                                        .recordLastUpdate
                                                                        ?.address
                                                                        ?.number ||
                                                                        ''}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )}

                                                    {/* Inscrição Estadual */}
                                                    {record.stateRegistrationChanged ===
                                                        'Sim' && (
                                                        <div className="mt-4 text-sm p-3 bg-gray-900 rounded-lg border border-gray-700">
                                                            <h3 className="text-white font-semibold mb-2">
                                                                Inscrição
                                                                Estadual
                                                            </h3>
                                                            <div className="flex items-center space-x-2">
                                                                <span
                                                                    className={`px-2 py-1 rounded ${getStatusBadgeClasses(
                                                                        record.currentStateRegistrationStatus
                                                                    )}`}
                                                                >
                                                                    {record
                                                                        .currentStateRegistration
                                                                        ?.number ||
                                                                        'N/A'}
                                                                </span>
                                                                <span className="text-gray-300 text-lg">
                                                                    ⋮
                                                                </span>
                                                                <span
                                                                    className={`px-2 py-1 rounded ${getStatusBadgeClasses(
                                                                        record.previousStateRegistrationStatus
                                                                    )}`}
                                                                >
                                                                    {record
                                                                        .previousStateRegistration
                                                                        ?.number ||
                                                                        'N/A'}
                                                                </span>
                                                            </div>
                                                            {record.stateRegistrationReactivated ===
                                                                'Sim' && (
                                                                <p className="text-green-400 text-xs mt-2">
                                                                    Nova
                                                                    Inscrição
                                                                    Estadual
                                                                    reativada!
                                                                </p>
                                                            )}
                                                            {record.stateRegistrationMissingFound ===
                                                                'Sim' && (
                                                                <p className="text-yellow-400 text-xs mt-2">
                                                                    Inscrição
                                                                    Estadual não
                                                                    existia e
                                                                    foi
                                                                    detectada!
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}

                                                    {/* Seção de Pastas com ícones e disposição em coluna */}
                                                    <div className="mt-2 flex flex-col text-xs space-y-1">
                                                        {/* Origem */}
                                                        <div className="flex items-center">
                                                            <FaFolder className="text-gray-400 mr-1" />
                                                            <span className="font-semibold text-sm text-gray-300">
                                                                Origem:
                                                            </span>
                                                            <span className="text-gray-400 ml-1 text-sm">
                                                                {
                                                                    record.sourcefolder
                                                                }
                                                            </span>
                                                        </div>

                                                        {/* Linha pontilhada para separação */}
                                                        <span className="border-b border-dotted border-gray-500 w-full"></span>

                                                        {/* Destino */}
                                                        <div className="flex items-center">
                                                            <FaFolderOpen className="text-gray-400 mr-1" />
                                                            <span className="font-semibold text-sm text-gray-300">
                                                                Destino:
                                                            </span>
                                                            <span className="text-gray-400 ml-1 text-sm">
                                                                {
                                                                    record.destinationFolder
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Botões de Navegação (setas) */}
                                <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-between px-2">
                                    <button
                                        onClick={() =>
                                            setCurrentPage(prev =>
                                                Math.max(prev - 1, 1)
                                            )
                                        }
                                        disabled={currentPage === 1}
                                        className="bg-gray-700 text-white p-3 rounded-full hover:bg-gray-600 disabled:opacity-50"
                                    >
                                        &lt;
                                    </button>
                                    <button
                                        onClick={() =>
                                            setCurrentPage(prev =>
                                                Math.min(prev + 1, totalPages)
                                            )
                                        }
                                        disabled={currentPage === totalPages}
                                        className="bg-gray-700 text-white p-3 rounded-full hover:bg-gray-600 disabled:opacity-50"
                                    >
                                        &gt;
                                    </button>
                                </div>

                                {/* Indicador de Página */}
                                <div className="flex justify-center items-center mt-4 mb-4">
                                    <span className="text-gray-300 italic">
                                        Página {currentPage} de {totalPages}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gradient-to-br from-[#192339] via-[#1E2A40] to-[#101829] border border-[#2A3B55]/60 shadow-2xl flex flex-col items-center justify-center text-center rounded-xl p-4">
                            {chartSeries.length > 0 ? (
                                <ReactApexChart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="bar"
                                    height={350}
                                />
                            ) : (
                                <p>Carregando gráfico...</p>
                            )}
                        </div>
                    </div>
                </div>
                {/* Popup Condicional */}
                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-11/12 md:w-3/4 lg:w-1/2 min-h-[500px] p-6 rounded-lg shadow-lg bg-gradient-to-b from-[#0E1621] to-[#192339] border border-gray-700">
                            {/* Tabs Header */}
                            <div className="flex space-x-1 mb-4">
                                <button
                                    className={`flex-1 py-2 px-2 rounded-t-md text-center font-semibold font-montserrat text-sm transition-all duration-200 ${
                                        activeTab === 'existingTab'
                                            ? 'bg-[#192339] text-white shadow-md border-b-2 border-white'
                                            : 'bg-[#2d3946] text-gray-300 hover:bg-[#334155]'
                                    }`}
                                    onClick={() => setActiveTab('existingTab')}
                                >
                                    <FolderIcon className="w-4 h-4 inline-block mr-1 text-sm" />{' '}
                                    Consultar CNPJ
                                </button>
                                <button
                                    className={`flex-1 py-2 px-2 rounded-t-md text-center font-semibold font-montserrat text-sm transition-all duration-200 ${
                                        activeTab === 'newTab'
                                            ? 'bg-[#192339] text-white shadow-md border-b-2 border-white'
                                            : 'bg-[#2d3946] text-gray-300 hover:bg-[#334155]'
                                    }`}
                                    onClick={() => setActiveTab('newTab')}
                                >
                                    <DocumentTextIcon className="w-4 h-4 inline-block mr-1 text-sm" />{' '}
                                    Consulta em Massa
                                </button>
                            </div>

                            {/* Tabs Content */}
                            {activeTab === 'existingTab' && (
                                <div>
                                    <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                                        Consulte informações de clientes usando
                                        o CNPJ
                                    </h2>
                                    <p className="text-gray-300 font-montserrat text-base mb-4">
                                        Cole os números de CNPJ no campo abaixo,
                                        separados por espaços ou quebras de
                                        linha.
                                    </p>

                                    <div className="flex items-center mb-4">
                                        <textarea
                                            ref={textareaRef}
                                            id="popupCnpj"
                                            name="popupCnpj"
                                            value={popupCnpj}
                                            onChange={e =>
                                                setPopupCnpj(e.target.value)
                                            }
                                            className="flex-1 w-full h-32 p-2 bg-[#1f2937] border border-dashed border-gray-500 rounded-lg focus:outline-none font-montserrat text-white text-sm resize-none"
                                            placeholder="Cole os CNPJs aqui"
                                        ></textarea>

                                        <button
                                            onClick={handlePasteClick}
                                            className="ml-3 bg-gray-700 text-white p-2 rounded-lg hover:bg-gray-600 focus:outline-none"
                                        >
                                            <MagnifyingGlassIcon className="w-5 h-5" />
                                        </button>
                                    </div>

                                    <label className="block mb-2 text-white font-montserrat text-sm font-semibold">
                                        Escolha uma pasta usada anteriormente
                                    </label>
                                    <select
                                        className="w-full py-2 px-2 text-white bg-[#1f2937] border border-gray-600 rounded-lg focus:outline-none font-montserrat text-sm"
                                        onChange={e => {
                                            setFolderName(e.target.value);
                                            setIsFolderSelected(
                                                e.target.value !== ''
                                            );
                                        }}
                                        value={folderName}
                                    >
                                        <option value="">
                                            Selecione uma pasta existente
                                        </option>
                                        {folders.map((folderObj, index) => (
                                            <option
                                                key={index}
                                                value={folderObj.folder}
                                            >
                                                {folderObj.folder}
                                            </option>
                                        ))}
                                    </select>

                                    <div className="flex items-center mt-3">
                                        <button
                                            className="text-blue-400 hover:text-blue-500 focus:outline-none"
                                            onClick={() =>
                                                setShowNewFolderInput(
                                                    !showNewFolderInput
                                                )
                                            }
                                        >
                                            <FolderIcon className="w-5 h-5" />
                                        </button>
                                        <span className="ml-2 font-montserrat text-white text-sm">
                                            ou crie uma nova pasta
                                        </span>
                                    </div>

                                    {showNewFolderInput && (
                                        <div className="mt-3">
                                            <input
                                                type="text"
                                                className="w-full py-2 px-2 text-white bg-[#1f2937] border border-gray-500 rounded-lg focus:outline-none font-montserrat text-sm"
                                                placeholder="Digite o nome da nova pasta"
                                                value={folderName}
                                                onChange={handleInputChange}
                                            />
                                            {errorMessage && (
                                                <p className="text-red-500 italic mt-1 text-xs font-montserrat">
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div className="flex justify-between mt-6">
                                        <button
                                            onClick={() =>
                                                setIsPopupOpen(false)
                                            }
                                            className="px-5 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white text-sm font-semibold font-montserrat"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSearchClick}
                                            disabled={!isSaveEnabled}
                                            className={`px-5 py-2 rounded-lg text-sm font-semibold font-montserrat ${
                                                isSaveEnabled
                                                    ? 'bg-[#00B3BE] hover:bg-[#00D3CF] text-[#192339]'
                                                    : 'bg-gray-600 opacity-50 cursor-not-allowed text-white'
                                            }`}
                                        >
                                            Salvar
                                        </button>
                                    </div>
                                    {loadingQueue && (
                                        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                            <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                                                <FaSpinner className="animate-spin w-6 h-6 text-white" />
                                                <span className="text-base text-white font-montserrat font-semibold">
                                                    Enviando para Consulta...
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {activeTab === 'newTab' && (
                                <div>
                                    <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-2">
                                        Envie um arquivo com CNPJs para análise
                                        em massa
                                    </h2>
                                    <p className="text-gray-300 font-montserrat text-lg mb-4">
                                        Envie um arquivo Excel com as
                                        informações dos CNPJs para continuar.
                                    </p>

                                    <div className="flex items-center space-x-4 p-4 rounded-lg border-2 border-dashed border-gray-600 shadow-md font-montserrat">
                                        <div className="w-14 h-14 flex items-center justify-center bg-gray-700 rounded-lg">
                                            <img
                                                src="https://logospng.org/download/microsoft-excel/logo-microsoft-excel-1024.png"
                                                alt="Ícone de Excel"
                                                className="w-10 h-10"
                                            />
                                        </div>
                                        <div className="text-sm text-gray-300">
                                            <p className="mb-1 font-semibold">
                                                Exemplo de arquivo:
                                            </p>
                                            <p className="text-gray-400">
                                                Coluna: <strong>CNPJ</strong>
                                            </p>
                                            <p className="text-gray-400">
                                                Linhas:{' '}
                                                <span className="italic">
                                                    números de CNPJ
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center mb-6 p-4 bg-gray-800 rounded-lg shadow-md border-2 border-dotted border-gray-600 mt-4 font-montserrat">
                                        <label
                                            htmlFor="fileInput"
                                            className="text-gray-400 hover:text-white font-medium p-2 rounded-md cursor-pointer text-sm font-montserrat"
                                        >
                                            Clique aqui para selecionar um
                                            arquivo Excel
                                        </label>
                                        <input
                                            type="file"
                                            accept=".xls,.xlsx"
                                            multiple
                                            className="hidden"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                        />
                                        <p className="text-gray-500 text-xs mt-2 text-center">
                                            Suporte para arquivos no formato{' '}
                                            <strong>.xls</strong> ou{' '}
                                            <strong>.xlsx</strong>.
                                            Certifique-se de que o arquivo
                                            contém uma coluna chamada{' '}
                                            <strong>"CNPJ"</strong> e os números
                                            de CNPJ nas linhas abaixo.
                                        </p>
                                    </div>
                                    {statusMessageArchive && (
                                        <div className="text-center text-green-500 font-montserrat text-base font-bold mt-4">
                                            {statusMessageArchive}
                                        </div>
                                    )}

                                    <div className="flex justify-between mt-6">
                                        <button
                                            onClick={() =>
                                                setIsPopupOpen(false)
                                            }
                                            className="px-5 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white text-sm font-semibold font-montserrat"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            disabled={!selectedFiles}
                                            className={`px-5 py-2 rounded-lg text-sm font-semibold font-montserrat ${
                                                selectedFiles
                                                    ? 'bg-[#00B3BE] hover:bg-[#00D3CF] text-[#192339]'
                                                    : 'bg-gray-600 opacity-50 cursor-not-allowed text-white'
                                            }`}
                                        >
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {loadingQueue && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="flex items-center space-x-3 px-4 py-2 bg-[#223750] shadow-xl rounded-xl">
                                    <FaSpinner className="animate-spin w-6 h-6 text-white" />
                                    <span className="text-base text-white font-montserrat font-semibold">
                                        Enviando para Consulta...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default CNPJMonitoring;
